import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Popover,
  PopoverPanel,
  PopoverButton,
  Transition,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { injected } from "wagmi/connectors";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../redux/actions/user";
import SessionService from "../../services/Session";
import config from "../../config";
import { logIssueNetworkRequestError } from "../../utils/loggers";
import { displayToastMessage } from "../../utils/toasts";
import { store } from "../../redux";
import { authRoutePath, sellerDashboardPath } from "../../containers/Router";
import { Mixpanel } from "../../utils/analytics";
import { BrowserProvider } from "ethers";
import type { Account, Chain, Client, Transport } from "viem";
import { type Config, useConnectorClient } from "wagmi";
import { clearCart } from "../../utils/cart";
import FiatCryptoOnrampWidget from "../FiatCryptoOnrampWidget";
// import SwapCryptoWidget from "../SwapCryptoWidget";

let previousChainID: any = null;

const CommonHeader = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);

  const { address, isConnected, chainId } = useAccount();
  const networkClientConfig = useEthersSigner({ chainId });
  const { connect } = useConnect();
  const { disconnect } = useDisconnect();

  const [isOpen, setIsOpen] = useState(false);

  const sessionService = useMemo(() => new SessionService(), []);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const sessionData = await sessionService.reviewSession();
        if (sessionData.idVerificationNeeded) {
          navigate(authRoutePath);
        } else if (sessionData.success) {
          const state = store.getState();
          dispatch(
            setUser({
              ...state.user,
              walletAddress: address,
              id: sessionData.userID,
              email: sessionData.email,
              phoneNumber: sessionData.phoneNumber,
              avatarImage: sessionData.avatarImage,
              profileAboutYouText: sessionData.profileAboutYouText,
              chainId,
            }),
          );
          Mixpanel.track("Session - Success", {
            ...state.user,
            walletAddress: address,
            id: sessionData.userID,
            email: sessionData.email,
            phoneNumber: sessionData.phoneNumber,
            chainId,
          });
        }
      } catch (error: Error | any) {
        logIssueNetworkRequestError("CommonHeader.checkSession()", error);
        displayToastMessage(
          "error",
          "Network error: Please refresh and/or check network settings.",
        );
      }
    };

    checkSession();
  }, [address, chainId, dispatch, navigate, sessionService]);

  useEffect(() => {
    const state = store.getState();
    dispatch(
      setUser({
        ...state.user,
        ...networkClientConfig,
      }),
    );
  }, [dispatch, networkClientConfig]);

  useEffect(() => {
    console.log("Network changed");
    Mixpanel.track("Chain ID Changed", { chainId });
  }, [chainId]);

  const handleWalletConnection = useCallback(() => {
    if (isConnected) {
      console.log(`Wallet connected: ${address}:`);
      const state = store.getState();
      dispatch(
        setUser({
          ...state.user,
          walletAddress: address,
          chainId,
        }),
      );
      Mixpanel.track("Wallet - Connected", {
        ...state.user,
        walletAddress: address,
        chainId,
      });
    } else {
      console.log("Wallet disconnected");
    }
  }, [address, chainId, dispatch, isConnected]);

  useEffect(() => {
    handleWalletConnection();
  }, [handleWalletConnection]);

  useEffect(() => {
    if (previousChainID !== chainId && previousChainID !== null) {
      clearCart();
      displayToastMessage(
        "info",
        "Network change detected. Please refresh the page",
      );
    }
    previousChainID = chainId;
  }, [chainId]);

  const renderSellLink = useMemo(
    () => (
      <a
        href={user && user.id && user.email ? "/product/create" : "/auth"}
        className="block px-2 py-2 text-lg text-blue-800"
      >
        Sell
      </a>
    ),
    [user],
  );

  const renderCartLink = useMemo(
    () => (
      <a href="/cart" className="block px-2 py-2 text-lg text-blue-800">
        Cart
      </a>
    ),
    [],
  );

  const renderAccountLink = useMemo(
    () => (
      <a href="/account" className="block px-2 py-2 text-lg text-blue-800">
        Account
      </a>
    ),
    [],
  );

  const renderActivityLink = useMemo(
    () => (
      <a href="/activity" className="block px-2 py-2 text-lg text-blue-800">
        Activity
      </a>
    ),
    [],
  );

  const handleSignOut = useCallback(
    async (evt: Event | any) => {
      evt.preventDefault();
      try {
        displayToastMessage("info", "Signing out...");
        await sessionService.signOut();
        navigate(authRoutePath);
      } catch (error: Error | any) {
        logIssueNetworkRequestError("CommonHeader.handleSignOut", error);
        displayToastMessage(
          "error",
          "Network error: Please refresh and/or check network settings.",
        );
      }
    },
    [navigate, sessionService],
  );

  const renderLoginProfileButton = useMemo(() => {
    if (user && user.id && user.email) {
      return (
        <Fragment>
          <a
            href={sellerDashboardPath}
            className="block px-2 py-2 text-lg text-blue-800"
          >
            Seller
          </a>
          <button
            onClick={handleSignOut}
            className="block px-2 py-2 text-blue-800 lg:hidden"
          >
            Sign Out
          </button>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {/* <a href="/auth" className="block px-2 py-2 text-lg text-blue-800">
            Log In
          </a> */}
          <a href="/auth" className="block px-2 py-2 text-lg text-blue-800">
            Auth
          </a>
        </Fragment>
      );
    }
  }, [handleSignOut, user]);

  const renderConnectWalletButton = useMemo(() => {
    if (isConnected) {
      return (
        <button
          onClick={() => {
            disconnect();
            Mixpanel.track("Disconnected Wallet", {});
          }}
          className="block px-2 py-2 text-lg text-blue-800"
        >
          Disconnect
        </button>
      );
    } else {
      return (
        <button
          onClick={() => {
            connect({ connector: injected() });
            Mixpanel.track("Connected Wallet", {});
          }}
          className="block px-2 py-2 text-lg text-blue-800"
        >
          Connect Wallet
        </button>
      );
    }
  }, [connect, disconnect, isConnected]);

  const renderBlogButton = useMemo(
    () => (
      <a
        href={config.links.blogURL}
        target="_blank"
        rel="noreferrer"
        className="block px-2 py-2 text-lg text-blue-800"
        onClick={() => {
          Mixpanel.track("Viewed Blog", {});
        }}
      >
        Blog
      </a>
    ),
    [],
  );

  const renderDocsButton = useMemo(
    () => (
      <a
        href={config.links.documentationURL}
        target="_blank"
        rel="noreferrer"
        className="block px-2 py-2 text-lg text-blue-800"
        onClick={() => {
          Mixpanel.track("Viewed Docs", {});
        }}
      >
        Docs
      </a>
    ),
    [],
  );

  const renderSupportButton = useMemo(
    () => (
      <a
        href={`mailto:${config.emails.supportEmail}?subject=Support%20Request&body=Please be very clear about your issue so our team can swiftly solve it!`}
        target="_blank"
        rel="noreferrer"
        className="block px-2 py-2 text-lg text-blue-800"
        onClick={() => {
          Mixpanel.track("Clicked Support", {});
        }}
      >
        Support
      </a>
    ),
    [],
  );

  return (
    <div className="bg-white-800 p-4 shadow-lg">
      <div className="container mx-auto flex items-center justify-between">
        <a href="/" className="text-xl font-semibold text-blue-800">
          {config.labels.brandName}
        </a>
        <div className="flex flex-row items-center justify-center xl:hidden">
          <Popover className="relative pl-4">
            <PopoverButton onClick={(_) => setIsOpen(!isOpen)}>
              {isOpen ? (
                <XMarkIcon className="h-6 w-6 text-blue-800" />
              ) : (
                <Bars3Icon className="h-6 w-6 text-blue-800" />
              )}
            </PopoverButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <PopoverPanel className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <FiatCryptoOnrampWidget />
                  {/* <SwapCryptoWidget /> */}
                  {renderSellLink}
                  {renderCartLink}
                  {renderActivityLink}
                  {renderAccountLink}
                  {renderBlogButton}
                  {renderDocsButton}
                  {renderSupportButton}
                  {renderLoginProfileButton}
                  {renderConnectWalletButton}
                </div>
              </PopoverPanel>
            </Transition>
          </Popover>
        </div>
        <div className="hidden space-x-4 xl:flex">
          <ConnectButton />
          <FiatCryptoOnrampWidget />
          {/* <SwapCryptoWidget /> */}
          {renderSellLink}
          {renderCartLink}
          {renderActivityLink}
          {renderAccountLink}
          {/* {renderBlogButton} */}
          {renderDocsButton}
          {renderSupportButton}
          {renderLoginProfileButton}
        </div>
      </div>
    </div>
  );
};

export function clientToSigner(client: Client<Transport, Chain, Account>) {
  if (client && client.chain && client.chain.id && client.chain.name) {
    const { chain, transport } = client;
    const network = {
      chainId: chain.id,
      name: chain.name,
      ensAddress: chain.contracts?.ensRegistry?.address,
    };
    const provider = new BrowserProvider(transport, network);
    return { provider };
  } else {
    return {};
  }
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: client } = useConnectorClient<Config>({ chainId });
  console.log("client:", client);
  return useMemo(() => (client ? clientToSigner(client) : {}), [client]);
}

export default CommonHeader;
