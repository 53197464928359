import React from "react";
import CommonHeader from "../../components/CommonHeader";
import { ProductDetailsCard } from "../../components/ProductDetailsCard";
import { ethers } from "ethers";
import FeesContract from "../../contracts/Fees.sol/Fees.json";
import PurchaseEscrowContract from "../../contracts/PurchaseEscrowV1.sol/PurchaseEscrowV1.json";
import { IProduct } from "../../interfaces/product";
import { displayToastMessage } from "../../utils/toasts";
import {
  logIssueNetworkRequest,
  logIssueNetworkRequestError,
} from "../../utils/loggers";
import { convertETHToWei, convertWeiToETH } from "../../utils/convert";
import { connect } from "react-redux";
import { contactSupportErrorLabel } from "../../utils/errors";
import { Web3NetworkConfig, getNetworkConfig } from "../../utils/network";
import { Mixpanel } from "../../utils/analytics";
import { getWalletETHBalance } from "../../utils/wallet";

class ShoppingCart extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      cart: this.getCartItems(),
    };

    this.handleCartCheckout = this.handleCartCheckout.bind(this);
    this.handleEditQuantity = this.handleEditQuantity.bind(this);
  }

  componentDidMount() {
    Mixpanel.track("ShoppingCart", {
      user: this.props.user,
    });
  }

  getCartItems() {
    const cartItems = localStorage.getItem("cart");
    return cartItems ? JSON.parse(cartItems) : [];
  }

  saveCartItems(cartItems: any) {
    localStorage.setItem("cart", JSON.stringify(cartItems));
    this.setState({ cart: cartItems });
  }

  removeFromCart(index: number, product: IProduct) {
    let cartItems = this.getCartItems();
    cartItems.splice(index, 1);
    this.saveCartItems(cartItems);
    Mixpanel.track("Removed Item From Cart", {
      product,
    });
  }

  async handleCartCheckout() {
    if (this.state.cart.length === 0) {
      return displayToastMessage("error", "Add products to your cart");
    }

    logIssueNetworkRequest("SellerDashboard.handleUpdateAccount()");

    displayToastMessage("info", "Loading...");

    try {
      // const provider = config.web3.provider;
      // const signer = await provider.getSigner();
      // const contract = new ethers.Contract(
      //   config.contracts.PurchaseEscrow.address,
      //   PurchaseEscrowContract.abi,
      //   signer,
      // );
      const networkConfig: Web3NetworkConfig = getNetworkConfig(
        this.props.user.chainId,
        this.props.user.provider,
      );
      console.log("networkConfig:", networkConfig);
      const provider = networkConfig.provider;
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(
        networkConfig.addresses.PurchaseEscrow,
        PurchaseEscrowContract.abi,
        signer,
      );
      const feesContract = new ethers.Contract(
        networkConfig.addresses.Fees,
        FeesContract.abi,
        signer,
      );
      const serviceFee = await feesContract.getServiceFee();

      // function initiateCartCheckout(uint256[] calldata productIds, address payable buyer, address payable validator) external payable;
      const cart: any = this.state.cart;
      const productIds = cart.map((product: IProduct) => {
        return product.productId;
      });
      const quantities = cart.map((product: IProduct) => {
        return product.quantity;
      });
      const totalAmount = cart.reduce(
        (acc: BigInt | any, product: IProduct) =>
          acc + BigInt(product.price) * BigInt(product.quantity),
        BigInt(0),
      );

      const totalAmountWithFee =
        totalAmount + (totalAmount * BigInt(serviceFee)) / BigInt(100);
      const totalAmountInETH = Number(convertWeiToETH(totalAmountWithFee));
      const currentWalletBalance = await getWalletETHBalance(
        provider,
        this.props.user.walletAddress,
      );

      console.log(
        "totalAmount, totalAmountWithFee, totalAmountInETH, currentWalletBalance:",
        totalAmount,
        totalAmountWithFee,
        totalAmountInETH,
        currentWalletBalance,
      );

      if (Number(currentWalletBalance) < totalAmountInETH) {
        return displayToastMessage(
          "error",
          "ETH Balance is less than total amount required (including fees): " +
            totalAmountInETH,
        );
      }

      await contract.initiateCartCheckout(
        productIds,
        quantities,
        this.props.user.walletAddress,
        networkConfig.addresses.CoreValidatorAddress,
        { value: convertETHToWei(totalAmountInETH) },
      );

      displayToastMessage("success", "Checkout initiated successfully!");

      Mixpanel.track("Cart Checkout - Success", {
        cart: this.state.cart,
      });

      this.saveCartItems([]);
    } catch (error: Error | any) {
      logIssueNetworkRequestError("ShoppingCart.handleCartCheckout()", error);
      displayToastMessage(
        "error",
        `Failed to process cart checkout. ${contactSupportErrorLabel}`,
      );
      Mixpanel.track("Cart Checkout - Failure", {
        cart: this.state.cart,
      });
    }
  }

  handleEditQuantity(e: Event | any, index: any) {
    console.log("handleEditQuantity()");
    try {
      const cart = this.state.cart;
      const product: IProduct = cart[index];

      product.quantity = e.target.value;

      this.setState({ cart });
    } catch (error: Error | any) {
      console.log("handleEditQuantity() error:", error);
    }
  }

  renderShoppingCartList() {
    const { cart } = this.state;
    if (!cart.length) {
      return (
        <div className="py-10 text-center">
          <h2 className="text-2xl font-semibold">Your cart is empty.</h2>
          <a
            href="/"
            className="mt-4 inline-block rounded bg-blue-500 px-6 py-3 text-white hover:bg-blue-600"
          >
            Start Shopping
          </a>
        </div>
      );
    }

    return (
      <div className="cart-items-list flex max-h-[calc(100vh-200px)] flex-col overflow-y-auto p-6">
        {cart.map((product: IProduct, index: number) => (
          <div key={index} className="my-2">
            <ProductDetailsCard
              index={index}
              product={product}
              selectItem={() => {}}
              editQuantity={true}
              onEditQuantity={(e: any) => this.handleEditQuantity(e, index)}
            />
            <button
              className="my-2 rounded bg-red-600 p-4 font-bold text-white hover:bg-green-700"
              onClick={() => this.removeFromCart(index, product)}
            >
              Remove Product
            </button>
          </div>
        ))}
      </div>
    );
  }

  renderSummary() {
    const { cart } = this.state;
    const total = cart.reduce(
      (acc: BigInt | any, product: IProduct) =>
        acc + BigInt(product.price) * BigInt(product.quantity),
      BigInt(0),
    );
    return (
      <div className="sticky top-0 bg-white p-4 shadow">
        <h2 className="text-xl font-semibold">Summary</h2>
        <p className="text-lg">Total: {convertWeiToETH(total)} ETH</p>
        <button
          className="mt-4 w-full rounded bg-blue-600 py-3 text-white hover:bg-blue-700"
          onClick={this.handleCartCheckout}
        >
          Checkout
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="flex min-h-screen flex-col bg-gray-100">
        <CommonHeader />
        <h1 className="px-8 pt-8 text-3xl font-bold">Shopping Cart</h1>
        <div className="container mx-auto flex flex-grow flex-col p-4 md:flex-row md:gap-4">
          <div className="overflow-hidden md:flex-grow">
            {this.renderShoppingCartList()}
          </div>
          <div className="mt-4 md:mt-0 md:w-1/3">{this.renderSummary()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Object | any) => ({
  router: state.router,
  user: state.user,
});

export default connect(mapStateToProps, null)(ShoppingCart);
