// TODO: Test E2E with testnet contracts
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { ethers } from "ethers";
import { connect } from "react-redux";
import CommonHeader from "../../components/CommonHeader";
import { displayToastMessage } from "../../utils/toasts";
import RANDOMToken from "../../contracts/RANDOM.sol/RANDOM.json";
import veRANDOMToken from "../../contracts/veRANDOM.sol/veRANDOM.json";
import RewardsContract from "../../contracts/Rewards.sol/Rewards.json";
import { getNetworkConfig } from "../../utils/network";
import { Mixpanel } from "../../utils/analytics";

const ActivityDashboard = ({ user }: any) => {
  const [randomBalance, setRandomBalance] = useState("...");
  const [veRandomBalance, setVeRandomBalance] = useState("...");
  const [totalPlatformEarnings, setTotalPlatformEarnings] = useState("...");
  const [lockAmount, setLockAmount] = useState("");
  const [lockDuration, setLockDuration] = useState("");
  const [redeemAmount, setRedeemAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [lockedBalance, setLockedBalance] = useState("...");
  const [unlockTime, setUnlockTime] = useState("...");
  const [extendDuration, setExtendDuration] = useState("");

  const networkConfig = useMemo(
    () => getNetworkConfig(user.chainId, user.provider),
    [user.chainId, user.provider],
  );

  const loadUserBalances = useCallback(async () => {
    try {
      const provider = networkConfig.provider;
      const signer = await provider.getSigner();

      const randomContract = new ethers.Contract(
        networkConfig.addresses.RANDOM,
        RANDOMToken.abi,
        signer,
      );

      const veRandomContract = new ethers.Contract(
        networkConfig.addresses.veRANDOM,
        veRANDOMToken.abi,
        signer,
      );

      const rewardsContract = new ethers.Contract(
        networkConfig.addresses.Rewards,
        RewardsContract.abi,
        signer,
      );

      Mixpanel.track("Fetch User Balances", {});

      try {
        const randomBalance = await randomContract.balanceOf(
          user.walletAddress,
        );
        setRandomBalance(ethers.formatEther(randomBalance));
      } catch (error) {
        console.log(
          "randomContract.balanceOf(user.walletAddress) error:",
          error,
        );
        Mixpanel.track("Fetch Token Balance - Failure", { error });
      }

      try {
        const veRandomBalance = await veRandomContract.balanceOf(
          user.walletAddress,
        );
        setVeRandomBalance(ethers.formatEther(veRandomBalance));
      } catch (error) {
        console.log(
          "veRandomContract.balanceOf(user.walletAddress) error",
          error,
        );
        Mixpanel.track("Fetch veToken Balance - Failure", { error });
      }

      try {
        const totalPlatformEarnings = await rewardsContract.getTokenBalance(
          user.walletAddress,
        );
        setTotalPlatformEarnings(ethers.formatEther(totalPlatformEarnings));
      } catch (error) {
        console.log(
          "randomContract.balanceOf(user.walletAddress) error:",
          error,
        );
        Mixpanel.track("Fetch Platform Earnings - Failure", { error });
      }

      try {
        const [lockedAmount, lockedUnlockTime] =
          await veRandomContract.getLockedBalance(user.walletAddress);
        setLockedBalance(ethers.formatEther(lockedAmount));
        setUnlockTime(
          new Date(Number(lockedUnlockTime) * 1000).toLocaleString(),
        );
      } catch (error) {
        console.log(
          "veRandomContract.getLockedBalance(user.walletAddress) error:",
          error,
        );
        Mixpanel.track("Fetch Locked Balance - Failure", { error });
      }
    } catch (error) {
      console.error("Failed to load user balances:", error);
      displayToastMessage("error", "Failed to load user balances.");
      Mixpanel.track("Failure - Fetch User Balances", {});
    }
  }, [networkConfig, user.walletAddress]);

  useEffect(() => {
    if (user.walletAddress) {
      loadUserBalances();
      Mixpanel.track("ActivityDashboard", { user });
    } else {
      Mixpanel.track("ActivityDashboard", {});
    }
  }, [user, user.walletAddress, loadUserBalances]);

  const handleRedeemTokens = async (e: any, redeemAll: boolean) => {
    e.preventDefault();

    try {
      setLoading(true);

      const networkConfig = getNetworkConfig(user.chainId, user.provider);
      const provider = networkConfig.provider;
      const signer = await provider.getSigner();

      const rewardsContract = new ethers.Contract(
        networkConfig.addresses.Rewards,
        RewardsContract.abi,
        signer,
      );

      let amount = redeemAll ? totalPlatformEarnings : redeemAmount;
      const amountInWei = ethers.parseUnits(amount, 18);

      const tx = await rewardsContract.redeemTokens(
        user.walletAddress,
        amountInWei,
      );

      await tx.wait();

      displayToastMessage("success", "Tokens redeemed successfully.");

      Mixpanel.track("Redeemed Tokens - Success", { amount });

      loadUserBalances();
    } catch (error) {
      console.error("Failed to redeem tokens:", error);
      displayToastMessage("error", "Failed to redeem tokens.");
      Mixpanel.track("Redeemed Tokens - Failure", { error });
    } finally {
      setLoading(false);
    }
  };

  const handleLockTokens = async () => {
    try {
      setLoading(true);

      const provider = networkConfig.provider;
      const signer = await provider.getSigner();

      const randomContract = new ethers.Contract(
        networkConfig.addresses.RANDOM,
        RANDOMToken.abi,
        signer,
      );
      const veRandomContract = new ethers.Contract(
        networkConfig.addresses.veRANDOM,
        veRANDOMToken.abi,
        signer,
      );

      const amountInWei = ethers.parseUnits(lockAmount, 18);
      const durationInSeconds = parseInt(lockDuration);

      const approveTx = await randomContract.approve(
        networkConfig.addresses.veRANDOM,
        amountInWei,
      );

      displayToastMessage("info", "Approving...please wait");

      await approveTx.wait();

      const tx = await veRandomContract.lock(amountInWei, durationInSeconds);

      displayToastMessage("info", "Locking...please wait");

      await tx.wait();

      displayToastMessage("success", "Tokens locked successfully.");

      Mixpanel.track("Locked Tokens - Success", { lockAmount });

      loadUserBalances();
    } catch (error) {
      console.error("Failed to lock tokens:", error);
      displayToastMessage("error", "Failed to lock tokens.");
      Mixpanel.track("Locked Tokens - Failure", { error });
    } finally {
      setLoading(false);
    }
  };

  const handleUnlockTokens = async () => {
    try {
      setLoading(true);

      const provider = networkConfig.provider;
      const signer = await provider.getSigner();

      const veRandomContract = new ethers.Contract(
        networkConfig.addresses.veRANDOM,
        veRANDOMToken.abi,
        signer,
      );

      const tx = await veRandomContract.unlock();
      await tx.wait();

      displayToastMessage("success", "Tokens unlocked successfully.");

      Mixpanel.track("Unlocked Tokens - Success", {});

      loadUserBalances();
    } catch (error) {
      console.error("Failed to unlock tokens:", error);
      displayToastMessage("error", "Failed to unlock tokens.");
      Mixpanel.track("Unlocked Tokens - Failure", { error });
    } finally {
      setLoading(false);
    }
  };

  const handleExtendLock = async () => {
    try {
      setLoading(true);

      const provider = networkConfig.provider;
      const signer = await provider.getSigner();

      const veRandomContract = new ethers.Contract(
        networkConfig.addresses.veRANDOM,
        veRANDOMToken.abi,
        signer,
      );

      const durationInSeconds = parseInt(extendDuration);

      const tx = await veRandomContract.extendLock(durationInSeconds);
      await tx.wait();

      displayToastMessage("success", "Lock extended successfully.");

      Mixpanel.track("Extended Lock - Success", { durationInSeconds });

      loadUserBalances();
    } catch (error) {
      console.error("Failed to extend lock:", error);
      displayToastMessage("error", "Failed to extend lock.");
      Mixpanel.track("Extended Lock - Failure", { error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <CommonHeader />
      <div className="px-4 py-8 lg:px-20">
        <div className="my-10 rounded bg-white p-6 shadow-md">
          <h1 className="text-2xl font-bold text-gray-800">
            Activity Dashboard
          </h1>
          <h2 className="my-4 text-xl font-semibold text-gray-700">
            Total RANDOM Tokens:{" "}
            <span className="text-blue-500">{randomBalance}</span>
          </h2>
          <h2 className="my-4 text-xl font-semibold text-gray-700">
            Total veRANDOM Tokens:{" "}
            <span className="text-blue-500">{veRandomBalance}</span>
          </h2>
          <h2 className="my-4 text-xl font-semibold text-gray-700">
            Total Locked Tokens:{" "}
            <span className="text-blue-500">{lockedBalance}</span>
          </h2>
          <h2 className="my-4 text-xl font-semibold text-gray-700">
            Time Til Unlock (TTUL):{" "}
            <span className="text-blue-500">{unlockTime}</span>
          </h2>
          <h2 className="my-4 text-xl font-semibold text-gray-700">
            Total Platform Earnings:{" "}
            <span className="text-blue-500">{totalPlatformEarnings}</span>
          </h2>

          <div className="mt-8">
            <h2 className="my-4 text-xl font-semibold text-gray-700">
              Redeem Tokens
            </h2>
            <input
              type="text"
              placeholder="Amount to redeem"
              value={redeemAmount}
              onChange={(e) => setRedeemAmount(e.target.value)}
              className="mt-2 w-full rounded border px-3 py-2"
            />
            <div className="">
              <button
                onClick={(e: any) => handleRedeemTokens(e, false)}
                className="mt-4 w-full rounded bg-blue-600 py-2 text-white hover:bg-blue-700"
                disabled={loading}
              >
                {loading ? "Redeeming..." : "Redeem Tokens"}
              </button>
              <button
                onClick={(e: any) => handleRedeemTokens(e, true)}
                className="mt-4 w-full rounded bg-blue-600 py-2 text-white hover:bg-blue-700"
                disabled={loading}
              >
                {loading ? "Redeeming..." : "Redeem All"}
              </button>
            </div>
          </div>

          <div className="mt-8">
            <h2 className="my-4 text-xl font-semibold text-gray-700">
              Lock Tokens
            </h2>
            <input
              type="text"
              placeholder="Amount to lock"
              value={lockAmount}
              onChange={(e) => setLockAmount(e.target.value)}
              className="mt-2 w-full rounded border px-3 py-2"
            />
            <input
              type="text"
              placeholder="Duration in seconds"
              value={lockDuration}
              onChange={(e) => setLockDuration(e.target.value)}
              className="mt-2 w-full rounded border px-3 py-2"
            />
            <button
              onClick={handleLockTokens}
              className="mt-4 w-full rounded bg-blue-600 py-2 text-white hover:bg-blue-700"
              disabled={loading}
            >
              {loading ? "Locking..." : "Lock Tokens"}
            </button>
          </div>

          <div className="mt-8">
            <h2 className="my-4 text-xl font-semibold text-gray-700">
              Extend Lock
            </h2>
            <input
              type="text"
              placeholder="Additional duration in seconds"
              value={extendDuration}
              onChange={(e) => setExtendDuration(e.target.value)}
              className="mt-2 w-full rounded border px-3 py-2"
            />
            <button
              onClick={handleExtendLock}
              className="mt-4 w-full rounded bg-blue-600 py-2 text-white hover:bg-blue-700"
              disabled={loading}
            >
              {loading ? "Extending..." : "Extend Lock"}
            </button>
          </div>

          <div className="mt-8">
            <h2 className="my-4 text-xl font-semibold text-gray-700">
              Unlock Tokens
            </h2>
            <button
              onClick={handleUnlockTokens}
              className="mt-4 w-full rounded bg-blue-600 py-2 text-white hover:bg-blue-700"
              disabled={loading}
            >
              {loading ? "Unlocking..." : "Unlock Tokens"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ActivityDashboard);
