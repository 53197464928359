import config from "../config";

export const contactSupportErrorLabel = `Please try again and/or contact support: ${config.emails.supportEmail}`;

export const postFormErrorLabel = (formLabel: string) => {
  return `${formLabel} Error: ${contactSupportErrorLabel}`;
};

export const errorsToLabels = {
  userMustActivateAccount:
    "Activate your account using the token in your email titled 'Activate Account'",
  paymentInfoMissing: "Required payment information is missing.",
  failedToCancelSubscription: `Unable to cancel subscription: ${contactSupportErrorLabel}`,
  failedToCreateNewSubscription: `Unable to create a new subscription: ${contactSupportErrorLabel}`,
  failedToFetchPublicProfile: `Unable to fetch public profile: ${contactSupportErrorLabel}`,
  failedToFetchPublicSchedule: `Unable to fetch public schedule: ${contactSupportErrorLabel}`,
  failedToFetchRequiredUserData: `Unable to fetch required user data: ${contactSupportErrorLabel}`,
  failedtoLoadBidAnalyticsData: `Unable to load bid analytics data: ${contactSupportErrorLabel}`,
  failedToLoadBidsData: `Unable to load required bids data: ${contactSupportErrorLabel}`,
  failedToLoadStreamsData: `Unable to load required streams data: ${contactSupportErrorLabel}`,
  failedToLoadLiveFeedData: `Unable to load live feed (expired, inactive, or no payment): ${contactSupportErrorLabel}`,
  failedToLoadPaymentsData: `Unable to load required payments data: ${contactSupportErrorLabel}`,
  failedToLoadProductData: `Unable to load required product data: ${contactSupportErrorLabel}`,
  failedToLoadPropertyData: `Unable to load required property data: ${contactSupportErrorLabel}`,
  failedToPurchaseProduct: `Purchase failed: ${contactSupportErrorLabel}`,
  failedToSignOut: `Unable to sign out: ${contactSupportErrorLabel}`,
  failedToLoadRecommendations: `Unable to load recommendations: ${contactSupportErrorLabel}`,
  failedToSubmitScheduledBid: `Unable to submit scheduled bid: ${contactSupportErrorLabel}`,
  failedToSubmitSubscribedBid: `Unable to submit weekly subscribed message (free bid): ${contactSupportErrorLabel}`,
  failedToLoadSubscriptionData: `Unable to load required subscription data: ${contactSupportErrorLabel}`,
  failedToUpdateAccount: `Unable to update account information: ${contactSupportErrorLabel}`,
  failedToUpdateAutoAcceptBids: `Unable to update 'Auto Accept Bids' flag: ${contactSupportErrorLabel}`,
  failedToUpdateProfile: `Unable to update profile: ${contactSupportErrorLabel}`,
  failedToUpdateProfileImage: `Unable to update profile image: ${contactSupportErrorLabel}`,
  failedToUpdateSocialLinks: `Unable to update social link(s): ${contactSupportErrorLabel}`,
  failedToUpdateSubscriptionProductPrice: `Unable to update subscription product price: ${contactSupportErrorLabel}`,
  failedToOnboardToStripe: ` "Stripe connect creation (onboarding) failed. ${contactSupportErrorLabel}`,
  userInvalidIDDocumentError: `Invalid document`,
};
