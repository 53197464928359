export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const camelToSnake = (text: string) => {
  return text.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const snakeToCamel = (text: string) => {
  return text
    .split("_")
    .map((w) => capitalize(w))
    .join("");
};

export const snakeToNormal = (text: string) => {
  return text
    .split("_")
    .map((w) => capitalize(w))
    .join(" ");
};

export const truncateString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + "...";
};
