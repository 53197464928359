import config from "../../config";
import { contactSupportErrorLabel } from "../../utils/errors";

interface Common {
  apiDomain?: string;
  fetch(endpoint: string, options: CommonOptions | any): Promise<Response>;
}

interface CommonOptions {
  headers: Object | any;
}

class CommonService implements Common {
  apiDomain?: string;

  constructor() {
    this.apiDomain = config.links.baseAPIURL;
    this.fetch = this.fetch.bind(this);
    this.healthCheck = this.healthCheck.bind(this);
  }

  fetch(endpoint: string, options: CommonOptions | any) {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (options.headers) {
      headers = {
        ...headers,
        ...options.headers,
      };
    }

    return fetch(`${this.apiDomain}${endpoint}`, {
      ...options,
      headers,
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 400) {
          throw new Error(`Internal server error. ${contactSupportErrorLabel}`);
        }
        return response.json();
      })
      .catch((error) => {
        return { error };
      });
  }

  healthCheck() {
    return fetch("/healthz", { method: "GET" })
      .then((res) => res.status)
      .catch((err) => err);
  }
}

export default CommonService;
