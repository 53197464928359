import { IProduct } from "../../interfaces/product";
import {
  convertIPFSLinkToHTTPSLink,
  convertPaymentTypeToLabel,
  convertWeiToToken,
} from "../../utils/convert";
import { truncateString } from "../../utils/string";

export const ProductDetailsCard = (props: any) => {
  const product: IProduct = props.product;

  const priceString = convertWeiToToken(
    product.price,
    product.paymentType,
  ).toString();

  const getImageSRC = () => {
    if (product.imageHashes.length === 0) {
      return `${process.env.PUBLIC_URL}/fallback-list-img.jpg`;
    } else {
      // NOTE: First IPFS link
      return convertIPFSLinkToHTTPSLink(product.imageHashes[0]);
    }
  };

  const renderQuantityAmount = () => {
    if (!props.editQuantity) {
      return product.quantity;
    }
    return (
      <input
        type="number"
        name="quantity"
        value={props.quantity}
        onChange={props.onEditQuantity}
        placeholder="1"
        className="w-1/5 rounded-lg border-gray-300 p-2 shadow-sm focus:border-[rgb(65,105,225)] focus:ring focus:ring-[rgb(65,105,225)] focus:ring-opacity-50"
      />
    );
  };

  return (
    <div
      key={props.index}
      className="flex cursor-pointer flex-col overflow-hidden rounded-lg shadow-lg transition duration-300 ease-in-out hover:shadow-xl"
      onClick={(e: Event | any) => {
        props.selectItem(e, product);
      }}
    >
      <div className="flex-shrink-0">
        <img
          className="h-48 w-full object-cover"
          alt={`list-bg-${props.index}`}
          src={getImageSRC()}
        ></img>
      </div>
      <div className="flex flex-1 flex-col justify-between bg-white p-6">
        <div className="flex-1">
          <h2 className="text-xl font-semibold text-gray-900">
            {truncateString(product.title, 50)}
          </h2>
          <p className="text-sm font-medium text-gray-500">
            Category: {product.category}
          </p>
          <p className="text-sm font-medium text-gray-500">
            Quantity: {renderQuantityAmount()}
          </p>
          <p className="text-sm font-medium text-gray-500">
            Price: {priceString}{" "}
            {convertPaymentTypeToLabel(product.paymentType)}
          </p>
        </div>
      </div>
    </div>
  );
};
