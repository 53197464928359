import config from "../../config";

const CommonFooter = () => {
  return (
    <footer className="bg-[rgb(65,105,225)] py-4">
      <div className="mx-auto max-w-6xl text-center text-sm text-white">
        © {config.labels.domain} 2024 - All rights reserved |{" "}
        <a
          href="/legal?view=faq"
          target="_blank"
          rel="noreferrer"
          className="text-white hover:text-[rgb(65,105,225)]"
        >
          FAQ
        </a>{" "}
        |{" "}
        <a
          href="/legal"
          target="_blank"
          rel="noreferrer"
          className="text-white hover:text-[rgb(65,105,225)]"
        >
          Legal
        </a>
      </div>
    </footer>
  );
};

export default CommonFooter;
