import { useEffect } from "react";
import CommonHeader from "../../components/CommonHeader";
import CommonHomeBanner from "../../components/CommonHomeBanner";
import CommonProductsList from "../../components/CommonProductsList";
import CommonFooter from "../../components/CommonFooter";
import { Mixpanel } from "../../utils/analytics";
import { store } from "../../redux";

const Home = () => {
  useEffect(() => {
    const state = store.getState();
    Mixpanel.track("Home", {
      user: state.user,
    });
  }, []);

  return (
    <div className="home-container">
      <CommonHeader />
      <CommonHomeBanner />
      <CommonProductsList />
      <CommonFooter />
    </div>
  );
};

export default Home;
