import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ethers } from "ethers";
import queryString from "query-string";
import CommonHeader from "../../components/CommonHeader";
import { displayToastMessage } from "../../utils/toasts";
import CommonProductsList from "../../components/CommonProductsList";
import RatingsReviewsContract from "../../contracts/RatingsReviewsV1.sol/RatingsReviewsV1.json";
import {
  logIssueNetworkRequest,
  logIssueNetworkRequestError,
} from "../../utils/loggers";
import { contactSupportErrorLabel } from "../../utils/errors";
import { getNetworkConfig } from "../../utils/network";
import { Mixpanel } from "../../utils/analytics";
import config from "../../config";
import { convertIPFSLinkToHTTPSLink } from "../../utils/convert";

const SellerListings = () => {
  const location = useLocation();
  const user = useSelector((state: any) => state.user);

  const [queryWalletAddress, setQueryWalletAddress] = useState(null);
  const [sellerInfo, setSellerInfo] = useState({
    totalRatings: "...",
    averageRating: "...",
  });

  const loadSellerInformation = useCallback(async () => {
    logIssueNetworkRequest("SellerListings.loadSellerInformation()");

    try {
      const networkConfig = getNetworkConfig(user.chainId, user.provider);
      const provider = networkConfig.provider;
      const signer = await provider.getSigner();
      const ratingsReviewsContract = new ethers.Contract(
        networkConfig.addresses.RatingsReviews,
        RatingsReviewsContract.abi,
        signer,
      );

      const totalRatings =
        await ratingsReviewsContract.reviewCounts(queryWalletAddress);
      const averageRating =
        await ratingsReviewsContract.getAverageRating(queryWalletAddress);

      setSellerInfo({
        totalRatings: totalRatings.toString(),
        averageRating: averageRating.toString(),
      });

      return true;
    } catch (error: Error | any) {
      logIssueNetworkRequestError(
        "SellerListings.loadSellerInformation():",
        error,
      );
      return false;
    }
  }, [queryWalletAddress, user.chainId, user.provider]);

  useEffect(() => {
    const parsedQuery: any = queryString.parse(location.search);
    if (parsedQuery.address && ethers.isAddress(parsedQuery.address)) {
      setQueryWalletAddress(parsedQuery.address);
    } else {
      displayToastMessage("error", "Invalid seller address");
    }
  }, [location]);

  useEffect(() => {
    if (queryWalletAddress) {
      let isMounted = true;

      const fetchData = async () => {
        displayToastMessage("info", "Loading seller information...");
        const success = await loadSellerInformation();
        if (isMounted) {
          if (success) {
            displayToastMessage(
              "success",
              "Seller information loaded successfully",
            );
          } else {
            displayToastMessage(
              "error",
              `Failed to load seller data. ${contactSupportErrorLabel}`,
            );
          }
        }
      };

      fetchData();
      Mixpanel.track("SellerDashboard", { user });

      return () => {
        isMounted = false;
      };
    }
  }, [queryWalletAddress, loadSellerInformation, user]);

  const renderSellerInformation = () => (
    <div className="my-10 overflow-hidden rounded-lg bg-white shadow-lg">
      <div className="bg-gradient-to-r from-purple-600 to-indigo-600 px-6 py-4">
        <h1 className="break-words text-xl font-bold text-white sm:text-2xl">
          Seller: {queryWalletAddress}
        </h1>
      </div>
      <div className="p-6">
        <div className="flex flex-col items-center space-y-4 sm:flex-row sm:space-x-6 sm:space-y-0">
          <div className="flex-shrink-0">
            <img
              className="h-32 w-32 rounded-full object-cover shadow-lg"
              src={
                user.avatarImage
                  ? convertIPFSLinkToHTTPSLink(user.avatarImage)
                  : config.links.placeholderImage
              } // Placeholder image
              alt="Seller profile"
            />
          </div>
          <div className="flex-grow space-y-4">
            <div className="flex flex-col space-y-2 sm:flex-row sm:justify-between sm:space-y-0">
              <div className="flex items-center space-x-2">
                <span className="text-lg font-semibold text-gray-700">
                  Total Ratings:
                </span>
                <span className="text-2xl font-bold text-[#0F4C81]">
                  {sellerInfo.totalRatings}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <span className="text-lg font-semibold text-gray-700">
                  Average Rating:
                </span>
                <span className="text-2xl font-bold text-[#0F4C81]">
                  {sellerInfo.averageRating}
                </span>
              </div>
            </div>
            <div>
              <h2 className="text-lg font-semibold text-gray-800">About Me</h2>
              <p className="mt-2 text-gray-600">
                {user.profileAboutYouText ? user.profileAboutYouText : "N/A"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderDashboardContent = () => {
    if (queryWalletAddress === null) {
      return (
        <div className="rounded-lg bg-white p-6 shadow-lg">
          <h2 className="mb-4 text-lg font-semibold text-red-600">
            Failed to load listings.
          </h2>
          <button
            className="w-full rounded-lg bg-gradient-to-r from-blue-500 to-indigo-600 py-3 text-white transition duration-300 hover:from-blue-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            onClick={loadSellerInformation}
          >
            Reload
          </button>
        </div>
      );
    }
    return <CommonProductsList isSellerListings={true} />;
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200">
      <CommonHeader />
      <div className="container mx-auto px-4 py-8 lg:px-8">
        {renderSellerInformation()}
        {renderDashboardContent()}
      </div>
    </div>
  );
};

export default SellerListings;
