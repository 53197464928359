import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { Cookies } from "react-cookie-consent";
import { ToastContainer } from "react-toastify";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MoonPayProvider } from "@moonpay/moonpay-react";
import Auth from "../Auth";
import Home from "../Home";
import ShoppingCart from "../ShoppingCart";
import Legal from "../Legal";
import SellerDashboard from "../SellerDashboard";
import SellerListings from "../SellerListings";
import ProductDetails from "../ProductDetails";
import NewProductListing from "../NewProductListing";
import ConsumerDashboard from "../ConsumerDashboard";
import PageNotFound from "../PageNotFound";
import ActivityDashboard from "../ActivityDashboard";
import CookieConsentBanner, {
  removeAnalyticCookies,
} from "../../components/CookieConsentBanner";
import config from "../../config";
import { history } from "../../redux";
import CommonModal from "../../components/CommonModal";
import { Mixpanel } from "../../utils/analytics";
import "./index.css";

const handleProtectedRoutes = (Component: any, props: any) => {
  const consentCookie = Cookies.get(config.cookies.consentCookieLabel);
  if (consentCookie && consentCookie === "false") {
    removeAnalyticCookies();
  }
  return <Component {...props} />;
};

const queryClient = new QueryClient();

export const homeRoutePath = "/";
export const shoppingCardPath = "/cart";
export const authRoutePath = "/auth";
export const legalRoutePath = "/legal";
export const sellerDashboardPath = "/seller/profile";
export const sellerListingsPath = "/seller/listings";
export const productDetailsPath = "/product/details";
export const newProductListingPath = "/product/create";
export const consumerDashboardPath = "/account";
export const activityDashboardPath = "/activity";

const CoreRouter = (props: any) => {
  const [showModal, setShowModal] = useState(true);

  const renderGlobalNotificationModal = () => {
    if (typeof window !== "undefined" && window.localStorage) {
      const sawNotification: any = window.localStorage.getItem("gn");
      if (sawNotification === "true") {
        return;
      }
      if (!showModal) {
        return;
      }
      return (
        <CommonModal
          showModalOnly
          toggleButtonText="Go Explore"
          modalTitle="Welcome to LayerMarket (Beta)!"
          modalDescription="Discover a new way to buy and sell products on Ethereum and Layer 2 networks"
          childElement={
            <div className="space-y-4 text-gray-700">
              <p className="font-medium">
                Welcome to the beta version of LayerMarket!
              </p>
              <p className="font-bold">
                LayerMarket is an online marketplace to buy and sell business
                assets, such as Datasets and IP, on top of Ethereum and Layer 2
                networks (Arbitrum, Base, and Optimism).
              </p>
              <p className="font-bold text-blue-600">
                <a
                  href="https://docs.layermarket.xyz/getting-started/how-to-use"
                  target="_blank"
                  rel="norefferer"
                  id="learn-more-link"
                >
                  *Learn how to connect to our application/service here.
                </a>
              </p>
              <p className="font-semibold text-amber-600">
                PLEASE NOTE: This is a beta version and may contain bugs. It is
                not intended for production use.
              </p>
              <p>
                To get started,{" "}
                <span className="font-semibold">
                  make sure to connect your wallet
                </span>
                . Explore the platform and send us your feedback at:
                <a
                  href="mailto:engineering@layermarket.xyz"
                  className="ml-1 text-blue-600 hover:underline"
                >
                  engineering@layermarket.xyz
                </a>
              </p>
            </div>
          }
          onSubmit={() => {
            Mixpanel.track("General Notification", {});
            window.localStorage.setItem("gn", "true");
            setShowModal(false);
          }}
        />
      );
    }
  };
  return (
    <Router history={history}>
      <WagmiProvider config={config.web3.walletChainConfig}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider>
            <MoonPayProvider
              apiKey={config.moonPay.apiKey}
              debug={config.moonPay.debug}
            >
              <div className="main">
                <Routes>
                  <Route
                    path={homeRoutePath}
                    Component={(props: any): any =>
                      handleProtectedRoutes(Home, props)
                    }
                  />
                  <Route
                    path={`${shoppingCardPath}/*`}
                    Component={(props: any): any =>
                      handleProtectedRoutes(ShoppingCart, props)
                    }
                  />
                  <Route
                    path={`${authRoutePath}/*`}
                    Component={(props: any): any =>
                      handleProtectedRoutes(Auth, props)
                    }
                  />
                  <Route
                    path={`${legalRoutePath}/*`}
                    Component={(props: any): any =>
                      handleProtectedRoutes(Legal, props)
                    }
                  />
                  <Route
                    path={`${sellerDashboardPath}/*`}
                    Component={(props: any): any =>
                      handleProtectedRoutes(SellerDashboard, props)
                    }
                  />
                  <Route
                    path={`${sellerListingsPath}/*`}
                    Component={(props: any): any =>
                      handleProtectedRoutes(SellerListings, props)
                    }
                  />
                  <Route
                    path={`${newProductListingPath}/*`}
                    Component={(props: any): any =>
                      handleProtectedRoutes(NewProductListing, props)
                    }
                  />
                  <Route
                    path={`${productDetailsPath}/*`}
                    Component={(props: any): any =>
                      handleProtectedRoutes(ProductDetails, props)
                    }
                  />
                  <Route
                    path={`${consumerDashboardPath}/*`}
                    Component={(props: any): any =>
                      handleProtectedRoutes(ConsumerDashboard, props)
                    }
                  />
                  <Route
                    path={`${activityDashboardPath}/*`}
                    Component={(props: any): any =>
                      handleProtectedRoutes(ActivityDashboard, props)
                    }
                  />
                  <Route path="*" Component={PageNotFound} />
                </Routes>
                <ToastContainer />
                <CookieConsentBanner />
                {renderGlobalNotificationModal()}
              </div>
            </MoonPayProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </Router>
  );
};

export default CoreRouter;
