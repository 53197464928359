import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonHeader from "../../components/CommonHeader";
// import ProductsContract from "../../contracts/Products.sol/Products.json";
import ProductsContract from "../../contracts/ProductsV1.sol/ProductsV1.json";
import PurchaseEscrowContract from "../../contracts/PurchaseEscrowV1.sol/PurchaseEscrowV1.json";
import { ProductDetailsCard } from "../../components/ProductDetailsCard";
import { displayToastMessage } from "../../utils/toasts";
import {
  logIssueNetworkRequest,
  logIssueNetworkRequestError,
} from "../../utils/loggers";
import { ethers } from "ethers";
import {
  convertProductResultToObject,
  convertPurchaseResultToObject,
} from "../../utils/convert";
import { getNetworkConfig } from "../../utils/network";
import { Mixpanel } from "../../utils/analytics";
import NotificationService from "../../services/Notification";

type ProductType = "Disputed" | "Purchased";

interface ProductInfo {
  disputedProductID: string;
  selectedDisputedProduct: any | null;
  purchasedProductID: string;
  selectedPurchasedProduct: any | null;
}

const ConsumerDashboard = () => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user);

  const [notificationInfo, setNotificationInfo] = useState({
    userEmailAddress: user?.email || "",
    userWalletAddress: user?.walletAddress || "",
  });

  const [productInfo, setProductInfo] = useState<ProductInfo>({
    disputedProductID: "",
    selectedDisputedProduct: null,
    purchasedProductID: "",
    selectedPurchasedProduct: null,
  });

  const notificationService = new NotificationService();

  useEffect(() => {
    displayToastMessage("info", "Loading...");
    logIssueNetworkRequest("ConsumerDashboard.componentDidMount()");
    Mixpanel.track("ConsumerDashboard", { user });
  }, [user]);

  const fetchProduct = useCallback(
    async (evt: Event | any, type: ProductType) => {
      evt.preventDefault();
      displayToastMessage("info", "Loading...");

      const productID =
        type === "Disputed"
          ? productInfo.disputedProductID
          : productInfo.purchasedProductID;

      try {
        const networkConfig = getNetworkConfig(user.chainId, user.provider);
        const provider = networkConfig.provider;
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(
          networkConfig.addresses.Products,
          ProductsContract.abi,
          signer,
        );
        const purchaseEscrowContract = new ethers.Contract(
          networkConfig.addresses.PurchaseEscrow,
          PurchaseEscrowContract.abi,
          signer,
        );

        let proxyProduct = await contract.getProduct(productID);
        const product = convertProductResultToObject(proxyProduct);
        const proxyPurchase =
          await purchaseEscrowContract.productIDToPurchase(productID);
        const purchase = convertPurchaseResultToObject(proxyPurchase);

        if (type === "Disputed" && !purchase.isDisputed) {
          setProductInfo((prev) => ({
            ...prev,
            selectedDisputedProduct: null,
          }));
          displayToastMessage("error", "Product is not marked as disputed.");
          return;
        }

        if (type === "Purchased" && purchase.buyer !== user.walletAddress) {
          setProductInfo((prev) => ({
            ...prev,
            selectedPurchasedProduct: null,
          }));
          displayToastMessage(
            "error",
            "Failed to fetch purchased product details",
          );
          return;
        }

        setProductInfo((prev) => ({
          ...prev,
          [type === "Disputed"
            ? "selectedDisputedProduct"
            : "selectedPurchasedProduct"]: product,
        }));
      } catch (error: Error | any) {
        logIssueNetworkRequestError("ConsumerDashboard.fetchProduct()", error);
        setProductInfo((prev) => ({
          ...prev,
          selectedDisputedProduct: null,
          selectedPurchasedProduct: null,
        }));
        displayToastMessage("error", "Failed to fetch product details.");
      }
    },
    [user, productInfo.disputedProductID, productInfo.purchasedProductID],
  );

  const handleUpdateNotificationAccount = async (evt: Event | any) => {
    evt.preventDefault();
    displayToastMessage("info", "Loading...");
    logIssueNetworkRequest(
      "ConsumerDashboard.handleUpdateNotificationAccount()",
    );

    try {
      const res = await notificationService.updateNotificationAccount({
        userID: user.id,
        email: notificationInfo.userEmailAddress,
        walletAddress: notificationInfo.userWalletAddress,
      });
      if (res.error) throw new Error(res.error);

      displayToastMessage("success", "Successfully updated.");

      Mixpanel.track("Updated Notfication Account - Success", {
        userID: user.id,
        email: notificationInfo.userEmailAddress,
      });
    } catch (error: Error | any) {
      logIssueNetworkRequestError(
        "ConsumerDashboard.handleUpdateNotificationAccount()",
        error,
      );
      Mixpanel.track("Updated Notfication Account - Failure", {
        error,
      });
    }
  };

  const renderConsumerNotificationCenter = () => (
    <div className="mb-8 overflow-hidden rounded-lg bg-white shadow-lg">
      <div className="bg-gradient-to-r from-blue-500 to-indigo-600 px-6 py-4">
        <h2 className="text-xl font-bold text-white">Notification Center</h2>
        <p className="text-sm text-blue-100">Registered Users Only</p>
      </div>
      <div className="p-6">
        <div className="space-y-4">
          {["Email Address", "Wallet Address"].map((field) => (
            <div key={field} className="flex flex-col">
              <label className="mb-1 text-sm font-medium text-gray-700">
                {field}:
              </label>
              <input
                type="text"
                className="rounded-md border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                placeholder={`${field} for notifications`}
                value={
                  notificationInfo[
                    field === "Email Address"
                      ? "userEmailAddress"
                      : "userWalletAddress"
                  ]
                }
                onChange={(e) =>
                  setNotificationInfo((prev) => ({
                    ...prev,
                    [field === "Email Address"
                      ? "userEmailAddress"
                      : "userWalletAddress"]: e.target.value,
                  }))
                }
              />
            </div>
          ))}
          <button
            className="w-full transform rounded-md bg-gradient-to-r from-blue-500 to-indigo-600 px-4 py-2 text-white transition duration-300 ease-in-out hover:from-blue-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            onClick={handleUpdateNotificationAccount}
          >
            Update Notification Settings
          </button>
        </div>
      </div>
    </div>
  );

  const renderProductSection = (type: ProductType) => (
    <div className="mb-8 overflow-hidden rounded-lg bg-white shadow-lg">
      <div
        className={`bg-gradient-to-r ${type === "Purchased" ? "from-green-500 to-teal-600" : "from-red-500 to-pink-600"} px-6 py-4`}
      >
        <h2 className="text-xl font-bold text-white">
          {type === "Purchased" ? "Purchase Center" : "Dispute Center"}
        </h2>
      </div>
      <div className="p-6">
        <div className="mb-4 flex flex-col">
          <label className="mb-1 text-sm font-medium text-gray-700">
            Product ID:
          </label>
          <div className="flex">
            <input
              type="text"
              className={`flex-grow rounded-l-md border-gray-300 px-4 py-2 focus:border-${type === "Purchased" ? "green" : "red"}-500 focus:ring focus:ring-${type === "Purchased" ? "green" : "red"}-500 focus:ring-opacity-50`}
              placeholder={`Enter the ${type.toLowerCase()} product's ID...`}
              value={
                productInfo[
                  `${type.toLowerCase()}ProductID` as keyof ProductInfo
                ]
              }
              onChange={(e) =>
                setProductInfo((prev) => ({
                  ...prev,
                  [`${type.toLowerCase()}ProductID`]: e.target.value,
                }))
              }
            />
            <button
              className={`rounded-r-md bg-gradient-to-r ${type === "Purchased" ? "from-green-500 to-teal-600 hover:from-green-600 hover:to-teal-700" : "from-red-500 to-pink-600 hover:from-red-600 hover:to-pink-700"} px-4 py-2 text-white transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-${type === "Purchased" ? "green" : "red"}-500 focus:ring-opacity-50`}
              onClick={(e) => fetchProduct(e, type)}
            >
              Search
            </button>
          </div>
        </div>
        <div className="mt-4">
          {productInfo[`selected${type}Product` as keyof ProductInfo] && (
            <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md">
              <ProductDetailsCard
                key={
                  (
                    productInfo[
                      `selected${type}Product` as keyof ProductInfo
                    ] as any
                  ).productId
                }
                product={
                  productInfo[
                    `selected${type}Product` as keyof ProductInfo
                  ] as any
                }
                selectItem={() =>
                  navigate(
                    `/product/details?id=${(productInfo[`selected${type}Product` as keyof ProductInfo] as any).productId}`,
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200">
      <CommonHeader />
      <div className="container mx-auto px-4 py-8 lg:px-8">
        <h1 className="mb-8 text-center text-4xl font-bold text-gray-900">
          Account
        </h1>
        <div className="mx-auto max-w-6xl">
          {renderConsumerNotificationCenter()}
          {renderProductSection("Purchased")}
          {renderProductSection("Disputed")}
        </div>
      </div>
    </div>
  );
};

export default ConsumerDashboard;
