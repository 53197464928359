import { ethers } from "ethers";
import config from "../config";

const { contractAddresses, nodes } = config.eth;

export interface Web3NetworkConfigAddresses {
  Fees?: ethers.Contract | any;
  Products?: ethers.Contract | any;
  PurchaseEscrow?: ethers.Contract | any;
  PurchaseEscrowUSDC: string;
  PurchaseEscrowDAI: string;
  PurchaseEscrowGHO: string;
  GHO: string;
  USDC: string;
  DAI: string;
  RatingsReviews?: ethers.Contract | any;
  Validators?: ethers.Contract | any;
  CoreValidatorAddress?: ethers.AddressLike | any;
}

export interface Web3NetworkConfig {
  addresses?: Web3NetworkConfigAddresses | any;
  provider?: ethers.JsonRpcProvider | any;
  error?: Error | any;
}

export const getNetworkConfig = (
  chainId: number,
  injectedProvider?: ethers.JsonRpcProvider | undefined,
): Web3NetworkConfig => {
  let provider;
  console.log(
    "getNetworkConfig() chainId, contractAddresses:",
    chainId,
    contractAddresses,
  );

  switch (chainId) {
    case 1:
      // ETH Mainnet
      provider = injectedProvider
        ? injectedProvider
        : new ethers.JsonRpcProvider(nodes.eth.mainnet.nodeProviderURL);
      return {
        addresses: { ...contractAddresses.eth.mainnet },
        provider,
      };
    case 11155111:
      // ETH Sepolia
      provider = injectedProvider
        ? injectedProvider
        : new ethers.JsonRpcProvider(nodes.eth.testnet.nodeProviderURL);
      return {
        addresses: { ...contractAddresses.eth.testnet },
        provider,
      };
    case 42161:
      // Arbitrum One
      provider = injectedProvider
        ? injectedProvider
        : new ethers.JsonRpcProvider(nodes.arbitrum.mainnet.nodeProviderURL);
      return {
        addresses: { ...contractAddresses.arbitrum.mainnet },
        provider,
      };
    case 421614:
      // Arbitrum Sepolia
      provider = injectedProvider
        ? injectedProvider
        : new ethers.BrowserProvider(window.ethereum, chainId);
      return {
        addresses: { ...contractAddresses.arbitrum.testnet },
        provider,
      };
    case 8453:
      // BASE Mainnet
      provider = injectedProvider
        ? injectedProvider
        : new ethers.JsonRpcProvider(nodes.base.mainnet.nodeProviderURL);
      return {
        addresses: { ...contractAddresses.base.mainnet },
        provider,
      };
    case 84532:
      // BASE Sepolia
      provider = injectedProvider
        ? injectedProvider
        : new ethers.BrowserProvider(window.ethereum, chainId);
      return {
        addresses: { ...contractAddresses.base.testnet },
        provider,
      };
    case 31337:
      // Localchain
      provider = injectedProvider
        ? injectedProvider
        : new ethers.JsonRpcProvider(nodes.localchain.testnet.nodeProviderURL);
      return {
        addresses: { ...contractAddresses.localchain.testnet },
        provider,
      };
    case 10:
      // OP Mainnet
      provider = injectedProvider
        ? injectedProvider
        : new ethers.JsonRpcProvider(nodes.op.mainnet.nodeProviderURL);
      return {
        addresses: { ...contractAddresses.op.testnet },
        provider,
      };
    case 11155420:
      // OP Sepolia
      provider = injectedProvider
        ? injectedProvider
        : new ethers.BrowserProvider(window.ethereum, chainId);
      return {
        addresses: { ...contractAddresses.op.testnet },
        provider,
      };
    default:
      // Localchain
      provider = injectedProvider
        ? injectedProvider
        : new ethers.JsonRpcProvider(nodes.localchain.testnet.nodeProviderURL);
      return {
        addresses: { ...contractAddresses.localchain.testnet },
        provider,
      };
  }
};
