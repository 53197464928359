import { MoonPayBuyWidget } from "@moonpay/moonpay-react";
import { useState, Fragment } from "react";
import { Mixpanel } from "../../utils/analytics";

export default function FiatCryptoOnramp() {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <MoonPayBuyWidget
        variant="overlay"
        baseCurrencyCode="usd"
        baseCurrencyAmount="100"
        defaultCurrencyCode="eth"
        onLogin={async () => console.log("Customer logged in!")}
        visible={visible}
      />
      <Fragment>
        <button
          onClick={() => {
            setVisible(!visible);
            Mixpanel.track("Clicked MoonPay Onramp", {});
          }}
          className="block px-2 py-2 text-lg text-blue-800"
        >
          ETH
        </button>
      </Fragment>
    </div>
  );
}
