import { ethers } from "ethers";
import { IProduct } from "../interfaces/product";
import { IPurchase } from "../interfaces/payment";
import { OrderByKeyType, OrderByValueType } from "../interfaces/search";

export const convertProductResultToObject = (result: any): IProduct => {
  return {
    isActive: result[0],
    productId: result[1].toString(), // Convert BigInt to string
    sellerAddress: result[2],
    validatorAddress: result[3],
    category: result[4],
    title: result[5],
    description: result[6],
    imageHashes: [...result[7]],
    quantity: result[8].toString(), // Convert BigInt to string
    price: result[9].toString(), // Convert BigInt to string
    inEscrow: result[10],
    paymentType: Number(result[11].toString()),
  };
};

export const convertPurchaseResultToObject = (result: any): IPurchase => {
  return {
    exists: result[0],
    purchaseId: result[1].toString(),
    productId: result[2].toString(),
    quantity: result[3].toString(),
    buyer: result[4],
    seller: result[5],
    validator: result[6],
    amount: result[7].toString(),
    isDisputed: result[8],
    isFinalized: result[9],
  };
};

export const sortFilterToOrderByKey = (sortFilter: string): OrderByKeyType => {
  switch (sortFilter) {
    case "Listed: Latest":
    case "Listed: Earliest":
      return "created_at";
    case "Price: High to low":
    case "Price: Low to high":
      return "price";
    default:
      return "created_at";
  }
};

export const sortFilterToOrderByValue = (
  sortFilter: string,
): OrderByValueType => {
  switch (sortFilter) {
    case "Listed: Latest":
    case "Name: Z to A":
    case "Price: High to low":
      return "DESC";
    case "Listed: Earliest":
    case "Name: A to Z":
    case "Price: Low to high":
      return "ASC";
    default:
      return "DESC";
  }
};

export const convertIPFSLinkToHTTPSLink = (imageHash: string) => {
  return imageHash.replace("ipfs://", "https://ipfs.io/ipfs/");
};

export const escapeWildcards = (str: string): string => {
  str = str.replaceAll("\\", "\\\\");
  str = str.replaceAll("_", "\\_");
  str = str.replaceAll("%", "%");
  return str;
};

export const convertPaymentTypeToLabel = (paymentType: number) => {
  switch (paymentType) {
    case 0:
      return "ETH";
    case 1:
      return "USDC";
    case 2:
      return "DAI";
    case 3:
      return "GHO";
    default:
      return "ETH";
  }
};

export const TOKEN_DECIMALS: any = {
  // ETH
  0: 18,
  // USDC
  1: 6,
  // DAI
  2: 18,
  // GHO
  3: 18,
};

export const convertTokenAmount = (
  amount: string | number,
  paymentType: number,
  toHuman: boolean = true,
) => {
  const decimals = TOKEN_DECIMALS[paymentType];

  if (toHuman) {
    if (paymentType === 1) {
      // For USDC, we need to adjust the amount first
      const adjustedAmount = BigInt(amount) / BigInt(10 ** 12); // Divide by 10^12 to get 10^6 i.e 6 decimals
      return ethers.formatUnits(adjustedAmount, decimals);
    } else {
      return ethers.formatUnits(amount, decimals);
    }
  } else {
    return ethers.parseUnits(amount.toString(), decimals);
  }
};

// Update the existing convertWeiToETH function to be more generic
export const convertWeiToToken = (wei: any, paymentType: number) => {
  return convertTokenAmount(wei, paymentType);
};

export const convertTokenToWei = (
  amount: number | string,
  paymentType: number,
) => {
  return convertTokenAmount(amount, paymentType, false);
};

export const convertETHInWeiToUSD = (ethInWei: string | number) => {
  // TODO: Wei => ETH => USD
};

export const convertWeiToETH = (wei: any) => {
  return ethers.formatUnits(wei, "ether");
};

export const convertETHToWei = (eth: number) => {
  return ethers.parseUnits(String(eth), "ether");
};
