import React from "react";
import { connect } from "react-redux";
import { logIssueNetworkRequestError } from "../../utils/loggers";
import { displayToastMessage } from "../../utils/toasts";
import SearchService from "../../services/Search";
import config from "../../config";
// import ProductsContract from "../../contracts/Products.sol/Products.json";
import ProductsContract from "../../contracts/ProductsV1.sol/ProductsV1.json";
import {
  OrderByKeyType,
  OrderByValueType,
  SearchProductsResponse,
} from "../../interfaces/search";
import { IProduct } from "../../interfaces/product";
import { ProductDetailsCard } from "../../components/ProductDetailsCard";
import { history } from "../../redux";
import { ethers } from "ethers";
import {
  convertProductResultToObject,
  escapeWildcards,
  sortFilterToOrderByKey,
  sortFilterToOrderByValue,
} from "../../utils/convert";
import queryString from "query-string";
import { Web3NetworkConfig, getNetworkConfig } from "../../utils/network";
import { Mixpanel } from "../../utils/analytics";

class CommonProductsList extends React.Component<any, any> {
  SearchService: SearchService;

  constructor(props: any) {
    super(props);

    const parsedQuery = queryString.parse(props.router.location.search);
    let queryWalletAddress = null;
    if (parsedQuery.address && ethers.isAddress(parsedQuery.address)) {
      queryWalletAddress = parsedQuery.address;
    }

    this.state = {
      queryWalletAddress: props.isSellerDashboard ? queryWalletAddress : null,
      searchValue: props.isSellerDashboard
        ? queryWalletAddress === null
          ? String(props.sellerID)
          : queryWalletAddress
        : "",
      selectedFilterType: props.isSellerDashboard
        ? queryWalletAddress === null
          ? "seller_id"
          : "seller_address"
        : config.products.searchFilterTypes[0],
      selectedCategory: config.products.searchCategories[0],
      selectedOrderByType: config.products.searchOrderByTypes[0],
      products: null,
      // products: [
      //   { title: "Random Title", quantity: 69, price: 1000, imageHashes: [] },
      //   { title: "Random Title", quantity: 69, price: 1000, imageHashes: [] },
      //   { title: "Random Title", quantity: 69, price: 1000, imageHashes: [] },
      //   { title: "Random Title", quantity: 69, price: 1000, imageHashes: [] },
      //   { title: "Random Title", quantity: 69, price: 1000, imageHashes: [] },
      //   { title: "Random Title", quantity: 69, price: 1000, imageHashes: [] },
      //   { title: "Random Title", quantity: 69, price: 1000, imageHashes: [] },
      //   { title: "Random Title", quantity: 69, price: 1000, imageHashes: [] },
      //   { title: "Random Title", quantity: 69, price: 1000, imageHashes: [] },
      //   { title: "Random Title", quantity: 69, price: 1000, imageHashes: [] },
      //   { title: "Random Title", quantity: 69, price: 1000, imageHashes: [] },
      //   { title: "Random Title", quantity: 69, price: 1000, imageHashes: [] },
      //   { title: "Random Title", quantity: 69, price: 1000, imageHashes: [] },
      // ],
      totalProductsFound: 0,
      searchOptions: {
        offset: 0,
        limit: 8,
        orderByKey: "created_at",
        orderByValue: "DESC",
      },
    };

    this.SearchService = new SearchService();

    this.loadProducts = this.loadProducts.bind(this);
    this.renderSearchFilterOptions = this.renderSearchFilterOptions.bind(this);
    this.renderProductsList = this.renderProductsList.bind(this);
  }

  async componentDidMount() {
    setTimeout(() => {
      this.loadProducts();
    });
  }

  async loadProducts(evt?: Event | any) {
    if (evt) {
      evt.preventDefault();
    }

    displayToastMessage("info", "Loading...", {
      autoClose: 2000,
      position: "bottom-left",
      toastId: "LoadingCPL",
    });

    try {
      const orderByKey: OrderByKeyType = sortFilterToOrderByKey(
        this.state.selectedOrderByType,
      );
      const orderByValue: OrderByValueType = sortFilterToOrderByValue(
        this.state.selectedOrderByType,
      );
      const searchData = {
        searchValue: escapeWildcards(this.state.searchValue),
        networkChainId: this.props.user.chainId,
        sellerAddress: this.state.queryWalletAddress,
        category: this.state.selectedCategory,
        offset: this.state.searchOptions.offset,
        limit: this.state.searchOptions.limit,
        filterType: this.state.selectedFilterType,
        orderByKey,
        orderByValue,
      };

      const res: SearchProductsResponse =
        await this.SearchService.searchProducts(searchData);
      if (res.error) {
        throw new Error(res.error);
      }
      console.log("res.products: ", res.products);

      const networkConfig: Web3NetworkConfig = getNetworkConfig(
        this.props.user.chainId,
        this.props.user.provider,
      );
      console.log("networkConfig:", networkConfig);

      const signer = await networkConfig.provider.getSigner();
      const contract = new ethers.Contract(
        networkConfig.addresses.Products,
        ProductsContract.abi,
        signer,
      );

      const finalProducts = [];
      for (let product of res.products) {
        try {
          const result = await contract.getProduct(product.product_id);
          console.log("result:", result);
          const finalProduct: IProduct | any =
            convertProductResultToObject(result);

          console.log("finalProduct:", finalProduct);

          if (!finalProduct.isActive) {
            continue;
          }

          finalProduct["created_at"] = product["created_at"];

          finalProducts.push(finalProduct);
        } catch (error: Error | any) {
          logIssueNetworkRequestError(
            "CommonProductsList.loadProducts() getProduct()",
            error,
          );
        }
      }

      if (orderByKey === "price") {
        finalProducts.sort((p1: IProduct, p2: IProduct) => {
          if (orderByValue === "ASC") {
            return p1.price - p2.price;
          } else {
            return p2.price - p1.price;
          }
        });
      }

      displayToastMessage("success", "Loaded", {
        autoClose: 2000,
        position: "bottom-left",
        toastId: "LoadedCPL",
      });

      Mixpanel.track("Load/Search Products - Success", searchData);

      this.setState({
        products: finalProducts,
        totalProductsFound: res.totalFound,
      });
    } catch (error: Error | any) {
      logIssueNetworkRequestError("CommonProductsList.loadProducts()", error);
      displayToastMessage(
        "error",
        "Network error: Please refresh and/or check network settings.",
        {
          autoClose: 4000,
          position: "bottom-left",
          toastId: "ErrorCPL",
        },
      );
      Mixpanel.track("Load/Search Products - Failure", { error });
      this.setState({ products: [] });
    }
  }

  handleLoadMoreProducts(offset: number) {
    this.setState(
      {
        searchOptions: {
          ...this.state.searchOptions,
          offset,
        },
      },
      async () => {
        displayToastMessage("info", "Loading");
        await this.loadProducts();
      },
    );
  }

  renderSearchFilterOptions() {
    return (
      <div className="mb-6 flex flex-col space-y-4 text-white md:flex-row md:items-center md:space-x-6 md:space-y-0">
        <div className="flex-1">
          <label
            htmlFor="category-select"
            className="mb-2 block text-sm font-medium"
          >
            Category:
          </label>
          <select
            id="category-select"
            value={this.state.selectedCategory}
            onChange={(e) =>
              this.setState({ selectedCategory: e.target.value })
            }
            className="w-full rounded-lg bg-white bg-opacity-20 p-2 text-white placeholder-white placeholder-opacity-70 shadow-sm backdrop-blur-sm transition duration-300 focus:border-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
          >
            {config.products.searchCategories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <div className="flex-1">
          <label
            htmlFor="order-by-select"
            className="mb-2 block text-sm font-medium"
          >
            Filter:
          </label>
          <select
            id="order-by-select"
            value={this.state.selectedOrderByType}
            onChange={(e) =>
              this.setState({ selectedOrderByType: e.target.value })
            }
            className="w-full rounded-lg bg-white bg-opacity-20 p-2 text-white placeholder-white placeholder-opacity-70 shadow-sm backdrop-blur-sm transition duration-300 focus:border-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
          >
            {config.products.searchOrderByTypes.map((orderByType) => (
              <option key={orderByType} value={orderByType}>
                {orderByType}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  renderSearchBar() {
    return (
      <div className="flex flex-col space-y-4 md:flex-row md:items-center md:space-x-4 md:space-y-0">
        <select
          id="filter-select"
          value={this.state.selectedFilterType}
          onChange={(e) =>
            this.setState({ selectedFilterType: e.target.value })
          }
          className="w-full rounded-lg bg-white bg-opacity-20 p-2 text-white placeholder-white placeholder-opacity-70 shadow-sm backdrop-blur-sm transition duration-300 focus:border-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 md:w-1/4"
        >
          {config.products.searchFilterTypes.map((filterType) => (
            <option key={filterType} value={filterType}>
              {filterType}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Search"
          value={this.state.searchValue}
          onChange={(e) => this.setState({ searchValue: e.target.value })}
          className="flex-1 rounded-lg bg-white bg-opacity-20 p-2 text-white placeholder-white placeholder-opacity-70 shadow-sm backdrop-blur-sm transition duration-300 focus:border-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
          onKeyDown={async (evt) => {
            if (evt.key === "Enter") {
              await this.loadProducts(evt);
            }
          }}
        />
        <button
          onClick={this.loadProducts}
          className="rounded-lg bg-white bg-opacity-20 px-6 py-2 text-white shadow-sm backdrop-blur-sm transition duration-300 hover:bg-opacity-30 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
        >
          Search
        </button>
      </div>
    );
  }

  renderProductsList() {
    if (this.state.products === null) {
      return (
        <div className="flex h-64 items-center justify-center">
          <div className="text-center">
            <div className="mb-4 h-12 w-12 animate-spin rounded-full border-4 border-blue-500 border-t-transparent"></div>
            <p className="text-lg font-medium text-gray-600">
              Loading Products...
            </p>
          </div>
        </div>
      );
    }
    if (this.state.products.length === 0) {
      let noProductsConnectWalletText = "No products found";
      if (!(this.props.user && this.props.user.walletAddress)) {
        noProductsConnectWalletText = "Please connect your wallet above";
      }
      return (
        <div className="flex h-64 items-center justify-center">
          <div className="text-center">
            <h2 className="mb-4 text-xl font-semibold text-red-600">
              {noProductsConnectWalletText}
            </h2>
            <button
              className="rounded-lg bg-blue-500 px-6 py-3 text-white transition duration-300 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              onClick={this.loadProducts}
            >
              Reload
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {this.state.products.map((product: IProduct, i: number) => (
          <ProductDetailsCard
            key={i}
            index={i}
            product={product}
            selectItem={(e: any) => {
              e.preventDefault();
              Mixpanel.track("Clicked Product Item", {
                productID: product.productId,
              });
              history.push(`/product/details?id=${product.productId}`);
            }}
          />
        ))}
      </div>
    );
  }

  renderLoadMoreButtons() {
    if (this.state.totalProductsFound !== 0) {
      const { limit, offset } = this.state.searchOptions;
      const disablePrevButton = offset === 0;
      const disableNextButton =
        offset + limit >= this.state.totalProductsFound ||
        this.state.products.length === 0;
      return (
        <div className="mt-8 flex justify-center space-x-4">
          <button
            disabled={disablePrevButton}
            className={`rounded-lg px-6 py-3 text-white transition duration-300 focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
              disablePrevButton
                ? "cursor-not-allowed bg-gray-400"
                : "bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 focus:ring-indigo-500"
            }`}
            onClick={() => this.handleLoadMoreProducts(offset - limit)}
          >
            Prev
          </button>
          <button
            disabled={disableNextButton}
            className={`rounded-lg px-6 py-3 text-white transition duration-300 focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
              disableNextButton
                ? "cursor-not-allowed bg-gray-400"
                : "bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 focus:ring-indigo-500"
            }`}
            onClick={() => this.handleLoadMoreProducts(offset + limit)}
          >
            Next
          </button>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200">
        <div className="container mx-auto px-4 py-8">
          <div className="mb-8 rounded-xl bg-gradient-to-r from-purple-600 to-indigo-600 p-6 shadow-lg">
            {this.renderSearchFilterOptions()}
            {this.renderSearchBar()}
          </div>
          {this.renderProductsList()}
          {this.renderLoadMoreButtons()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Object | any) => ({
  router: state.router,
  user: state.user,
});

export default connect(mapStateToProps, null)(CommonProductsList as any) as any;
