import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { loadStripe } from "@stripe/stripe-js";
import config from "../../config";
import {
  ActivateAccountRequest,
  AuthState,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  IDVerificationStartResponse,
  LogInResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SignUpLogInRequest,
  SignUpResponse,
} from "../../interfaces/auth";
import { IUser } from "../../interfaces/user";
import { setUser } from "../../redux/actions/user";
import AccountService from "../../services/Account";
import SessionService from "../../services/Session";
import { errorsToLabels, postFormErrorLabel } from "../../utils/errors";
import {
  logIssueNetworkRequest,
  logIssueNetworkRequestError,
  logUpdatedState,
} from "../../utils/loggers";
import { displayToastMessage } from "../../utils/toasts";
import { homeRoutePath } from "../Router";
import { Mixpanel } from "../../utils/analytics";
import { history } from "../../redux";

class Auth extends React.Component<any, AuthState | any> {
  AccountService: AccountService;
  SessionService: SessionService;

  constructor(props: any) {
    super(props);

    this.state = {
      currentView: "Log In",
      signUpLogInForm: {
        email: "",
        phoneNumber: "",
        password: "",
      } as SignUpLogInRequest,
      activateAccountForm: {
        email: "",
        activationToken: "",
      } as ActivateAccountRequest,
      forgotPasswordForm: {
        email: "",
      } as ForgotPasswordRequest,
      resetPasswordForm: {
        email: "",
        newPassword: "",
        confirmPassword: "",
        resetToken: "",
      } as ResetPasswordRequest,
    };

    this.AccountService = new AccountService();
    this.SessionService = new SessionService();

    this.renderSignUpForm = this.renderSignUpForm.bind(this);
    this.renderActivateAccountForm = this.renderActivateAccountForm.bind(this);
    this.renderForgotPasswordForm = this.renderForgotPasswordForm.bind(this);
    this.renderResetPasswordForm = this.renderResetPasswordForm.bind(this);
    this.renderLogInForm = this.renderLogInForm.bind(this);
    this.updateFormData = this.updateFormData.bind(this);

    this.handleSignUp = this.handleSignUp.bind(this);
    this.handleActivateAccount = this.handleActivateAccount.bind(this);
    this.handleLogIn = this.handleLogIn.bind(this);
    this.handleLogInSuccess = this.handleLogInSuccess.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  async componentDidMount() {
    try {
      const parsedQuery = queryString.parse(this.props.router.location.search);
      if (parsedQuery.stripeReturn) {
        let toastType: any =
          parsedQuery.stripeReturn === "1" ? "success" : "error";
        let message =
          parsedQuery.stripeReturn === "1"
            ? "Success"
            : errorsToLabels.failedToOnboardToStripe;
        displayToastMessage(toastType, message);
      }
      if (parsedQuery.email && parsedQuery.view === "activate") {
        const activationToken = parsedQuery.token ? parsedQuery.token : "";

        displayToastMessage(
          "success",
          "Success :D Check your email to activate your account AND MAKE SURE TO RESET YOUR TEMPORARY PASSWORD.",
        );

        return this.setState({
          currentView: "Activate Account",
          activateAccountForm: {
            activationToken,
            email: parsedQuery.email,
          },
        });
      }

      if (
        this.props.router.location.state &&
        this.props.router.location.state.session === false
      ) {
        return;
      }
      const sessionData = await this.SessionService.reviewSession();
      if (sessionData.idVerificationNeeded) {
        displayToastMessage("error", "Please verify your ID.");
        return this.handleIDVerificationStart({
          email: this.state.signUpLogInForm.email,
          userID: sessionData.userID,
          phoneNumber: sessionData.phoneNumber,
        });
      }
      if (sessionData.success) {
        this.props.dispatchSetUser({
          id: sessionData.userID,
          email: sessionData.email,
        });
        history.replace(homeRoutePath, {
          session: true,
        });
      }
    } catch (error: Error | any) {
      logIssueNetworkRequestError("Auth.componentDidMount", error);
    }
  }

  renderAuthView() {
    return (
      <div className="flex h-full w-full flex-col md:flex-row">
        <div className="flex-1 overflow-y-auto">
          {this.renderAuthViewHeader()}
          {this.renderAuthViewContent()}
        </div>
        <div className="hidden h-screen bg-[rgb(65,105,225)] md:block md:flex-1"></div>
      </div>
    );
  }

  renderAuthViewHeader() {
    return (
      <div className="flex w-full items-center justify-center px-24 pb-0 pt-24 md:px-12 lg:px-24">
        <h1 className="text-4xl font-semibold text-[rgb(65,105,225)]">
          {config.labels.brandName}
        </h1>
      </div>
    );
  }

  renderAuthViewContent() {
    return (
      <div className="px-16 py-0 md:px-12 lg:px-24">
        <div className="flex w-full flex-col items-center justify-center py-6">
          <h2 className="items-center justify-center text-4xl font-bold text-gray-800">
            {this.state.currentView}
          </h2>
        </div>
        {this.renderAuthFormContent()}
      </div>
    );
  }

  renderAuthFormContent() {
    switch (this.state.currentView) {
      case "Sign Up":
        return this.renderSignUpForm();
      case "Activate Account":
        return this.renderActivateAccountForm();
      case "Forgot Password":
        return this.renderForgotPasswordForm();
      case "Reset Password":
        return this.renderResetPasswordForm();
      case "Log In":
        return this.renderLogInForm();
    }
  }

  renderSignUpForm() {
    return (
      <div className="w-full">
        <form className="space-y-12">
          <input
            className="w-full border-b border-black bg-transparent py-1 text-[rgb(65,105,225)] focus:border-[rgb(65,105,225)]"
            type="text"
            placeholder="Email address"
            value={this.state.signUpLogInForm.email}
            onChange={(e) => this.updateFormData(e, "signUpLogInForm", "email")}
          />
          <input
            className="w-full border-b border-black bg-transparent py-1 text-[rgb(65,105,225)] focus:border-[rgb(65,105,225)]"
            type="text"
            placeholder="Phone number"
            value={this.state.signUpLogInForm.phoneNumber}
            onChange={(e) =>
              this.updateFormData(e, "signUpLogInForm", "phoneNumber")
            }
          />
          <input
            className="w-full border-b border-black bg-transparent py-1 text-[rgb(65,105,225)] focus:border-[rgb(65,105,225)]"
            type="password"
            autoComplete="on"
            placeholder="Password"
            value={this.state.signUpLogInForm.password}
            onChange={(e) =>
              this.updateFormData(e, "signUpLogInForm", "password")
            }
          />
          <button
            className="w-full rounded bg-blue-500 py-2 text-white hover:bg-blue-600"
            type="button"
            onClick={this.handleSignUp}
          >
            Sign Up
          </button>
          <div className="flex flex-col items-center justify-center space-y-3">
            <button
              className="cursor-pointer text-blue-500 hover:text-blue-700"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ currentView: "Log In" });
              }}
            >
              Log In
            </button>
          </div>
        </form>
      </div>
    );
  }

  renderActivateAccountForm() {
    return (
      <div className="w-full">
        <form className="space-y-12">
          <input
            className="w-full border-b border-black bg-transparent py-1 text-[rgb(65,105,225)] focus:border-[rgb(65,105,225)]"
            type="text"
            placeholder="Email address"
            value={this.state.activateAccountForm.email}
            onChange={(e) =>
              this.updateFormData(e, "activateAccountForm", "email")
            }
          />
          <input
            className="w-full border-b border-black bg-transparent py-1 text-[rgb(65,105,225)] focus:border-[rgb(65,105,225)]"
            type="text"
            placeholder="Activation token"
            value={this.state.activateAccountForm.activationToken}
            onChange={(e) =>
              this.updateFormData(e, "activateAccountForm", "activationToken")
            }
          />
          <button
            className="w-full rounded bg-blue-500 py-2 text-white hover:bg-blue-600"
            type="button"
            onClick={this.handleActivateAccount}
          >
            Activate
          </button>
        </form>
      </div>
    );
  }

  renderForgotPasswordForm() {
    return (
      <div className="w-full">
        <form className="space-y-12">
          <input
            className="w-full border-b border-black bg-transparent py-1 text-[rgb(65,105,225)] focus:border-[rgb(65,105,225)]"
            type="text"
            placeholder="Email address"
            value={this.state.forgotPasswordForm.email}
            onChange={(e) =>
              this.updateFormData(e, "forgotPasswordForm", "email")
            }
          />
          <button
            className="w-full rounded bg-blue-500 py-2 text-white hover:bg-blue-600"
            type="button"
            onClick={this.handleForgotPassword}
          >
            Submit
          </button>
          <div className="flex flex-col items-center justify-center space-y-3">
            <button
              className="cursor-pointer text-blue-500 hover:text-blue-700"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ currentView: "Log In" });
              }}
            >
              Log In
            </button>
          </div>
        </form>
      </div>
    );
  }

  renderResetPasswordForm() {
    return (
      <div className="w-full">
        <form className="space-y-12">
          <input
            className="w-full border-b border-black bg-transparent py-1 text-[rgb(65,105,225)] focus:border-[rgb(65,105,225)]"
            type="text"
            placeholder="Email address"
            value={this.state.resetPasswordForm.email}
            onChange={(e) =>
              this.updateFormData(e, "resetPasswordForm", "email")
            }
          />
          <input
            className="w-full border-b border-black bg-transparent py-1 text-[rgb(65,105,225)] focus:border-[rgb(65,105,225)]"
            type="text"
            placeholder="Reset token"
            value={this.state.resetPasswordForm.resetToken}
            onChange={(e) =>
              this.updateFormData(e, "resetPasswordForm", "resetToken")
            }
          />
          <input
            className="w-full border-b border-black bg-transparent py-1 text-[rgb(65,105,225)] focus:border-[rgb(65,105,225)]"
            type="password"
            autoComplete="on"
            placeholder="New password"
            value={this.state.resetPasswordForm.newPassword}
            onChange={(e) =>
              this.updateFormData(e, "resetPasswordForm", "newPassword")
            }
          />
          <input
            className="w-full border-b border-black bg-transparent py-1 text-[rgb(65,105,225)] focus:border-[rgb(65,105,225)]"
            type="password"
            autoComplete="on"
            placeholder="Confirm password"
            value={this.state.resetPasswordForm.confirmPassword}
            onChange={(e) =>
              this.updateFormData(e, "resetPasswordForm", "confirmPassword")
            }
          />
          <button
            className="w-full rounded bg-blue-500 py-2 text-white hover:bg-blue-600"
            type="button"
            onClick={this.handleResetPassword}
          >
            Submit
          </button>
          <div className="flex flex-col items-center justify-center space-y-3">
            <button
              className="cursor-pointer text-blue-500 hover:text-blue-700"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ currentView: "Log In" });
              }}
            >
              Log In
            </button>
          </div>
        </form>
      </div>
    );
  }

  renderLogInForm() {
    return (
      <div className="flex w-full flex-col items-center justify-center">
        <form className="w-full max-w-md space-y-6">
          <input
            className="w-full border-b-2 border-gray-300 p-2 outline-none focus:border-blue-500"
            type="text"
            placeholder="Email address"
            value={this.state.signUpLogInForm.email}
            onChange={(e) => this.updateFormData(e, "signUpLogInForm", "email")}
          />
          <input
            className="w-full border-b-2 border-gray-300 p-2 outline-none focus:border-blue-500"
            type="password"
            autoComplete="on"
            placeholder="Password"
            value={this.state.signUpLogInForm.password}
            onChange={(e) =>
              this.updateFormData(e, "signUpLogInForm", "password")
            }
          />
          <button
            className="w-full rounded bg-blue-500 py-2 text-white hover:bg-blue-600"
            type="button"
            onClick={this.handleLogIn}
          >
            Log In
          </button>
          <div className="flex flex-col items-center justify-center space-y-3">
            <button
              className="cursor-pointer text-blue-500 hover:text-blue-700"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ currentView: "Forgot Password" });
              }}
            >
              Forgot Password
            </button>
            <button
              className="cursor-pointer text-blue-500 hover:text-blue-700"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ currentView: "Sign Up" });
              }}
            >
              Sign Up
            </button>
          </div>
        </form>
      </div>
    );
  }
  updateFormData(evt: Event | any, formLabel: string, formKeyLabel: string) {
    this.setState(
      {
        [formLabel]: {
          ...this.state[formLabel],
          [formKeyLabel]: evt.target.value,
        },
      },
      () => logUpdatedState(this.state, formLabel),
    );
  }

  async handleSignUp(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleSignUp");

      displayToastMessage("info", "Loading...");

      const resBody: SignUpResponse = await this.AccountService.signUp(
        this.state.signUpLogInForm,
      );
      if (resBody.error) {
        throw new Error(resBody.error);
      }

      Mixpanel.track("Sign Up", {
        email: this.state.signUpLogInForm.email,
        phoneNumber: this.state.signUpLogInForm.phoneNumber,
      });

      displayToastMessage(
        "success",
        "Please check your email for an activation token",
      );

      this.setState({ currentView: "Activate Account" } as AuthState);
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleSignUp", error);
      displayToastMessage("error", postFormErrorLabel("Sign Up"));
    }
  }

  async handleActivateAccount(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleActivateAccount");

      displayToastMessage("info", "Loading...");

      Mixpanel.track("Activate Account", {
        email: this.state.activateAccountForm.email,
      });

      const resBody: SignUpResponse = await this.AccountService.activateAccount(
        this.state.activateAccountForm,
      );
      if (resBody.error) {
        throw new Error(resBody.error);
      }

      displayToastMessage("success", "Please log in again :)");

      this.setState({ currentView: "Log In" } as AuthState);
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleActivateAccount", error);
      displayToastMessage("error", postFormErrorLabel("Account Activation"));
    }
  }

  async handleLogIn(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleLogIn");

      displayToastMessage("info", "Loading...");

      const resBody: LogInResponse = await this.SessionService.logIn(
        this.state.signUpLogInForm,
      );
      if (resBody.error) {
        if (resBody.error === errorsToLabels.userMustActivateAccount) {
          displayToastMessage(
            "success",
            "Please check your email for an activation token",
          );
          return this.setState({
            currentView: "Activate Account",
          } as AuthState);
        }
        throw new Error(resBody.error);
      }

      displayToastMessage("success", "Success...redirecting");

      if (resBody.idVerificationNeeded) {
        displayToastMessage("error", "Please verify your ID");
        return this.handleIDVerificationStart({
          email: this.state.signUpLogInForm.email,
          userID: resBody.userID,
        });
      }

      Mixpanel.identify(resBody.user.id);
      Mixpanel.instance.people.set_once({
        "First Login Date": new Date(),
        userID: resBody.user.id,
      });
      Mixpanel.track("Log In (start)", {
        email: this.state.signUpLogInForm.email,
        phoneNumber: this.state.signUpLogInForm.phoneNumber,
      });

      await this.handleLogInSuccess(resBody.user);
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleLogIn", error);
      displayToastMessage("error", postFormErrorLabel("Log In"));
    }
  }

  async handleIDVerificationStart(reqData: any) {
    try {
      logIssueNetworkRequest("handleIDVerificationStart");

      displayToastMessage("info", "Loading verification form...");

      const resBody: IDVerificationStartResponse =
        await this.AccountService.idVerificationStart({
          email: reqData.email,
          userID: reqData.userID,
        });
      if (resBody.error) {
        throw new Error(resBody.error);
      }

      displayToastMessage("success", "Success");

      const stripe = await loadStripe(config.stripe.publicKey);
      stripe?.verifyIdentity(resBody.cs);
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleIDVerificationStart", error);
      displayToastMessage("error", postFormErrorLabel("ID Verification"));
    }
  }

  async handleLogInSuccess(user: IUser | any) {
    try {
      // Update Redux State with User Data
      this.props.dispatchSetUser(user);

      Mixpanel.track("Log In (Complete)", {
        userID: user.id,
        email: user.email,
      });

      // Segue to Home
      history.replace(homeRoutePath, { session: true });
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleLogInSuccess", error);
      displayToastMessage("error", postFormErrorLabel("Post Log In"));
    }
  }

  async handleForgotPassword(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleForgotPassword");

      displayToastMessage("info", "Loading...");

      const resBody: ForgotPasswordResponse =
        await this.AccountService.forgotPassword(this.state.forgotPasswordForm);
      if (resBody.error) {
        throw new Error(resBody.error);
      }

      displayToastMessage(
        "success",
        "Please check your email for your reset token",
      );

      this.setState({ currentView: "Reset Password" } as AuthState);
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleForgotPassword", error);
      displayToastMessage("error", postFormErrorLabel("Forgot Password"));
    }
  }

  async handleResetPassword(evt: Event | any) {
    try {
      evt.preventDefault();

      logIssueNetworkRequest("handleResetPassword");

      displayToastMessage("info", "Loading...");

      const resBody: ResetPasswordResponse =
        await this.AccountService.resetPassword(this.state.resetPasswordForm);
      if (resBody.error) {
        throw new Error(resBody.error);
      }

      displayToastMessage("success", "Please log in again :)");

      this.setState({ currentView: "Log In" } as AuthState);
    } catch (error: Error | any) {
      logIssueNetworkRequestError("handleResetPassword", error);
      displayToastMessage("error", postFormErrorLabel("Reset Password"));
    }
  }

  render() {
    return this.renderAuthView();
  }
}

const mapStateToProps = (state: Object | any) => ({
  router: state.router,
  user: state.user,
});

const mapDispatchToProps = (dispatch: Function | any) => ({
  dispatchSetUser: (user: IUser | any) => dispatch(setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth as any);
