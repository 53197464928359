import queryString from "query-string";
import { ethers } from "ethers";
import React from "react";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import {
  IProduct,
  SendBuyerAReceiptResponse,
  SendDisputeExplainationResponse,
  ReportProductResponse,
} from "../../interfaces/product";
import config from "../../config";
import {
  logIssueNetworkRequest,
  logIssueNetworkRequestError,
  logUpdatedState,
} from "../../utils/loggers";
import { displayToastMessage } from "../../utils/toasts";
import FeesContract from "../../contracts/Fees.sol/Fees.json";
import RatingsReviewsContract from "../../contracts/RatingsReviewsV1.sol/RatingsReviewsV1.json";
import ProductsContract from "../../contracts/ProductsV1.sol/ProductsV1.json";
import PurchaseEscrowContract from "../../contracts/PurchaseEscrowV1.sol/PurchaseEscrowV1.json";
import PurchaseEscrowDAIContract from "../../contracts/PurchaseEscrowDAIV1.sol/PurchaseEscrowDAIV1.json";
import PurchaseEscrowGHOContract from "../../contracts/PurchaseEscrowGHOV1.sol/PurchaseEscrowGHOV1.json";
import PurchaseEscrowUSDCContract from "../../contracts/PurchaseEscrowUSDCV1.sol/PurchaseEscrowUSDCV1.json";
import ERC20 from "../../contracts/ERC20.sol/ERC20.json";
import CommonHeader from "../../components/CommonHeader";
import {
  convertProductResultToObject,
  convertPurchaseResultToObject,
  convertIPFSLinkToHTTPSLink,
  convertTokenToWei,
  convertWeiToToken,
  convertPaymentTypeToLabel,
} from "../../utils/convert";
import { IPurchase } from "../../interfaces/payment";
import ProductService from "../../services/Product";
import CommonModal from "../../components/CommonModal";
import { history } from "../../redux";
import { homeRoutePath, sellerListingsPath } from "../Router";
import { getCartItems, saveCartItems } from "../../utils/cart";
import { contactSupportErrorLabel } from "../../utils/errors";
import { Web3NetworkConfig, getNetworkConfig } from "../../utils/network";
import { Mixpanel } from "../../utils/analytics";
import { getWalletETHBalance } from "../../utils/wallet";
import "react-responsive-carousel/lib/styles/carousel.min.css";

class ProductDetails extends React.Component<any, any> {
  ProductService: ProductService;

  constructor(props: any) {
    super(props);

    const parsedQuery = queryString.parse(props.router.location.search);
    let productID = null;
    if (parsedQuery.id && !isNaN(Number(parsedQuery.id))) {
      productID = parsedQuery.id;
    }

    this.state = {
      productID,
      product: null,
      purchase: null,
      userIsValidator: false,
      approveReleaseOptions: ["Yes", "No"],
      approveRelease: true,
      selectedSellerRatingScore: 5,
      selectedQuantity: 1,
      buyerWantsAReceipt: false,
      buyerEmailForReceipt: "",
      disputeExplaination: "",
      reportReason: "",
      editedProduct: {
        quantity: 1,
        price: 1,
      },
      //   product: {
      //     isActive: true,
      //     productId: 0,
      //     sellerAddress: "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266",
      //     category: "Product Category",
      //     title: "Product Title",
      //     description: "Product Description",
      //     imageHashes: ["jk shit.jpeg", "ian-keefe-OgcJIKRnRC8-unsplash.jpg"],
      //     price: 0,
      //     inEscrow: false,
      //   } as IProduct,
      //   purchase: {
      //     exists: true,
      //     purchaseId: 1,
      //     productId: 3,
      //     buyer: "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266",
      //     seller: "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266",
      //     validator: "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266",
      //     amount: 100,
      //     isDisputed: false,
      //     isFinalized: false,
      //   } as IPurchase,
    };

    this.ProductService = new ProductService();

    this.handleLoadProductDetails = this.handleLoadProductDetails.bind(this);
    this.renderProductImages = this.renderProductImages.bind(this);
    this.renderQuantitySection = this.renderQuantitySection.bind(this);
    this.renderProductInformation = this.renderProductInformation.bind(this);
    this.handleInitiatePurchase = this.handleInitiatePurchase.bind(this);
    this.handleInitiateDispute = this.handleInitiateDispute.bind(this);
    this.handleResolveDispute = this.handleResolveDispute.bind(this);
    this.handleSubmitRating = this.handleSubmitRating.bind(this);
    this.handleReportProduct = this.handleReportProduct.bind(this);
    this.renderEditDeleteProductModal =
      this.renderEditDeleteProductModal.bind(this);
    this.handleUpdateProduct = this.handleUpdateProduct.bind(this);
    this.handleDeleteProduct = this.handleDeleteProduct.bind(this);
    this.renderBuyActionButtons = this.renderBuyActionButtons.bind(this);
    this.renderQuantityDropdownInput =
      this.renderQuantityDropdownInput.bind(this);
    this.addProductToCart = this.addProductToCart.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.handleLoadProductDetails();
      Mixpanel.track("ProductDetails", {
        user: this.props.user,
      });
    }, 1000);
  }

  async handleLoadProductDetails() {
    try {
      logIssueNetworkRequest("ProductDetails.handleLoadProductDetails()");
      displayToastMessage("info", "Loading product details...");
      if (this.state.productID !== null) {
        const networkConfig: Web3NetworkConfig = getNetworkConfig(
          this.props.user.chainId,
          this.props.user.provider,
        );
        console.log("networkConfig:", networkConfig);
        const provider = networkConfig.provider;
        const signer = await provider.getSigner();

        // const provider = config.web3.provider;
        // const signer = await provider.getSigner();
        // const contract = new ethers.Contract(
        //   config.contracts.Products.address,
        //   ProductsContract.abi,
        //   signer,
        // );
        const contract = new ethers.Contract(
          networkConfig.addresses.Products,
          ProductsContract.abi,
          signer,
        );
        let result = await contract.getProduct(this.state.productID);
        console.log("result:", result);
        const product = convertProductResultToObject(result);
        console.log("product:", product);

        // const purchaseEscrowContract = new ethers.Contract(
        //   config.contracts.PurchaseEscrow.address,
        //   PurchaseEscrowContract.abi,
        //   signer,
        // );
        const purchaseEscrowContract = new ethers.Contract(
          networkConfig.addresses.PurchaseEscrow,
          PurchaseEscrowContract.abi,
          signer,
        );

        result = await purchaseEscrowContract.productIDToPurchase(
          this.state.productID,
        );
        console.log("result:", result);
        const purchase = convertPurchaseResultToObject(result);
        console.log("purchase:", purchase, this.props.user);

        this.setState(
          {
            product,
            purchase,
            userIsValidator:
              purchase.validator === this.props.user.walletAddress,
          },
          () => {
            logUpdatedState(this.state, "product", true);
            logUpdatedState(this.state, "purchase", true);
          },
        );
      }
    } catch (error: Error | any) {
      logIssueNetworkRequestError(
        "ProductDetails.handleLoadProductDetails():",
        error,
      );
      displayToastMessage(
        "error",
        `Failed to load product. ${contactSupportErrorLabel}.`,
      );
    }
  }

  async handleInitiatePurchase() {
    try {
      const product: IProduct = this.state.product;
      const ethereum = (window as any).ethereum;

      logIssueNetworkRequest("ProductDetails.handleInitiatePurchase()");

      displayToastMessage("info", "Loading...");

      if (this.state.selectedQuantity < 1) {
        throw new Error("Quantity must be greater than zero!");
      }

      if (ethereum && product !== null) {
        const networkConfig: Web3NetworkConfig = getNetworkConfig(
          this.props.user.chainId,
          this.props.user.provider,
        );
        console.log("networkConfig:", networkConfig);
        const provider = networkConfig.provider;
        const signer = await provider.getSigner();
        const feesContract = new ethers.Contract(
          networkConfig.addresses.Fees,
          FeesContract.abi,
          signer,
        );
        const serviceFee = await feesContract.getServiceFee();

        let purchaseEscrowContract;
        let tokenContract: any;

        switch (product.paymentType) {
          case 0:
            purchaseEscrowContract = new ethers.Contract(
              networkConfig.addresses.PurchaseEscrow,
              PurchaseEscrowContract.abi,
              signer,
            );
            break;
          case 1:
            purchaseEscrowContract = new ethers.Contract(
              networkConfig.addresses.PurchaseEscrowUSDC,
              PurchaseEscrowUSDCContract.abi,
              signer,
            );
            tokenContract = new ethers.Contract(
              networkConfig.addresses.USDC,
              ERC20.abi,
              signer,
            );
            break;
          case 2:
            purchaseEscrowContract = new ethers.Contract(
              networkConfig.addresses.PurchaseEscrowDAI,
              PurchaseEscrowDAIContract.abi,
              signer,
            );
            tokenContract = new ethers.Contract(
              networkConfig.addresses.DAI,
              ERC20.abi,
              signer,
            );
            break;
          case 3:
            purchaseEscrowContract = new ethers.Contract(
              networkConfig.addresses.PurchaseEscrowGHO,
              PurchaseEscrowGHOContract.abi,
              signer,
            );
            tokenContract = new ethers.Contract(
              networkConfig.addresses.GHO,
              ERC20.abi,
              signer,
            );
            break;
          default:
            throw new Error("Unsupported payment type");
        }

        // const totalAmount =
        //   BigInt(product.price) +
        //   (BigInt(product.price) * BigInt(serviceFee)) / BigInt(100);
        // const totalAmountInETH =
        //   Number(this.state.selectedQuantity) *
        //   Number(convertWeiToETH(totalAmount));
        // const currentWalletBalance = await getWalletETHBalance(
        //   provider,
        //   this.props.user.walletAddress,
        // );

        // // console.log("totalAmount (wei, eth):", totalAmount, totalAmountInETH);
        // // console.log("currentWalletBalance:", currentWalletBalance);

        // if (Number(currentWalletBalance) < totalAmountInETH) {
        //   return displayToastMessage(
        //     "error",
        //     "ETH Balance is less than total amount required (including fees): " +
        //       totalAmountInETH,
        //   );
        // }

        const totalAmount =
          BigInt(product.price) +
          (BigInt(product.price) * BigInt(serviceFee)) / BigInt(100);
        const totalAmountInToken = convertTokenToWei(
          Number(this.state.selectedQuantity) *
            Number(convertWeiToToken(totalAmount, product.paymentType)),
          product.paymentType,
        );
        console.log(
          "totalAmount,totalAmountInToken:",
          totalAmount,
          totalAmountInToken,
        );

        if (product.paymentType === 0) {
          const currentWalletBalance = await getWalletETHBalance(
            provider,
            this.props.user.walletAddress,
          );
          console.log("currentWalletBalance:", currentWalletBalance);
          if (BigInt(currentWalletBalance) < BigInt(totalAmount)) {
            return displayToastMessage(
              "error",
              `ETH Balance is less than total amount required (including fees): ${convertWeiToToken(totalAmountInToken, 0)} ETH`,
            );
          }
        } else {
          const tokenBalance = await tokenContract.balanceOf(
            this.props.user.walletAddress,
          );
          console.log("tokenBalance:", tokenBalance);
          if (BigInt(tokenBalance) < BigInt(totalAmountInToken)) {
            return displayToastMessage(
              "error",
              `${product.paymentType} balance is less than total amount required (including fees): ${convertWeiToToken(totalAmountInToken, product.paymentType)} ${product.paymentType}`,
            );
          }

          // Approve the purchase escrow contract to spend tokens
          const tx = await tokenContract.approve(
            purchaseEscrowContract.target,
            totalAmount,
          );

          displayToastMessage("info", "Approving transfer allowance amount...");

          await tx.wait();

          displayToastMessage("success", "Approved");
        }

        // NOTE: Core validator for now is the company itself (will hire and assign validators)
        const validatorAddress = networkConfig.addresses.CoreValidatorAddress;
        const tx = await purchaseEscrowContract.initiatePurchase(
          {
            productId: product.productId,
            quantity: this.state.selectedQuantity,
            buyer: this.props.user.walletAddress,
            seller: product.sellerAddress,
            validator: validatorAddress,
          },
          product.paymentType === 0 ? { value: totalAmountInToken } : {},
        );

        displayToastMessage("info", "Waiting on transaction...");

        await tx.wait();

        displayToastMessage("success", "Purchase initiated successfully!");
        displayToastMessage(
          "info",
          `IMPORTANT: Store the following product ID for future reference: ${product.productId}`,
        );

        Mixpanel.track("New Transaction - Success", {
          totalAmountInToken,
          paymentType: product.paymentType,
        });

        this.setState(
          {
            product: {
              ...this.state.product,
              inEscrow: true,
            },
          },
          async () => {
            if (this.state.buyerWantsAReceipt) {
              try {
                const receiptData = {
                  email: this.state.buyerEmailForReceipt,
                  walletAddress: this.props.user.walletAddress,
                  purchaseData: {
                    validatorAddress,
                    chainId: String(this.props.user.chainId),
                    productId: product.productId,
                    purchaseAmountInWei: totalAmount.toString(),
                    buyerAddress: this.props.user.walletAddress,
                    sellerAddress: product.sellerAddress,
                  },
                };
                const res: SendBuyerAReceiptResponse =
                  await this.ProductService.sendBuyerAReceipt(receiptData);
                if (res.error) {
                  throw new Error(res.error);
                }

                displayToastMessage(
                  "success",
                  "Purchase receipt was sent successfully!",
                );

                Mixpanel.track("Send Buyer Receipt - Success", { receiptData });
              } catch (error: Error | any) {
                logIssueNetworkRequestError(
                  "ProductDetails.handleInitiatePurchase() sendBuyerAReceipt()",
                  error,
                );
                displayToastMessage(
                  "error",
                  `Failed to send receipt. Please store the following product ID for future reference: ${product.productId}`,
                );
                Mixpanel.track("Send Buyer Receipt - Failure", { error });
              }
            }
          },
        );
      } else {
        console.error("Ethereum object doesn't exist!");
        displayToastMessage(
          "error",
          "Please install a wallet such as MetaMask!",
        );
      }
    } catch (error: Error | any) {
      logIssueNetworkRequestError(
        "ProductDetails.handleInitiatePurchase()",
        error,
      );
      // TODO: Use commonErrorMessages to have support email in each error message
      // to make it easier for users to find support contact information.
      displayToastMessage(
        "error",
        `Failed to initiate purchase. ${contactSupportErrorLabel}`,
      );
      Mixpanel.track("New Transaction - Failure", { error });
    }
  }

  async handleInitiateDispute() {
    try {
      const purchase: IPurchase = this.state.purchase;
      const ethereum = (window as any).ethereum;

      console.log("handleInitiateDispute():", purchase, ethereum);

      displayToastMessage("info", "Loading...");

      if (ethereum && purchase !== null) {
        // const provider = config.web3.provider;
        // const signer = await provider.getSigner();
        // const purchaseEscrowContract = new ethers.Contract(
        //   config.contracts.PurchaseEscrow.address,
        //   PurchaseEscrowContract.abi,
        //   signer,
        // );
        const networkConfig: Web3NetworkConfig = getNetworkConfig(
          this.props.user.chainId,
          this.props.user.provider,
        );
        console.log("networkConfig:", networkConfig);
        const provider = networkConfig.provider;
        const signer = await provider.getSigner();
        const purchaseEscrowContract = new ethers.Contract(
          networkConfig.addresses.PurchaseEscrow,
          PurchaseEscrowContract.abi,
          signer,
        );

        await purchaseEscrowContract.initiateDispute(purchase.purchaseId);

        displayToastMessage("success", "Dispute initiated successfully!");

        Mixpanel.track("Disputed Purchase - Success", {
          disputedPurchaseID: purchase.purchaseId,
        });

        this.setState({
          purchase: {
            ...this.state.purchase,
            isDisputed: true,
          },
        });

        try {
          const disputeReasonData = {
            disputeData: {
              disputerAddress: this.props.user.walletAddress,
              purchaseId: this.state.purchase.purchaseId,
              productId: this.state.purchase.productId,
              buyer: this.state.purchase.buyer,
              seller: this.state.purchase.seller,
              validator: this.state.purchase.validator,
              explaination: this.state.disputeExplaination,
            },
          };
          const res: SendDisputeExplainationResponse =
            await this.ProductService.sendDisputeExplaination(
              disputeReasonData,
            );
          if (res.error) {
            throw new Error(res.error);
          }

          displayToastMessage(
            "success",
            "Dispute explaination was sent successfully!",
          );

          Mixpanel.track("Sent Dispute Explaination - Success", {
            disputeReasonData,
          });
        } catch (error: Error | any) {
          logIssueNetworkRequestError(
            "ProductDetails.handleInitiateDispute() sendDisputeExplaination()",
            error,
          );
          displayToastMessage("error", "Failed to send dispute explaination.");
          displayToastMessage(
            "error",
            `Send 'productID:${purchase.productId} and purchaseID:${purchase.purchaseId} to ${config.emails.supportEmail}`,
          );
          Mixpanel.track("Sent Dispute Explaination - Failure", {
            error,
            productID: purchase.productId,
            purchaseID: purchase.purchaseId,
          });
        }
      } else {
        console.error("Ethereum object doesn't exist!");
        displayToastMessage(
          "error",
          "Please install a wallet such as MetaMask!",
        );
        Mixpanel.track("Disputed Purchase - Failure", {
          error: "no ethereum object || purchase === null",
        });
      }
    } catch (error: Error | any) {
      logIssueNetworkRequestError(
        "ProductDetails.handleInitiateDispute()",
        error,
      );
      // TODO: Use commonErrorMessages to have support email in each error message
      // to make it easier for users to find support contact information.
      displayToastMessage(
        "error",
        `Failed to initiate dispute. ${contactSupportErrorLabel}`,
      );
      Mixpanel.track("Disputed Purchase - Failure", {
        error,
      });
    }
  }

  async handleResolveDispute() {
    try {
      const purchase: IPurchase = this.state.purchase;
      const ethereum = (window as any).ethereum;

      console.log("handleResolveDispute():", purchase, ethereum);

      displayToastMessage("info", "Loading...");

      if (ethereum && purchase !== null) {
        // const provider = config.web3.provider;
        // const signer = await provider.getSigner();
        // const purchaseEscrowContract = new ethers.Contract(
        //   config.contracts.PurchaseEscrow.address,
        //   PurchaseEscrowContract.abi,
        //   signer,
        // );
        const networkConfig: Web3NetworkConfig = getNetworkConfig(
          this.props.user.chainId,
          this.props.user.provider,
        );
        console.log("networkConfig:", networkConfig);
        const provider = networkConfig.provider;
        const signer = await provider.getSigner();
        const purchaseEscrowContract = new ethers.Contract(
          networkConfig.addresses.PurchaseEscrow,
          PurchaseEscrowContract.abi,
          signer,
        );

        await purchaseEscrowContract.resolveDispute(
          purchase.purchaseId,
          this.state.approveRelease,
        );

        displayToastMessage("success", "Dispute resolved successfully!");

        this.setState({
          purchase: {
            ...this.state.purchase,
            isDisputed: false,
            isFinalized: true,
          },
        });
      } else {
        console.error("Ethereum object doesn't exist!");
        displayToastMessage(
          "error",
          "Please install a wallet such as MetaMask!",
        );
      }
    } catch (error: Error | any) {
      logIssueNetworkRequestError(
        "ProductDetails.handleResolveDispute()",
        error,
      );
      // TODO: Use commonErrorMessages to have support email in each error message
      // to make it easier for users to find support contact information.
      displayToastMessage(
        "error",
        `Failed to resolve dispute. ${contactSupportErrorLabel}`,
      );
    }
  }

  async handleSubmitRating(e: Event | any) {
    e.preventDefault();

    logIssueNetworkRequest("ProductDetails.handleSubmitRating()");

    displayToastMessage("info", "Loading...");

    try {
      // const provider = config.web3.provider;
      // const signer = await provider.getSigner();
      // const ratingsReviewsContract = new ethers.Contract(
      //   config.contracts.RatingsReviews.address,
      //   RatingsReviewsContract.abi,
      //   signer,
      // );

      const networkConfig: Web3NetworkConfig = getNetworkConfig(
        this.props.user.chainId,
        this.props.user.provider,
      );
      console.log("networkConfig:", networkConfig);
      const provider = networkConfig.provider;
      const signer = await provider.getSigner();
      const ratingsReviewsContract = new ethers.Contract(
        networkConfig.addresses.RatingsReviews,
        RatingsReviewsContract.abi,
        signer,
      );

      await ratingsReviewsContract.submitRatingAndReview(
        this.state.product.sellerAddress,
        this.state.selectedSellerRatingScore,
      );

      displayToastMessage("success", "Rating submitted successfully!");

      Mixpanel.track("Review Submitted", {
        sellerAddress: this.state.product.sellerAddress,
        selectedSellerRatingScore: this.state.selectedSellerRatingScore,
      });
    } catch (error: Error | any) {
      logIssueNetworkRequestError(
        "ProductDetails.handleSubmitRating():",
        error,
      );
      displayToastMessage(
        "error",
        `Failed to submit rating. ${contactSupportErrorLabel}`,
      );
    }
  }

  async handleReportProduct() {
    logIssueNetworkRequest("ProductDetails.handleReportProduct()");

    displayToastMessage("info", "Loading...");

    try {
      const res: ReportProductResponse =
        await this.ProductService.reportProduct({
          productId: this.state.product.productId,
          reportReason: this.state.reportReason,
        });
      if (res.error) {
        throw new Error(res.error);
      }

      displayToastMessage("success", "Product has been reported successfully");

      Mixpanel.track("Product Reported", {
        productId: this.state.product.productId,
        reportReason: this.state.reportReason,
      });
    } catch (error: Error | any) {
      logIssueNetworkRequestError(
        "ProductDetails.handleReportProduct():",
        error,
      );
      displayToastMessage(
        "error",
        `Failed to report product. ${contactSupportErrorLabel}`,
      );
    }
  }

  renderProductImages() {
    const showArrows = this.state.product.imageHashes.length > 1;
    return (
      <div className="p-4 md:w-1/2">
        <Carousel showArrows={showArrows} className="rounded-lg shadow-lg">
          {this.state.product.imageHashes.map(
            (imageHash: string, i: number) => (
              <div key={i} className="h-full">
                <img
                  alt={`product-${i}`}
                  className="md:max-h-128 mx-auto max-h-96 w-full rounded-lg object-cover lg:max-h-full"
                  src={convertIPFSLinkToHTTPSLink(imageHash)}
                />
              </div>
            ),
          )}
        </Carousel>
      </div>
    );
  }

  renderReportProductModal() {
    // NOTE: Seller CAN NOT report their own product...
    if (
      this.state.product &&
      this.state.product.sellerAddress === this.props.user.walletAddress
    ) {
      return;
    }
    return (
      <CommonModal
        toggleButtonText={"Report"}
        modalTitle={"Report Product"}
        modalDescription={
          "Please explain why you think this product should be reported."
        }
        onSubmit={(reportReason: string) => {
          this.setState({ reportReason }, () => {
            this.handleReportProduct();
          });
        }}
      />
    );
  }

  async handleUpdateProduct() {
    try {
      logIssueNetworkRequest("ProductDetails.handleUpdateProduct()");

      displayToastMessage("info", "Loading...");

      // const provider = config.web3.provider;
      // const signer = await provider.getSigner();
      // const contract = new ethers.Contract(
      //   config.contracts.Products.address,
      //   ProductsContract.abi,
      //   signer,
      // );
      const networkConfig: Web3NetworkConfig = getNetworkConfig(
        this.props.user.chainId,
        this.props.user.provider,
      );
      console.log("networkConfig:", networkConfig);
      const provider = networkConfig.provider;
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(
        networkConfig.addresses.Products,
        ProductsContract.abi,
        signer,
      );

      let { quantity, price } = this.state.editedProduct;
      if (quantity === this.state.product.quantity) {
        quantity = this.state.product.quantity;
      }
      if (price === this.state.product.price) {
        price = this.state.product.price;
      }

      await contract.updateProduct(
        this.state.product.productId,
        quantity,
        ethers.parseUnits(price.toString(), "ether"),
        this.state.product.inEscrow,
      );

      displayToastMessage("success", "Product updated successfully!");

      window.location.reload();
    } catch (error: Error | any) {
      logIssueNetworkRequestError(
        "ProductDetails.handleUpdateProduct():",
        error,
      );
      displayToastMessage(
        "error",
        `Failed to edit product. ${contactSupportErrorLabel}`,
      );
    }
  }

  async handleDeleteProduct() {
    try {
      logIssueNetworkRequest("ProductDetails.handleDeleteProduct()");

      displayToastMessage("info", "Loading...");

      // const provider = config.web3.provider;
      // const signer = await provider.getSigner();
      // const contract = new ethers.Contract(
      //   config.contracts.Products.address,
      //   ProductsContract.abi,
      //   signer,
      // );
      const networkConfig: Web3NetworkConfig = getNetworkConfig(
        this.props.user.chainId,
        this.props.user.provider,
      );
      console.log("networkConfig:", networkConfig);
      const provider = networkConfig.provider;
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(
        networkConfig.addresses.Products,
        ProductsContract.abi,
        signer,
      );

      await contract.deleteProduct(this.state.product.productId);

      displayToastMessage("success", "Product deleted successfully");

      history.push(homeRoutePath);
    } catch (error: Error | any) {
      logIssueNetworkRequestError(
        "ProductDetails.handleDeleteProduct():",
        error,
      );
      displayToastMessage(
        "error",
        `Failed to delete product. ${contactSupportErrorLabel}`,
      );
    }
  }

  renderEditDeleteProductModal() {
    // NOTE: ONLY SELLER can edit and/or delete their product
    if (
      this.state.product &&
      this.state.product.sellerAddress !== this.props.user.walletAddress
    ) {
      return;
    }
    return (
      <div className="inline-edit-delete-actions-container flex">
        <CommonModal
          toggleButtonText={"Edit"}
          toggleButtonClasses={"bg-blue-500"}
          modalTitle={"Edit Product"}
          modalDescription={"Edit product information below:"}
          childElement={
            <form className="mt-6 space-y-4">
              <div className="flex flex-col space-y-1">
                <label className="block text-sm font-medium text-gray-700">
                  Quantity:
                </label>
                <input
                  type="number"
                  name="quantity"
                  value={this.state.editedProduct.quantity}
                  onChange={(e: any) =>
                    this.setState({
                      editedProduct: {
                        ...this.state.editedProduct,
                        quantity: e.target.value,
                      },
                    })
                  }
                  placeholder="Quantity"
                  className="w-full rounded-lg border-gray-300 p-2 shadow-sm focus:border-[rgb(65,105,225)] focus:ring focus:ring-[rgb(65,105,225)] focus:ring-opacity-50"
                />
              </div>
              <div className="flex flex-col space-y-1">
                <label className="block text-sm font-medium text-gray-700">
                  Price (
                  {convertPaymentTypeToLabel(
                    this.state.editedProduct.paymentType,
                  )}
                  ):
                </label>
                <input
                  type="text"
                  name="price"
                  value={this.state.editedProduct.price}
                  onChange={(e: any) =>
                    this.setState({
                      editedProduct: {
                        ...this.state.editedProduct,
                        price: e.target.value,
                      },
                    })
                  }
                  placeholder="Price"
                  className="w-full rounded-lg border-gray-300 p-2 shadow-sm focus:border-[rgb(65,105,225)] focus:ring focus:ring-[rgb(65,105,225)] focus:ring-opacity-50"
                />
              </div>
            </form>
          }
          onSubmit={(_: any) => {
            this.handleUpdateProduct();
          }}
        />
        <CommonModal
          toggleButtonText={"Delete"}
          toggleButtonClasses={"bg-red-600 mx-2"}
          modalTitle={"Delete Product"}
          modalDescription={"Type 'DELETE PRODUCT' to delete this product."}
          onSubmit={(text: string) => {
            if (text === "DELETE PRODUCT") {
              this.handleDeleteProduct();
            } else {
              displayToastMessage(
                "error",
                "Failed to type 'DELETE PRODUCT' successfully",
              );
            }
          }}
        />
      </div>
    );
  }

  renderQuantityDropdownInput() {
    if (
      this.state.product &&
      this.state.product.sellerAddress === this.props.user.walletAddress
    ) {
      return;
    }
    return (
      <input
        type="number"
        name="quantity"
        value={this.state.selectedQuantity}
        onChange={(e: Event | any) =>
          this.setState({ selectedQuantity: e.target.value })
        }
        placeholder="Quantity"
        className="w-full rounded-lg border-gray-300 p-2 shadow-sm focus:border-[rgb(65,105,225)] focus:ring focus:ring-[rgb(65,105,225)] focus:ring-opacity-50"
      />
    );
  }

  renderPurchaseActionButtons() {
    return (
      <div className="space-y-2 py-4">
        {this.renderDisputeActions()}
        {this.renderBuyActionButtons()}
        {this.renderResolveButton()}
      </div>
    );
  }

  renderDisputeActions() {
    if (
      this.state.product.inEscrow &&
      this.state.purchase.buyer === this.props.user.walletAddress
    ) {
      if (this.state.purchase.isDisputed) {
        return (
          <div className="dispute-actions-container">
            <button
              disabled
              className="cursor-not-allowed rounded-lg bg-red-500 px-6 py-3 text-white opacity-50"
            >
              Dispute In Progress...
            </button>
          </div>
        );
      } else {
        return (
          <CommonModal
            toggleButtonText={"Dispute Purchase"}
            toggleButtonClasses="bg-red-500 text-white px-6 py-3 rounded-lg transition duration-300 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            modalTitle={"Dispute Purchase"}
            modalDescription={
              "Please explain why you are disputing this purchase."
            }
            onSubmit={(disputeExplaination: string) => {
              this.setState({ disputeExplaination }, () => {
                this.handleInitiateDispute();
              });
            }}
          />
        );
      }
    }
  }

  addProductToCart() {
    // NOTE: JavaScript what the fuck is below...are you fucking serious...
    if (
      Number(this.state.product.quantity) < Number(this.state.selectedQuantity)
    ) {
      return displayToastMessage(
        "error",
        "Update 'Quantity' to be lower than how much is left in stock",
      );
    }
    const cartItems = getCartItems();
    const item = cartItems.find(
      (item: IProduct) => item.productId === this.state.product.productId,
    );
    console.log("item:", item);
    if (item) {
      return displayToastMessage("error", "Product is already in your cart");
    }

    cartItems.push({
      ...this.state.product,
      quantity: this.state.selectedQuantity,
    });
    saveCartItems(cartItems);

    console.log("cartItems:", getCartItems());

    displayToastMessage("success", "Product successfully added to your cart");

    Mixpanel.track("Added Item To Cart", {
      product: this.state.product,
      quantity: this.state.selectedQuantity,
    });
  }

  renderBuyActionButtons() {
    if (this.state.product.sellerAddress === this.props.user.walletAddress) {
      return;
    }
    if (!this.state.product.inEscrow) {
      return (
        <div className="buy-now-actions-container">
          <div className="flex space-x-4">
            <button
              className="rounded-lg bg-blue-500 px-6 py-3 text-white transition duration-300 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              onClick={this.addProductToCart}
            >
              Add To Cart
            </button>
            <button
              className="rounded-lg bg-green-500 px-6 py-3 text-white transition duration-300 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              onClick={this.handleInitiatePurchase}
            >
              Buy Now
            </button>
          </div>
          <label className="mt-4 flex items-center">
            <input
              type="checkbox"
              name="send-me-receipt"
              checked={this.state.buyerWantsAReceipt}
              onChange={(e: any) =>
                this.setState({
                  buyerWantsAReceipt: !this.state.buyerWantsAReceipt,
                })
              }
              className="form-checkbox h-5 w-5 text-blue-500 transition duration-150 ease-in-out"
            />
            <span className="ml-2 text-gray-700">Need a receipt?</span>
          </label>
          {this.renderBuyerReceiptEmailInput()}
        </div>
      );
    }
  }

  renderBuyerReceiptEmailInput() {
    if (!this.state.product.inEscrow && this.state.buyerWantsAReceipt) {
      return (
        <input
          type="text"
          name="buyerEmailForReceipt"
          className="w-1/2 rounded-lg border-gray-300 p-2 shadow-sm focus:border-[rgb(65,105,225)] focus:ring focus:ring-[rgb(65,105,225)] focus:ring-opacity-50"
          value={this.state.buyerEmailForReceipt}
          onChange={(e: any) =>
            this.setState({ buyerEmailForReceipt: e.target.value })
          }
          placeholder="example@layermarket.xyz"
        />
      );
    }
  }

  renderResolveButton() {
    if (
      this.state.product.inEscrow &&
      this.state.userIsValidator &&
      this.state.purchase.isDisputed &&
      !this.state.purchase.isFinalized
    ) {
      return (
        <button
          className="rounded-lg bg-green-500 px-6 py-3 text-white transition duration-300 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          onClick={this.handleResolveDispute}
        >
          Resolve Dispute
        </button>
      );
    }
  }

  renderSellerRatingSection() {
    if (
      this.state.purchase &&
      this.state.purchase.isFinalized &&
      this.state.purchase.buyer === this.props.user.walletAddress
    ) {
      return (
        <div className="flex items-center space-x-4">
          <select
            id="rating-select"
            value={this.state.selectedSellerRatingScore}
            onChange={(e) =>
              this.setState({ selectedSellerRatingScore: e.target.value })
            }
            className="rounded-lg bg-blue-500 px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            {config.products.ratingScores.map((score) => (
              <option key={score} value={score}>
                {score}
              </option>
            ))}
          </select>
          <button
            className="rounded-lg bg-green-500 px-6 py-3 text-white transition duration-300 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            onClick={this.handleSubmitRating}
          >
            Submit Rating
          </button>
        </div>
      );
    }
  }

  renderQuantitySection() {
    const product = this.state.product;
    if (
      this.state.purchase &&
      this.state.purchase.buyer !== this.props.user.walletAddress
    ) {
      return (
        <div className="in-line-quantity-input my-2">
          <label>
            <strong>Quantity ({product.quantity} left)</strong>{" "}
          </label>
          {this.renderQuantityDropdownInput()}
        </div>
      );
    }
  }

  renderProductInformation() {
    const product = this.state.product;
    const priceString = convertWeiToToken(
      product.price,
      product.paymentType,
    ).toString();
    return (
      <div className="mx-4 my-8 rounded-lg bg-gradient-to-br from-white to-blue-100 p-8 shadow-lg md:w-1/2">
        {this.renderReportProductModal()}
        {this.renderEditDeleteProductModal()}
        <h1 className="mb-6 text-4xl font-bold text-blue-900">
          {product.title}
        </h1>
        <p className="mb-6 text-lg text-gray-700">{product.description}</p>
        <p className="mb-6 text-2xl font-bold text-blue-800">
          Price: {priceString} {convertPaymentTypeToLabel(product.paymentType)}
        </p>
        <p className="mb-6 break-words text-lg text-gray-700 sm:text-xl">
          {" "}
          <strong>Seller:</strong>{" "}
          <a
            href={`${sellerListingsPath}?address=${product.sellerAddress}`}
            className="text-blue-600 transition duration-300 hover:text-blue-800"
          >
            {product.sellerAddress}
          </a>
        </p>
        {this.renderQuantitySection()}
        <p className="mb-6 text-lg text-red-500">
          In Escrow: {`${product.inEscrow}`}
        </p>
        {this.renderPurchaseActionButtons()}
        {this.renderSellerRatingSection()}
      </div>
    );
  }

  render() {
    if (this.state.product === null || !this.state.product.isActive) {
      return (
        <div className="flex min-h-screen flex-col items-center justify-center bg-gradient-to-br from-blue-100 to-white">
          <div className="hidden">
            <CommonHeader />
          </div>
          <p className="mb-6 text-2xl text-gray-700">
            {this.state.productID === null
              ? "Invalid URL"
              : "Loading product details..."}
          </p>
          <a
            href="/"
            className="text-blue-600 transition duration-300 hover:text-blue-800"
          >
            Search Products
          </a>
        </div>
      );
    }

    return (
      <div className="flex min-h-screen flex-col bg-gradient-to-br from-blue-50 to-white">
        <CommonHeader />
        <div className="flex flex-col md:flex-row">
          {this.renderProductImages()}
          {this.renderProductInformation()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Object | any) => ({
  router: state.router,
  user: state.user,
});

export default connect(mapStateToProps, null)(ProductDetails as any);
