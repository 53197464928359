import CommonService from "../Common";
import {
  ListProductRequest,
  ReportProductRequest,
  SendBuyerAReceiptRequest,
  SendDisputeExplainationRequest,
} from "../../interfaces/product";

interface Product {
  pathPrefix?: string;
  CommonService: CommonService | any;
}

class ProductService implements Product {
  pathPrefix?: string | undefined;
  CommonService: CommonService | any;

  constructor() {
    this.pathPrefix = "/api/v1/product";

    this.CommonService = new CommonService();
  }

  listProduct(reqBody: ListProductRequest | any) {
    return this.CommonService.fetch(`${this.pathPrefix}/list`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  sendBuyerAReceipt(reqBody: SendBuyerAReceiptRequest | any) {
    return this.CommonService.fetch(`${this.pathPrefix}/send/receipt`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }

  sendDisputeExplaination(reqBody: SendDisputeExplainationRequest | any) {
    return this.CommonService.fetch(
      `${this.pathPrefix}/send/disputeexplaination`,
      {
        method: "POST",
        mode: "cors",
        credentials: "include",
        body: JSON.stringify(reqBody),
      },
    );
  }

  reportProduct(reqBody: ReportProductRequest | any) {
    return this.CommonService.fetch(`${this.pathPrefix}/report`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }
}

export default ProductService;
