import React from "react";
import queryString from "query-string";
import config from "../../config";
import CommonFooter from "../../components/CommonFooter";
import "./index.css";
import { Mixpanel } from "../../utils/analytics";

class Legal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    const parsedQuery = queryString.parse(window.location.search);

    this.state = {
      legalList: ["terms", "privacy", "cookies", "FAQ"],
      currentView: parsedQuery.view ? parsedQuery.view : "terms",
      brandName: config.labels.brandName,
      effectiveDate: "05/24/2024",
      privacyEmail: "support@layermarket.xyz",
      supportEmail: config.emails.supportEmail,
      supportEmailLink: `mailto:${config.emails.supportEmail}?subject=Support%20Request&body=Please be very clear about your issue so our team can swiftly solve it!`,
      salesEmailLink: `mailto:${config.emails.salesEmail}?subject=Pricing`,
    };

    this.handleUpdateState = this.handleUpdateState.bind(this);
    this.renderLegalList = this.renderLegalList.bind(this);
    this.getLegalContent = this.getLegalContent.bind(this);
  }

  componentDidMount() {
    Mixpanel.track("Home", {
      currentView: this.state.currentView,
    });
  }

  capitalize(label: string) {
    return label.charAt(0).toUpperCase() + label.slice(1);
  }

  handleUpdateState(key: string, value: any) {
    this.setState({
      [key]: value,
    });
  }

  renderHeader() {
    return (
      <div className="bg-royal-blue px-5 py-4 text-center text-white">
        <a href="/" className="text-xl font-bold">
          {this.state.brandName}
        </a>
      </div>
    );
  }

  renderTerms() {
    return (
      <div className="space-y-4 text-gray-700">
        <p className="font-medium">Terms and Conditions</p>
        <p>
          <strong>Introduction:</strong> Welcome to {this.state.brandName}.
          These Terms and Conditions ("Terms") govern your access to and use of
          our website and services at layermarket.xyz ("Website"), which
          facilitate the trading of digital business assets on Ethereum and
          Layer 2 (L2) networks. By accessing or using our Website, you agree to
          be bound by these Terms and all applicable laws.
        </p>
        <p>
          <strong>Services Description:</strong> {this.state.brandName} provides
          a cutting-edge platform for trading a wide range of digital products
          and businesses. Our marketplace supports various items of value that
          businesses own, create, or benefit from, including datasets and
          intellectual property, websites and web applications, newsletters and
          subscriber lists, ebooks and digital content, social media channels
          and profiles, software and tools, digital storefronts and e-commerce
          assets, and more.
        </p>
        <p>
          <strong>User Accounts:</strong> Users must register and create an
          account to buy or sell on {this.state.brandName}. Users agree to
          provide accurate, current, and complete information during the
          registration process. Users are responsible for maintaining the
          confidentiality of account credentials and are fully responsible for
          all activities that occur under their account.
        </p>
        <p>
          <strong>Transactions:</strong> {this.state.brandName} facilitates
          transactions between buyers and sellers. Currently accepted payment
          methods include ETH, USDC, DAI, and GHO. Additional payment methods
          may be added in the future through decentralized governance proposals.
          Buyers and sellers are responsible for complying with all applicable
          laws and regulations regarding their transactions.
        </p>
        <p>
          <strong>Intellectual Property:</strong> All content provided on the
          Website, including but not limited to text, graphics, logos, and
          software, is the property of {this.state.brandName} or its content
          suppliers and protected by intellectual property laws. Users retain
          ownership of their digital assets but grant {this.state.brandName}{" "}
          necessary rights to facilitate transactions and display listings.
        </p>
        <p>
          <strong>Prohibited Activities:</strong> Users agree not to engage in
          activities that violate any laws, infringe upon the rights of others,
          or breach any terms outlined in these Terms. This includes
          transmitting any viruses, malware, or other harmful computer code
          through the Website, and attempting to gain unauthorized access to or
          disrupt any parts of the Website.
        </p>
        <p>
          <strong>Protocol Features:</strong> {this.state.brandName} provides
          key protocol features to support the ecosystem. Users are encouraged
          to review the documentation for information on contract interfaces and
          additional resources for in-depth understanding of the platform.
        </p>
        <p>
          <strong>Amendments to Terms:</strong> {this.state.brandName} reserves
          the right to amend these Terms at any time. All amendments to these
          Terms will be posted on the Website and effective immediately upon
          posting.
        </p>
        <p>
          <strong>Termination:</strong> {this.state.brandName} may terminate
          your access to all or any part of the Website at any time, with or
          without cause, with or without notice, effective immediately.
        </p>
        <p>
          <strong>Governing Law:</strong> These Terms shall be governed by and
          construed in accordance with the laws of the jurisdiction in which{" "}
          {this.state.brandName} operates, without regard to its conflict of law
          provisions.
        </p>
        <p>
          <strong>Key Features:</strong> {this.state.brandName} offers the
          following key features to enhance user experience and platform
          security:
        </p>
        <ul className="list-disc space-y-2 pl-5">
          <li>
            <strong>Seller KYC Verification:</strong> To ensure trust and
            security on our platform, sellers undergo a Know Your Customer (KYC)
            verification process.
          </li>
          <li>
            <strong>Comprehensive Marketplace:</strong> Our platform supports a
            wide range of digital business assets, providing a diverse
            marketplace for buyers and sellers.
          </li>
          <li>
            <strong>Gasless Product Listings (Experimental):</strong> We are
            experimenting with gasless product listings to reduce transaction
            costs for sellers.
          </li>
          <li>
            <strong>Secure Payment & Escrow:</strong> All transactions are
            secured through smart contracts, ensuring safe and transparent
            exchanges.
          </li>
          <li>
            <strong>Product Validation:</strong> We implement measures to
            validate products listed on our platform, maintaining quality
            standards.
          </li>
          <li>
            <strong>Dispute Management:</strong> A robust system is in place to
            handle and resolve disputes between buyers and sellers fairly.
          </li>
          <li>
            <strong>Search & Reviews:</strong> Our platform includes advanced
            search functionality and a review system to help users make informed
            decisions.
          </li>
          <li>
            <strong>Activity Earnings:</strong> Users can earn rewards for their
            active participation and contributions to the platform ecosystem.
          </li>
        </ul>
        <p>
          Users agree to abide by the rules and regulations associated with each
          of these features. For more detailed information on each feature,
          please refer to our{" "}
          <a href="https://docs.layermarket.xyz">documentation</a>.
        </p>
        <p>
          <strong>Contact Information:</strong> If you have any questions about
          these Terms or the {this.state.brandName} protocol, please contact us
          at {this.state.supportEmail}.
        </p>
      </div>
    );
  }

  renderPrivacy() {
    return (
      <div className="space-y-4 text-gray-700">
        <p className="font-medium">Privacy Policy</p>
        <p>
          <strong>Introduction:</strong> This Privacy Policy describes how{" "}
          {this.state.brandName}, accessible at layermarket.xyz ("Website"),
          collects, uses, and shares your personal information when you use our
          services. By using our Website, you agree to the collection and use of
          information in accordance with this policy.
        </p>
        <p>
          <strong>Information We Collect:</strong>
          <ul>
            <li>
              <strong>Personal Identification Information:</strong> Name, email
              address, telephone number, and address collected during account
              registration or transaction processes.
            </li>
            <li>
              <strong>KYC Information:</strong> Government-issued ID and other
              verification information for Seller KYC Verification.
            </li>
            <li>
              <strong>Transaction Data:</strong> Details about the datasets and
              products you buy or sell, including payment and transaction
              statuses.
            </li>
            <li>
              <strong>Technical Data:</strong> IP address, browser type and
              version, operating system, and other technology on the devices you
              use to access the Website.
            </li>
          </ul>
        </p>
        <p>
          <strong>How We Use Your Information:</strong>
          <ul>
            <li>
              To Provide Services: To manage your account, provide you with
              customer support, and ensure the functionality of our marketplace.
            </li>
            <li>
              For Verification: To verify your identity in connection with our
              KYC Verification process.
            </li>
            <li>
              To Improve and Maintain our Services: To perform data analysis,
              identify usage trends, and develop new products and services.
            </li>
            <li>
              For Marketing: To send you promotional messages and other
              information about products and services we think might interest
              you.
            </li>
          </ul>
        </p>
        <p>
          <strong>Sharing Your Information:</strong>
          <ul>
            <li>
              With Service Providers: We share information with third-party
              companies that help us provide our services, such as payment
              processors and security consultants.
            </li>
            <li>
              For Legal Reasons: We may disclose your information if required by
              law or if we believe it is necessary to prevent fraud or other
              illegal activities.
            </li>
            <li>
              In Business Transfers: If {this.state.brandName} is involved in a
              merger, acquisition, or sale of assets, your information may be
              transferred as part of that transaction.
            </li>
          </ul>
        </p>
        <p>
          <strong>Data Security:</strong> We implement appropriate technical and
          organizational measures to protect your personal information from
          unauthorized access, alteration, disclosure, or destruction. However,
          no internet-based site can be 100% secure, so we cannot guarantee
          absolute security.
        </p>
        <p>
          <strong>Data Retention:</strong> We retain your personal information
          as long as necessary to provide the services you have requested, or
          for other essential purposes such as complying with legal obligations,
          resolving disputes, and enforcing our policies.
        </p>
        <p>
          <strong>Your Rights:</strong> You have the right to access, correct,
          or delete your personal information held by us. You can also object to
          the processing of your data, request data portability, and withdraw
          consent for the use of your information.
        </p>
        <p>
          <strong>International Transfers:</strong> Your information may be
          transferred to—and maintained on—computers located outside of your
          state, province, country, or other governmental jurisdiction where the
          data protection laws may differ from those of your jurisdiction.
        </p>
        <p>
          <strong>Children's Privacy:</strong> Our services are not directed to
          individuals under the age of 18. We do not knowingly collect personal
          information from children under 18. If you become aware that a child
          has provided us with personal information, please contact us.
        </p>
        <p>
          <strong>Changes to This Privacy Policy:</strong> We may update our
          Privacy Policy from time to time. We will notify you of any changes by
          posting the new Privacy Policy on this page. You are advised to review
          this Privacy Policy periodically for any changes.
        </p>
        <p>
          <strong>Contact Us:</strong> If you have any questions about this
          Privacy Policy, please contact us at {this.state.privacyEmail}.
        </p>
      </div>
    );
  }

  renderCookies() {
    return (
      <div className="space-y-4 text-gray-700">
        <p className="font-medium">Cookie Policy</p>
        <p>
          <strong>Introduction:</strong> This Cookie Policy ("Policy") outlines
          how {this.state.brandName} ("we," "us," or "our") utilizes cookies and
          similar tracking technologies on our website accessible at
          layermarket.xyz ("Website"). Your use of the Website signifies your
          consent to our use of cookies in accordance with this Policy.
        </p>
        <p>
          <strong>What are Cookies?</strong> Cookies are small data files stored
          on your device when you visit websites. They are used to remember your
          preferences, enhance your browsing experience, and collect information
          about your interactions with the site.
        </p>
        <p>
          <strong>How We Use Cookies:</strong>
          <ul>
            <li>
              <strong>Essential Cookies:</strong> These are necessary for the
              Website to function correctly, including security, network
              management, and accessibility. Without these cookies, our services
              cannot be provided.
            </li>
            <li>
              <strong>Performance and Analytics Cookies:</strong> We employ
              these cookies to collect information on how users interact with
              the Website, enabling us to improve functionality and user
              experience.
            </li>
            <li>
              <strong>Functional Cookies:</strong> These cookies remember your
              preferences and choices (like your user name or language) to
              provide a more personalized online experience.
            </li>
            <li>
              <strong>Advertising Cookies:</strong> Used to deliver
              advertisements more relevant to you and your interests. They are
              also used to limit the number of times you see an advertisement as
              well as help measure the effectiveness of the advertising
              campaign.
            </li>
          </ul>
        </p>
        <p>
          <strong>Third-Party Cookies:</strong> We partner with third-party
          providers who may also set cookies on your device during your visit to
          the Website. These cookies enable third-party features or
          functionality to be provided on or through the site (e.g.,
          advertising, interactive content, and analytics).
        </p>
        <p>
          <strong>Managing Cookies:</strong> You have the ability to accept or
          decline cookies. Most web browsers automatically accept cookies, but
          you can usually modify your browser setting to decline cookies if you
          prefer. This may prevent you from taking full advantage of the
          Website.
        </p>
        <p>
          <strong>Changes to This Policy:</strong> We reserve the right to make
          changes to this Cookie Policy at any time. Any changes will be posted
          here and are effective immediately. By continuing to use the Website
          after changes are made, you agree to the revised Policy.
        </p>
        <p>
          <strong>Contact Us:</strong> If you have any questions about our use
          of cookies or this Policy, please contact us at{" "}
          {config.emails.supportEmail}.
        </p>
      </div>
    );
  }

  renderFAQ() {
    return (
      <div className="FAQContentContainer space-y-4 text-gray-700">
        <p className="font-medium">Frequently Asked Questions</p>

        <div className="FAQContent">
          <div className="FAQQuestion">What is {this.state.brandName}?</div>
          <div className="FAQAnswer">
            {this.state.brandName} is a cutting-edge platform built on Ethereum
            and Layer 2 networks, designed for trading a wide range of digital
            products and businesses. Our marketplace supports various items of
            value that businesses own, create, or benefit from, including
            datasets, websites, newsletters, ebooks, social media channels,
            software, digital storefronts, and more.
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion">
            What kind of digital assets can I buy or sell on{" "}
            {this.state.brandName}?
          </div>
          <div className="FAQAnswer">
            Our marketplace supports a variety of digital business assets,
            including:
            <ul className="mt-2 list-disc pl-5">
              <li>Datasets and intellectual property</li>
              <li>Websites and web applications</li>
              <li>Newsletters and subscriber lists</li>
              <li>Ebooks and digital content</li>
              <li>Social media channels and profiles</li>
              <li>Software and tools</li>
              <li>Digital storefronts and e-commerce assets</li>
              <li>And more</li>
            </ul>
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion">
            What payment methods are accepted on {this.state.brandName}?
          </div>
          <div className="FAQAnswer">
            Currently, we accept the following payment methods: ETH, USDC, DAI,
            and GHO. We plan to expand payment options in the future through
            decentralized governance proposals.
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion">
            How can I use the {this.state.brandName} protocol?
          </div>
          <div className="FAQAnswer">
            You can use {this.state.brandName} as a buyer looking to acquire
            valuable digital assets, a seller aiming to monetize your digital
            creations, or a developer interested in integrating with our
            protocol. Our documentation provides information on key protocol
            features, contract interfaces, and additional resources for in-depth
            understanding.
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion">
            How are transactions secured on {this.state.brandName}?
          </div>
          <div className="FAQAnswer">
            Transactions on {this.state.brandName} are secured through smart
            contracts on Ethereum and Layer 2 networks. These contracts ensure
            that payments are protected and only released when all transaction
            conditions are satisfied.
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion">
            How do I get started on {this.state.brandName}?
          </div>
          <div className="FAQAnswer">
            To get started, explore our documentation to understand the
            platform's features and capabilities. You can then create an
            account, connect your wallet, and start browsing or listing digital
            assets on our marketplace.
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion">How do I contact support?</div>
          <div className="FAQAnswer">
            You can contact our support team by emailing{" "}
            {this.state.privacyEmail} or through the contact form on our
            website. We are available to address any concerns or questions you
            may have about the {this.state.brandName} protocol or marketplace.
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion">What is Seller KYC Verification?</div>
          <div className="FAQAnswer">
            Seller KYC (Know Your Customer) Verification is a process we use to
            verify the identity of sellers on our platform. This helps ensure
            trust and security in transactions. Sellers will need to provide
            certain identification documents to complete this process.
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion">
            What types of digital assets does your marketplace support?
          </div>
          <div className="FAQAnswer">
            Our comprehensive marketplace supports a wide range of digital
            business assets, including datasets, websites, newsletters, ebooks,
            social media channels, software, digital storefronts, and more. This
            diverse selection caters to various business needs and interests.
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion">What are Gasless Product Listings?</div>
          <div className="FAQAnswer">
            Gasless Product Listings is an experimental feature we're exploring
            to reduce transaction costs for sellers. It aims to allow sellers to
            list products without paying gas fees, making it more economical to
            use our platform. As this is experimental, its availability and
            terms may change.
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion">
            How does Secure Payment & Escrow work?
          </div>
          <div className="FAQAnswer">
            Our Secure Payment & Escrow system uses smart contracts to ensure
            safe and transparent transactions. When a buyer makes a purchase,
            the payment is held in escrow until the transaction is completed
            satisfactorily, protecting both buyers and sellers.
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion">What is Product Validation?</div>
          <div className="FAQAnswer">
            Product Validation is our process of verifying and ensuring the
            quality of products listed on our platform. This may involve
            checking the authenticity, completeness, and accuracy of the digital
            assets being sold to maintain high standards on our marketplace.
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion">How does Dispute Management work?</div>
          <div className="FAQAnswer">
            Our Dispute Management system provides a structured process for
            resolving conflicts between buyers and sellers. If a dispute arises,
            both parties can present their case, and our team will work to reach
            a fair resolution based on our policies and the evidence provided.
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion">
            What are the Search & Reviews features?
          </div>
          <div className="FAQAnswer">
            Our platform includes an advanced search functionality to help users
            find relevant digital assets quickly. The review system allows
            buyers to rate and comment on their purchases, providing valuable
            feedback for other users and helping maintain quality standards on
            the platform.
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion">What are Activity Earnings?</div>
          <div className="FAQAnswer">
            Activity Earnings is a feature that rewards users for their active
            participation in the platform ecosystem. Users can earn rewards for
            various activities such as completing successful transactions,
            providing helpful reviews, or contributing to the community. These
            earnings can typically be used within the platform or potentially
            exchanged for other cryptocurrencies.
          </div>
        </div>
      </div>
    );
  }
  renderLegalList() {
    return (
      <div className="bg-gray-100 p-5">
        <ul className="flex flex-wrap justify-center gap-4">
          {this.state.legalList.map((view: string, key: number) => {
            const isActive = this.state.currentView === view.toLowerCase();
            return (
              <li
                key={key}
                className={`text-lg ${isActive ? "font-semibold text-royal-blue" : "text-gray-700"}`}
              >
                <button
                  onClick={() =>
                    this.setState({ currentView: view.toLowerCase() })
                  }
                >
                  {view.toUpperCase()}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  getLegalContent() {
    switch (this.state.currentView) {
      case "terms":
        return this.renderTerms();
      case "privacy":
        return this.renderPrivacy();
      case "cookies":
        return this.renderCookies();
      case "faq":
        return this.renderFAQ();
      default:
        return this.renderTerms();
    }
  }

  renderLegalView() {
    return (
      <div className="p-5">
        <div className="mb-2 text-lg font-bold text-royal-blue">
          {this.state.currentView.toUpperCase()}
        </div>
        {this.getLegalContent()}
      </div>
    );
  }

  render() {
    return (
      <div className="min-h-screen bg-white">
        {this.renderHeader()}
        {this.renderLegalList()}
        {this.renderLegalView()}
        <CommonFooter />
      </div>
    );
  }
}

export default Legal;
