import { useMemo } from "react";
import { store } from "../../redux";
import { Mixpanel } from "../../utils/analytics";

const CommonHomeBanner = () => (
  <div className="bg-gradient-to-r from-blue-500 to-purple-600 px-6 py-4 text-white shadow-lg">
    <div className="container mx-auto text-center">
      <a
        href="https://docs.layermarket.xyz"
        target="_blank"
        rel="noreferrer"
        onClick={(e: any) => {
          const state = store.getState();
          Mixpanel.track("Home - Learn More Click", { user: state.user });
        }}
        className="decoration-2font-semibold inline-block transform text-lg underline underline-offset-4 transition duration-300 ease-in-out hover:scale-105"
      >
        Learn more about selling assets on LayerMarket.
      </a>
    </div>
  </div>
);

export default CommonHomeBanner;
