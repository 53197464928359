import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Description,
} from "@headlessui/react";
import { motion, AnimatePresence } from "framer-motion";
import { XMarkIcon } from "@heroicons/react/24/outline";

const Modal = (props: any) => {
  const [textValue, setTextValue] = useState("");

  const closeModal = () => {
    props.setIsOpen(false);
    setTextValue("");
  };

  const handleSubmit = (e: Event | any) => {
    e.preventDefault();
    props.onSubmit(textValue);
    closeModal();
  };

  let childElement;
  if (props.childElement) {
    childElement = props.childElement;
  } else {
    childElement = (
      <textarea
        className="mt-4 w-full rounded-md border-gray-300 p-2 text-sm text-gray-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        rows={4}
        placeholder="Please type here..."
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
      />
    );
  }

  return (
    <AnimatePresence>
      {props.isOpen && (
        <Dialog
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          open={props.isOpen}
          onClose={closeModal}
          className="fixed inset-0 z-10 overflow-y-auto"
        >
          <div className="flex min-h-screen items-center justify-center p-4">
            <DialogPanel className="w-full max-w-md overflow-hidden rounded-lg bg-white shadow-xl">
              <div className="bg-gradient-to-r from-purple-600 to-indigo-600 px-6 py-4">
                <DialogTitle className="text-xl font-bold text-white">
                  {props.modalTitle}
                </DialogTitle>
                <Description className="mt-1 text-sm text-purple-200">
                  {props.modalDescription}
                </Description>
              </div>
              <div className="px-6 py-4">{childElement}</div>
              <div className="bg-gray-50 px-6 py-3 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleSubmit}
                  className="inline-flex w-full justify-center rounded-md bg-gradient-to-r from-purple-600 to-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:from-purple-700 hover:to-indigo-700 sm:ml-3 sm:w-auto"
                >
                  Submit
                </button>
                <button
                  onClick={closeModal}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};

const CommonModal = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const buttonColor = props.toggleButtonClasses
    ? props.toggleButtonClasses
    : "bg-gradient-to-r from-purple-600 to-indigo-600";

  const renderButton = () => {
    if (!props.showModalOnly) {
      return (
        <button
          className={`rounded-full ${buttonColor} px-6 py-2 font-semibold text-white shadow-lg transition duration-300 ease-in-out hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2`}
          onClick={() => setIsModalOpen(true)}
        >
          {props.toggleButtonText}
        </button>
      );
    }
  };

  useEffect(() => {
    if (!isModalOpen && props.showModalOnly) {
      setIsModalOpen(true);
    }
  }, [isModalOpen, props.showModalOnly]);

  return (
    <div className="App">
      {renderButton()}
      <Modal
        {...props}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onSubmit={props.onSubmit}
        childElement={props.childElement}
      />
    </div>
  );
};

export default CommonModal;
