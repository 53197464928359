import React, { useEffect } from "react";
import { store } from "../../redux";
import { Mixpanel } from "../../utils/analytics";

const PageNotFound = (props: any) => {
  useEffect(() => {
    const state = store.getState();
    Mixpanel.track("PageNotFound", {
      user: state.user,
    });
  }, []);
  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100">
      <h1 className="text-2xl font-bold text-gray-800">404 Page Not Found</h1>
      <a href="/" className="mt-4 text-lg text-blue-600 hover:text-blue-800">
        Home
      </a>
    </div>
  );
};

export default PageNotFound;
