import { http } from "wagmi";
import { arbitrumSepolia, baseSepolia, optimismSepolia } from "wagmi/chains";
import { getDefaultConfig } from "@rainbow-me/rainbowkit";
const inProd = process.env.NODE_ENV === "production";
const baseClientURL: string = inProd
  ? String(process.env.REACT_APP_PROD_WEB_APP_URL)
  : String(process.env.REACT_APP_DEV_WEB_APP_URL);
const domain = process.env.REACT_APP_MAIN_DOMAIN || "layermarket.xyz";
const brandName = process.env.REACT_APP_BRAND_NAME || "LayerMarket";

let walletChainConfig: any = {};
let chains;
let transports;
if (!inProd) {
  const localNodeURL = "http://localhost:8545";
  const localChain = {
    id: 31337,
    name: "Localhost",
    network: "localhost",
    rpcUrls: {
      default: {
        http: [localNodeURL], // URL to your local node
      },
    },
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
  };
  chains = [localChain, arbitrumSepolia, baseSepolia, optimismSepolia] as any;
  transports = {
    [localChain.id]: http(),
    [arbitrumSepolia.id]: http(),
    [baseSepolia.id]: http(),
    [optimismSepolia.id]: http(),
  };
} else {
  chains = [arbitrumSepolia, baseSepolia, optimismSepolia] as any;
  transports = {
    [arbitrumSepolia.id]: http(),
    [baseSepolia.id]: http(),
    [optimismSepolia.id]: http(),
  };
}

walletChainConfig = getDefaultConfig({
  appName: brandName,
  projectId: String(process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID),
  chains,
  transports,
});

const config = {
  inProd,
  eth: {
    contractAddresses: {
      localchain: {
        testnet: {
          Fees: process.env.REACT_APP_FEES_CONTRACT_ADDRESS_LOCALCHAIN_NETWORK,
          Products:
            process.env.REACT_APP_PRODUCTS_CONTRACT_ADDRESS_LOCALCHAIN_NETWORK,
          PurchaseEscrow:
            process.env
              .REACT_APP_PURCHASE_ESCROW_CONTRACT_ADDRESS_LOCALCHAIN_NETWORK,
          PurchaseEscrowUSDC:
            process.env
              .REACT_APP_PURCHASE_ESCROW_USDC_CONTRACT_ADDRESS_LOCALCHAIN_NETWORK,
          PurchaseEscrowDAI:
            process.env
              .REACT_APP_PURCHASE_ESCROW_DAI_CONTRACT_ADDRESS_LOCALCHAIN_NETWORK,
          PurchaseEscrowGHO:
            process.env
              .REACT_APP_PURCHASE_ESCROW_GHO_CONTRACT_ADDRESS_LOCALCHAIN_NETWORK,
          GHO: process.env.REACT_APP_GHO_CONTRACT_ADDRESS_LOCALCHAIN_NETWORK,
          USDC: process.env.REACT_APP_USDC_CONTRACT_ADDRESS_LOCALCHAIN_NETWORK,
          DAI: process.env.REACT_APP_DAI_CONTRACT_ADDRESS_LOCALCHAIN_NETWORK,
          RatingsReviews:
            process.env
              .REACT_APP_RATINGS_REVIEWS_CONTRACT_ADDRESS_LOCALCHAIN_NETWORK,
          Validators:
            process.env
              .REACT_APP_VALIDATORS_CONTRACT_ADDRESS_LOCALCHAIN_NETWORK,
          CoreValidatorAddress:
            process.env.REACT_APP_CORE_VALIDATOR_ADDRESS_LOCALCHAIN_NETWORK,
          RANDOM: process.env.REACT_APP_RANDOM_ADDRESS_LOCALCHAIN_NETWORK,
          veRANDOM: process.env.REACT_APP_VERANDOM_ADDRESS_LOCALCHAIN_NETWORK,
          Rewards: process.env.REACT_APP_REWARDS_ADDRESS_LOCALCHAIN_NETWORK,
        },
      },
      eth: {
        testnet: {
          Fees: process.env.REACT_APP_FEES_CONTRACT_ADDRESS_ETH_TESTNET_NETWORK,
          Products:
            process.env.REACT_APP_PRODUCTS_CONTRACT_ADDRESS_ETH_TESTNET_NETWORK,
          PurchaseEscrow:
            process.env
              .REACT_APP_PURCHASE_ESCROW_CONTRACT_ADDRESS_ETH_TESTNET_NETWORK,
          PurchaseEscrowUSDC:
            process.env
              .REACT_APP_PURCHASE_ESCROW_USDC_CONTRACT_ADDRESS_ETH_TESTNET_NETWORK,
          PurchaseEscrowDAI:
            process.env
              .REACT_APP_PURCHASE_ESCROW_DAI_CONTRACT_ADDRESS_ETH_TESTNET_NETWORK,
          PurchaseEscrowGHO:
            process.env
              .REACT_APP_PURCHASE_ESCROW_GHO_CONTRACT_ADDRESS_ETH_TESTNET_NETWORK,
          GHO: process.env.REACT_APP_GHO_CONTRACT_ADDRESS_ETH_TESTNET_NETWORK,
          USDC: process.env.REACT_APP_USDC_CONTRACT_ADDRESS_ETH_TESTNET_NETWORK,
          DAI: process.env.REACT_APP_DAI_CONTRACT_ADDRESS_ETH_TESTNET_NETWORK,
          RatingsReviews:
            process.env
              .REACT_APP_RATINGS_REVIEWS_CONTRACT_ADDRESS_ETH_TESTNET_NETWORK,
          Validators:
            process.env
              .REACT_APP_VALIDATORS_CONTRACT_ADDRESS_ETH_TESTNET_NETWORK,
          CoreValidatorAddress:
            process.env.REACT_APP_CORE_VALIDATOR_ADDRESS_ETH_TESTNET_NETWORK,
          RANDOM: process.env.REACT_APP_RANDOM_ADDRESS,
          veRANDOM: process.env.REACT_APP_VERANDOM_ADDRESS,
          Rewards: process.env.REACT_APP_REWARDS_ADDRESS,
        },
        mainnet: {
          Fees: process.env.REACT_APP_FEES_CONTRACT_ADDRESS_ETH_MAINNET_NETWORK,
          Products:
            process.env.REACT_APP_PRODUCTS_CONTRACT_ADDRESS_ETH_MAINNET_NETWORK,
          PurchaseEscrow:
            process.env
              .REACT_APP_PURCHASE_ESCROW_CONTRACT_ADDRESS_ETH_MAINNET_NETWORK,
          PurchaseEscrowUSDC:
            process.env
              .REACT_APP_PURCHASE_ESCROW_USDC_CONTRACT_ADDRESS_ETH_MAINNET_NETWORK,
          PurchaseEscrowDAI:
            process.env
              .REACT_APP_PURCHASE_ESCROW_DAI_CONTRACT_ADDRESS_ETH_MAINNET_NETWORK,
          PurchaseEscrowGHO:
            process.env
              .REACT_APP_PURCHASE_ESCROW_GHO_CONTRACT_ADDRESS_ETH_MAINNET_NETWORK,
          GHO: process.env.REACT_APP_GHO_CONTRACT_ADDRESS_ETH_MAINNET_NETWORK,
          USDC: process.env.REACT_APP_USDC_CONTRACT_ADDRESS_ETH_MAINNET_NETWORK,
          DAI: process.env.REACT_APP_DAI_CONTRACT_ADDRESS_ETH_MAINNET_NETWORK,
          RatingsReviews:
            process.env
              .REACT_APP_RATINGS_REVIEWS_CONTRACT_ADDRESS_ETH_MAINNET_NETWORK,
          Validators:
            process.env
              .REACT_APP_VALIDATORS_CONTRACT_ADDRESS_ETH_MAINNET_NETWORK,
          CoreValidatorAddress:
            process.env.REACT_APP_CORE_VALIDATOR_ADDRESS_ETH_MAINNET_NETWORK,
          RANDOM: process.env.REACT_APP_RANDOM_ADDRESS,
          veRANDOM: process.env.REACT_APP_VERANDOM_ADDRESS,
          Rewards: process.env.REACT_APP_REWARDS_ADDRESS,
        },
      },
      arbitrum: {
        testnet: {
          Fees: process.env.REACT_APP_FEES_CONTRACT_ADDRESS_ARB_TESTNET_NETWORK,
          Products:
            process.env.REACT_APP_PRODUCTS_CONTRACT_ADDRESS_ARB_TESTNET_NETWORK,
          PurchaseEscrow:
            process.env
              .REACT_APP_PURCHASE_ESCROW_CONTRACT_ADDRESS_ARB_TESTNET_NETWORK,
          PurchaseEscrowUSDC:
            process.env
              .REACT_APP_PURCHASE_ESCROW_USDC_CONTRACT_ADDRESS_ARB_TESTNET_NETWORK,
          PurchaseEscrowDAI:
            process.env
              .REACT_APP_PURCHASE_ESCROW_DAI_CONTRACT_ADDRESS_ARB_TESTNET_NETWORK,
          PurchaseEscrowGHO:
            process.env
              .REACT_APP_PURCHASE_ESCROW_GHO_CONTRACT_ADDRESS_ARB_TESTNET_NETWORK,
          GHO: process.env.REACT_APP_GHO_CONTRACT_ADDRESS_ARB_TESTNET_NETWORK,
          USDC: process.env.REACT_APP_USDC_CONTRACT_ADDRESS_ARB_TESTNET_NETWORK,
          DAI: process.env.REACT_APP_DAI_CONTRACT_ADDRESS_ARB_TESTNET_NETWORK,
          RatingsReviews:
            process.env
              .REACT_APP_RATINGS_REVIEWS_CONTRACT_ADDRESS_ARB_TESTNET_NETWORK,
          Validators:
            process.env
              .REACT_APP_VALIDATORS_CONTRACT_ADDRESS_ARB_TESTNET_NETWORK,
          CoreValidatorAddress:
            process.env.REACT_APP_CORE_VALIDATOR_ADDRESS_ARB_TESTNET_NETWORK,
          RANDOM: process.env.REACT_APP_ARB_TESTNET_RANDOM_ADDRESS,
          veRANDOM: process.env.REACT_APP_ARB_TESTNET_VERANDOM_ADDRESS,
          Rewards: process.env.REACT_APP_ARB_TESTNET_REWARDS_ADDRESS,
        },
        mainnet: {
          Fees: process.env.REACT_APP_FEES_CONTRACT_ADDRESS_ARB_MAINNET_NETWORK,
          Products:
            process.env.REACT_APP_PRODUCTS_CONTRACT_ADDRESS_ARB_MAINNET_NETWORK,
          PurchaseEscrow:
            process.env
              .REACT_APP_PURCHASE_ESCROW_CONTRACT_ADDRESS_ARB_MAINNET_NETWORK,
          PurchaseEscrowUSDC:
            process.env
              .REACT_APP_PURCHASE_ESCROW_USDC_CONTRACT_ADDRESS_ARB_MAINNET_NETWORK,
          PurchaseEscrowDAI:
            process.env
              .REACT_APP_PURCHASE_ESCROW_DAI_CONTRACT_ADDRESS_ARB_MAINNET_NETWORK,
          PurchaseEscrowGHO:
            process.env
              .REACT_APP_PURCHASE_ESCROW_DAI_CONTRACT_ADDRESS_ARB_MAINNET_NETWORK,
          GHO: process.env.REACT_APP_GHO_CONTRACT_ADDRESS_ARB_MAINNET_NETWORK,
          USDC: process.env.REACT_APP_USDC_CONTRACT_ADDRESS_ARB_MAINNET_NETWORK,
          DAI: process.env.REACT_APP_DAI_CONTRACT_ADDRESS_ARB_MAINNET_NETWORK,
          RatingsReviews:
            process.env
              .REACT_APP_RATINGS_REVIEWS_CONTRACT_ADDRESS_ARB_MAINNET_NETWORK,
          Validators:
            process.env
              .REACT_APP_VALIDATORS_CONTRACT_ADDRESS_ARB_MAINNET_NETWORK,
          CoreValidatorAddress:
            process.env.REACT_APP_CORE_VALIDATOR_ADDRESS_ARB_MAINNET_NETWORK,
          RANDOM: process.env.REACT_APP_RANDOM_ADDRESS,
          veRANDOM: process.env.REACT_APP_VERANDOM_ADDRESS,
          Rewards: process.env.REACT_APP_REWARDS_ADDRESS,
        },
      },
      base: {
        testnet: {
          Fees: process.env
            .REACT_APP_FEES_CONTRACT_ADDRESS_BASE_TESTNET_NETWORK,
          Products:
            process.env
              .REACT_APP_PRODUCTS_CONTRACT_ADDRESS_BASE_TESTNET_NETWORK,
          PurchaseEscrow:
            process.env
              .REACT_APP_PURCHASE_ESCROW_CONTRACT_ADDRESS_BASE_TESTNET_NETWORK,
          PurchaseEscrowUSDC:
            process.env
              .REACT_APP_PURCHASE_ESCROW_USDC_CONTRACT_ADDRESS_BASE_TESTNET_NETWORK,
          PurchaseEscrowDAI:
            process.env
              .REACT_APP_PURCHASE_ESCROW_DAI_CONTRACT_ADDRESS_BASE_TESTNET_NETWORK,
          PurchaseEscrowGHO:
            process.env
              .REACT_APP_PURCHASE_ESCROW_GHO_CONTRACT_ADDRESS_BASE_TESTNET_NETWORK,
          GHO: process.env.REACT_APP_GHO_CONTRACT_ADDRESS_BASE_TESTNET_NETWORK,
          USDC: process.env
            .REACT_APP_USDC_CONTRACT_ADDRESS_BASE_TESTNET_NETWORK,
          DAI: process.env.REACT_APP_DAI_CONTRACT_ADDRESS_BASE_TESTNET_NETWORK,
          RatingsReviews:
            process.env
              .REACT_APP_RATINGS_REVIEWS_CONTRACT_ADDRESS_BASE_TESTNET_NETWORK,
          Validators:
            process.env
              .REACT_APP_VALIDATORS_CONTRACT_ADDRESS_BASE_TESTNET_NETWORK,
          CoreValidatorAddress:
            process.env.REACT_APP_CORE_VALIDATOR_ADDRESS_BASE_TESTNET_NETWORK,
          RANDOM: process.env.REACT_APP_BASE_TESTNET_RANDOM_ADDRESS,
          veRANDOM: process.env.REACT_APP_BASE_TESTNET_VERANDOM_ADDRESS,
          Rewards: process.env.REACT_APP_BASE_TESTNET_REWARDS_ADDRESS,
        },
        mainnet: {
          Fees: process.env
            .REACT_APP_FEES_CONTRACT_ADDRESS_BASE_MAINNET_NETWORK,
          Products:
            process.env
              .REACT_APP_PRODUCTS_CONTRACT_ADDRESS_BASE_MAINNET_NETWORK,
          PurchaseEscrow:
            process.env
              .REACT_APP_PURCHASE_ESCROW_CONTRACT_ADDRESS_BASE_MAINNET_NETWORK,
          PurchaseEscrowUSDC:
            process.env
              .REACT_APP_PURCHASE_ESCROW_USDC_CONTRACT_ADDRESS_BASE_MAINNET_NETWORK,
          PurchaseEscrowDAI:
            process.env
              .REACT_APP_PURCHASE_ESCROW_DAI_CONTRACT_ADDRESS_BASE_MAINNET_NETWORK,
          PurchaseEscrowGHO:
            process.env
              .REACT_APP_PURCHASE_ESCROW_GHO_CONTRACT_ADDRESS_BASE_MAINNET_NETWORK,
          GHO: process.env.REACT_APP_GHO_CONTRACT_ADDRESS_BASE_MAINNET_NETWORK,
          USDC: process.env
            .REACT_APP_USDC_CONTRACT_ADDRESS_BASE_MAINNET_NETWORK,
          DAI: process.env.REACT_APP_DAI_CONTRACT_ADDRESS_BASE_MAINNET_NETWORK,
          RatingsReviews:
            process.env
              .REACT_APP_RATINGS_REVIEWS_CONTRACT_ADDRESS_BASE_MAINNET_NETWORK,
          Validators:
            process.env
              .REACT_APP_VALIDATORS_CONTRACT_ADDRESS_BASE_MAINNET_NETWORK,
          CoreValidatorAddress:
            process.env.REACT_APP_CORE_VALIDATOR_ADDRESS_BASE_MAINNET_NETWORK,
          RANDOM: process.env.REACT_APP_RANDOM_ADDRESS,
          veRANDOM: process.env.REACT_APP_VERANDOM_ADDRESS,
          Rewards: process.env.REACT_APP_REWARDS_ADDRESS,
        },
      },
      op: {
        testnet: {
          Fees: process.env.REACT_APP_FEES_CONTRACT_ADDRESS_OP_TESTNET_NETWORK,
          Products:
            process.env.REACT_APP_PRODUCTS_CONTRACT_ADDRESS_OP_TESTNET_NETWORK,
          PurchaseEscrow:
            process.env
              .REACT_APP_PURCHASE_ESCROW_CONTRACT_ADDRESS_OP_TESTNET_NETWORK,
          PurchaseEscrowUSDC:
            process.env
              .REACT_APP_PURCHASE_ESCROW_USDC_CONTRACT_ADDRESS_OP_TESTNET_NETWORK,
          PurchaseEscrowDAI:
            process.env
              .REACT_APP_PURCHASE_ESCROW_DAI_CONTRACT_ADDRESS_OP_TESTNET_NETWORK,
          PurchaseEscrowGHO:
            process.env
              .REACT_APP_PURCHASE_ESCROW_DAI_CONTRACT_ADDRESS_OP_TESTNET_NETWORK,
          GHO: process.env.REACT_APP_GHO_CONTRACT_ADDRESS_OP_TESTNET_NETWORK,
          USDC: process.env.REACT_APP_USDC_CONTRACT_ADDRESS_OP_TESTNET_NETWORK,
          DAI: process.env.REACT_APP_DAI_CONTRACT_ADDRESS_OP_TESTNET_NETWORK,
          RatingsReviews:
            process.env
              .REACT_APP_RATINGS_REVIEWS_CONTRACT_ADDRESS_OP_TESTNET_NETWORK,
          Validators:
            process.env
              .REACT_APP_VALIDATORS_CONTRACT_ADDRESS_OP_TESTNET_NETWORK,
          CoreValidatorAddress:
            process.env.REACT_APP_CORE_VALIDATOR_ADDRESS_OP_TESTNET_NETWORK,
          RANDOM: process.env.REACT_APP_OP_TESTNET_RANDOM_ADDRESS,
          veRANDOM: process.env.REACT_APP_OP_TESTNET_VERANDOM_ADDRESS,
          Rewards: process.env.REACT_APP_OP_TESTNET_REWARDS_ADDRESS,
        },
        mainnet: {
          Fees: process.env.REACT_APP_FEES_CONTRACT_ADDRESS_OP_MAINNET_NETWORK,
          Products:
            process.env.REACT_APP_PRODUCTS_CONTRACT_ADDRESS_OP_MAINNET_NETWORK,
          PurchaseEscrow:
            process.env
              .REACT_APP_PURCHASE_ESCROW_CONTRACT_ADDRESS_OP_MAINNET_NETWORK,
          PurchaseEscrowUSDC:
            process.env
              .REACT_APP_PURCHASE_ESCROW_USDC_CONTRACT_ADDRESS_OP_MAINNET_NETWORK,
          PurchaseEscrowDAI:
            process.env
              .REACT_APP_PURCHASE_ESCROW_DAI_CONTRACT_ADDRESS_OP_MAINNET_NETWORK,
          PurchaseEscrowGHO:
            process.env
              .REACT_APP_PURCHASE_ESCROW_DAI_CONTRACT_ADDRESS_OP_MAINNET_NETWORK,
          GHO: process.env.REACT_APP_GHO_CONTRACT_ADDRESS_OP_MAINNET_NETWORK,
          USDC: process.env.REACT_APP_USDC_CONTRACT_ADDRESS_OP_MAINNET_NETWORK,
          DAI: process.env.REACT_APP_DAI_CONTRACT_ADDRESS_OP_MAINNET_NETWORK,
          RatingsReviews:
            process.env
              .REACT_APP_RATINGS_REVIEWS_CONTRACT_ADDRESS_OP_MAINNET_NETWORK,
          Validators:
            process.env
              .REACT_APP_VALIDATORS_CONTRACT_ADDRESS_OP_MAINNET_NETWORK,
          CoreValidatorAddress:
            process.env.REACT_APP_CORE_VALIDATOR_ADDRESS_OP_MAINNET_NETWORK,
          RANDOM: process.env.REACT_APP_RANDOM_ADDRESS,
          veRANDOM: process.env.REACT_APP_VERANDOM_ADDRESS,
          Rewards: process.env.REACT_APP_REWARDS_ADDRESS,
        },
      },
    },
    nodes: {
      localchain: {
        testnet: {
          nodeProviderURL:
            process.env.REACT_APP_JSON_RPC_PROVIDER_URL ||
            "https://mainnet.infura.io/v3/your_project_id",
        },
        mainnet: {
          nodeProviderURL:
            process.env.REACT_APP_JSON_RPC_PROVIDER_URL ||
            "https://mainnet.infura.io/v3/your_project_id",
        },
      },
      eth: {
        testnet: {
          nodeProviderURL:
            process.env.REACT_APP_JSON_RPC_PROVIDER_URL_ETH_TESTNET_NETWORK ||
            "https://mainnet.infura.io/v3/your_project_id",
        },
        mainnet: {
          nodeProviderURL:
            process.env.REACT_APP_JSON_RPC_PROVIDER_URL_ETH_MAINNET_NETWORK ||
            "https://mainnet.infura.io/v3/your_project_id",
        },
      },
      arbitrum: {
        testnet: {
          nodeProviderURL:
            process.env.REACT_APP_JSON_RPC_PROVIDER_URL_ARB_TESTNET_NETWORK ||
            "https://mainnet.infura.io/v3/your_project_id",
        },
        mainnet: {
          nodeProviderURL:
            process.env.REACT_APP_JSON_RPC_PROVIDER_URL_ARB_MAINNET_NETWORK ||
            "https://mainnet.infura.io/v3/your_project_id",
        },
      },
      base: {
        testnet: {
          nodeProviderURL:
            process.env.REACT_APP_JSON_RPC_PROVIDER_URL_BASE_TESTNET_NETWORK ||
            "https://mainnet.infura.io/v3/your_project_id",
        },
        mainnet: {
          nodeProviderURL:
            process.env.REACT_APP_JSON_RPC_PROVIDER_URL_BASE_MAINNET_NETWORK ||
            "https://mainnet.infura.io/v3/your_project_id",
        },
      },
      op: {
        testnet: {
          nodeProviderURL:
            process.env.REACT_APP_JSON_RPC_PROVIDER_URL_OP_TESTNET_NETWORK ||
            "https://mainnet.infura.io/v3/your_project_id",
        },
        mainnet: {
          nodeProviderURL:
            process.env.REACT_APP_JSON_RPC_PROVIDER_URL_OP_MAINNET_NETWORK ||
            "https://mainnet.infura.io/v3/your_project_id",
        },
      },
    },
  },
  cookies: {
    consentCookieLabel:
      process.env.REACT_APP_CONSENT_COOKIE_LABEL || "consent-cookie",
    sessionCookieLabel: process.env.REACT_APP_SESSION_COOKIE_LABEL || "ip_st",
  },
  emails: {
    privacyEmail:
      process.env.REACT_APP_PRIVACY_EMAIL || "privacy@layermarket.xyz",
    salesEmail: process.env.REACT_APP_SALES_EMAIL || "sales@layermarket.xyz",
    supportEmail:
      process.env.REACT_APP_SUPPORT_EMAIL || "support@layermarket.xyz",
  },
  graphql: {
    // TODO: Query optimization (now since we are on testnet)?
    uri:
      process.env.REACT_APP_GRAPHQL_URI ||
      "https://flyby-router-demo.herokuapp.com/",
  },
  labels: {
    brandName,
    domain,
  },
  links: {
    baseClientURL,
    baseAPIURL: inProd
      ? process.env.REACT_APP_PROD_API_DOMAIN
      : process.env.REACT_APP_DEV_API_DOMAIN,
    cookiePolicyURL:
      process.env.COOKIE_POLICY_URL || "https://layermarket.xyz/legal",
    documentationURL:
      process.env.REACT_APP_DOCUMENTATION_URL || "https://docs.layermarket.xyz",
    blogURL:
      process.env.REACT_APP_BLOG_URL || "https://medium.com/@layermarketxyz",
    stripeConnectBaseURL:
      process.env.NODE_ENV === "production"
        ? "https://connect.stripe.com/express_login"
        : "https://connect.stripe.com/express_login",
    stripeCustomerPortalURL: inProd
      ? "https://billing.stripe.com/p/login/14k00he561TqeaIfYY"
      : "https://billing.stripe.com/p/login/test_aEU7sK9ST2Ki0Xm9AA",
    placeholderImage:
      "https://ipfs.io/ipfs/QmRhoWZDwbgi3WLryPs7yJHxRvqYd4JsQGMLS48vBEeA8W/jeremy-perkins-uhjiu8FjnsQ-unsplash.jpg",
  },
  moonPay: {
    apiKey: process.env.REACT_APP_MOONPAY_API_KEY || "apiKey",
    debug: !inProd,
  },
  products: {
    searchFilterTypes: ["Title", "Description"],
    // searchCategories: [
    //   "All",
    //   "Books",
    //   "Datasets",
    //   "Digital Products",
    //   "Electronics and Accessories",
    //   "Health and Beauty",
    //   "Home and Garden",
    //   "Fashion",
    //   "Collectibles and Art",
    //   "Automotive Parts",
    //   "Jewelry and Watches",
    //   "Musical Instruments",
    //   "Sporting Goods",
    //   "Video Games and Consoles",
    //   "Other",
    // ],
    // searchCategories: [
    //   "All",
    //   "Datasets & IP",
    //   "Websites & Apps",
    //   "Newsletters & Lists",
    //   "Ebooks & Content",
    //   "Social Media",
    //   "Software & Tools",
    //   "E-commerce Assets",
    //   "Other Digital Assets",
    // ],
    searchCategories: [
      "All",
      "Datasets",
      "Websites",
      "Newsletters",
      "Ebooks",
      "Channels",
      "Other",
    ],
    searchOrderByTypes: [
      "Listed: Latest",
      "Listed: Earliest",
      "Price: Low to high",
      "Price: High to low",
    ],
    ratingScores: [5, 4, 3, 2, 1],
  },
  stripe: {
    publicKey:
      process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
      "pk_test_51JhPgKKkCirx1bze0v60sRtelvtfS9wcticYcxdYylvgmXbsovfKtsZ2EZ1s5H5woPxNM8rM2Z2GUcjeGwBVtT6400CG6xTHPo",
  },
  web3: {
    chains,
    walletChainConfig,
    thirdWebAPIKey:
      process.env.REACT_APP_THIRDWEB_API_KEY ||
      "1b5bb1b0a5884287e09d45d0c4f02262",
    ipfsMediaURLPrefix: "ipfs://",
  },
};

export default config;
