import CommonService from "../Common";
import { SearchProductsRequest } from "../../interfaces/search";

interface Search {
  pathPrefix?: string;
  CommonService: CommonService | any;
}

class SearchService implements Search {
  pathPrefix?: string | undefined;
  CommonService: CommonService | any;

  constructor() {
    this.pathPrefix = "/api/v1/search";

    this.CommonService = new CommonService();
  }

  searchProducts(reqBody: SearchProductsRequest) {
    if (
      !reqBody.sellerAddress ||
      (reqBody.sellerAddress && reqBody.sellerAddress === null)
    ) {
      delete reqBody.sellerAddress;
    }
    return this.CommonService.fetch(`${this.pathPrefix}/products`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }
}

export default SearchService;
