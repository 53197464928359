import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import { createLogger } from "redux-logger";
import userReducer from "./reducers/user";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

const middleware = [routerMiddleware];
if (process.env.NODE_ENV !== "production") {
  middleware.push(createLogger());
}

export const store = configureStore({
  reducer: combineReducers({
    router: routerReducer,
    user: userReducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
});

export const history = createReduxHistory(store);
