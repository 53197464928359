import { displayToastMessage } from "./toasts";

// TODO: Review initial calls to each on home...
export const getCartItems = () => {
  // console.log("getCartItems()");
  const cartItems = localStorage.getItem("cart");
  return cartItems ? JSON.parse(cartItems) : [];
};

export const saveCartItems = (cartItems: any) => {
  // console.log("saveCartItems()");
  localStorage.setItem("cart", JSON.stringify(cartItems));
  return cartItems;
};

export const removeFromCart = (index: number) => {
  // console.log("removeFromCart()");
  let cartItems = getCartItems();
  cartItems.splice(index, 1);
  saveCartItems(cartItems);
};

export const clearCart = () => {
  // console.log("clearCart()");
  if (getCartItems().length > 0) {
    displayToastMessage("info", "Cart cleared...Please refresh");
  }
  saveCartItems([]);
}