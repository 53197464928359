import CommonService from "../Common";
import {
  ActivateAccountRequest,
  ForgotPasswordRequest,
  ResetPasswordRequest,
  SignUpLogInRequest,
  IDVerificationStartRequest,
} from "../../interfaces/auth";
import {
  UpdateAccountRequest,
  UpdateSellerProfileRequest,
} from "../../interfaces/user";

interface User {
  pathPrefix?: string;
  CommonService: CommonService | any;
}

class AccountService implements User {
  pathPrefix?: string | undefined;
  CommonService: CommonService | any;

  constructor() {
    this.pathPrefix = "/api/v1/account";
    this.CommonService = new CommonService();
  }

  signUp({ email, phoneNumber, password, type }: SignUpLogInRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/signup`, {
      method: "POST",
      body: JSON.stringify({
        email,
        phoneNumber,
        password,
        type,
      }),
    });
  }

  activateAccount({ email, activationToken }: ActivateAccountRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/activate`, {
      method: "POST",
      body: JSON.stringify({
        email,
        activationToken,
      }),
    });
  }

  idVerificationStart({ userID, email }: IDVerificationStartRequest) {
    return this.CommonService.fetch(
      `${this.pathPrefix}/id/verification/start`,
      {
        method: "POST",
        mode: "cors",
        credentials: "include",
        body: JSON.stringify({
          email,
          userID,
        }),
      },
    );
  }

  forgotPassword({ email }: ForgotPasswordRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/forgotpassword`, {
      method: "POST",
      body: JSON.stringify({ email }),
    });
  }

  resetPassword({ email, newPassword, resetToken }: ResetPasswordRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/resetpassword`, {
      method: "PUT",
      body: JSON.stringify({
        email,
        newPassword,
        resetToken,
      }),
    });
  }

  updateAccount({
    userID,
    email,
    password,
    updatedAccount,
  }: UpdateAccountRequest) {
    if (updatedAccount.email?.trim() === "") {
      delete updatedAccount.email;
    }
    if (updatedAccount.phoneNumber?.trim() === "") {
      delete updatedAccount.phoneNumber;
    }
    return this.CommonService.fetch(`${this.pathPrefix}/update`, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify({
        userID,
        email,
        password,
        updatedAccount,
      }),
    });
  }

  updateSellerProfile(reqBody: UpdateSellerProfileRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/update/profile`, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify({
        userID: reqBody.userID,
        email: reqBody.email,
        avatarImage: reqBody.avatarImage,
        profileAboutYouText: reqBody.profileAboutYouText,
      }),
    });
  }
}

export default AccountService;
