import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import Joi from "joi";
import CommonHeader from "../../components/CommonHeader";
import { displayToastMessage } from "../../utils/toasts";
import CommonProductsList from "../../components/CommonProductsList";
import RatingsReviewsContract from "../../contracts/RatingsReviewsV1.sol/RatingsReviewsV1.json";
import {
  logIssueNetworkRequest,
  logIssueNetworkRequestError,
  logUpdatedState,
} from "../../utils/loggers";
import AccountService from "../../services/Account";
import SessionService from "../../services/Session";
import { setUser } from "../../redux/actions/user";
import { authRoutePath } from "../Router";
import { contactSupportErrorLabel } from "../../utils/errors";
import { getNetworkConfig } from "../../utils/network";
import { Mixpanel } from "../../utils/analytics";
import { convertIPFSLinkToHTTPSLink } from "../../utils/convert";
import config from "../../config";
import { createThirdwebClient } from "thirdweb";
import { upload } from "thirdweb/storage";

type AccountFormType = {
  email: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
};

const SellerDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);

  const [sellerInfo, setSellerInfo] = useState({
    sellerID: null,
    totalRatings: "...",
    averageRating: "...",
  });
  const [accountForm, setAccountForm] = useState<AccountFormType>({
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    password: "",
    confirmPassword: "",
  });
  const [profileForm, setProfileForm] = useState({
    currentAvatarImage:
      user?.avatarImage ||
      "ipfs://QmRhoWZDwbgi3WLryPs7yJHxRvqYd4JsQGMLS48vBEeA8W/jeremy-perkins-uhjiu8FjnsQ-unsplash.jpg",
    profileAboutYouText: user?.profileAboutYouText || "",
  });

  const accountService = new AccountService();

  useEffect(() => {
    setProfileForm((prev) => ({
      ...prev,
      currentAvatarImage:
        user?.avatarImage ||
        "ipfs://QmRhoWZDwbgi3WLryPs7yJHxRvqYd4JsQGMLS48vBEeA8W/jeremy-perkins-uhjiu8FjnsQ-unsplash.jpg",
      profileAboutYouText: user?.profileAboutYouText || "",
    }));
  }, [user.avatarImage, user.profileAboutYouText]);

  const loadSellerInformation = useCallback(async () => {
    logIssueNetworkRequest("SellerDashboard.loadSellerInformation()");

    try {
      const networkConfig = getNetworkConfig(user.chainId, user.provider);
      const provider = networkConfig.provider;
      const signer = await provider.getSigner();
      const ratingsReviewsContract = new ethers.Contract(
        networkConfig.addresses.RatingsReviews,
        RatingsReviewsContract.abi,
        signer,
      );

      const totalRatings = await ratingsReviewsContract.reviewCounts(
        user.walletAddress,
      );
      const averageRating = await ratingsReviewsContract.getAverageRating(
        user.walletAddress,
      );

      setSellerInfo({
        sellerID: user.id,
        totalRatings: totalRatings.toString(),
        averageRating: averageRating.toString(),
      });

      return true;
    } catch (error: Error | any) {
      logIssueNetworkRequestError(
        "SellerDashboard.loadSellerInformation():",
        error,
      );
      return false;
    }
  }, [user]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      displayToastMessage("info", "Fetching seller information...");
      const success = await loadSellerInformation();
      if (isMounted) {
        if (success) {
          displayToastMessage(
            "success",
            "Seller information loaded successfully",
          );
        } else {
          displayToastMessage(
            "error",
            `Failed to load seller data. ${contactSupportErrorLabel}`,
          );
        }
      }
    };

    if (user.id) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [user.id, loadSellerInformation]);

  const handleUpdateAccount = useCallback(
    async (e: Event | any) => {
      e.preventDefault();

      const schema = Joi.object({
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required(),
        phoneNumber: Joi.string()
          .pattern(/^\+?[1-9]\d{1,14}$/)
          .required(),
        password: Joi.string().min(6).required(),
        confirmPassword: Joi.string()
          .valid(Joi.ref("password"))
          .required()
          .messages({ "any.only": "Passwords do not match" }),
      });

      const { error } = schema.validate(accountForm);

      if (error) {
        displayToastMessage("error", error.details[0].message);
        return;
      }

      logIssueNetworkRequest("SellerDashboard.handleUpdateAccount()");
      displayToastMessage("info", "Updating account...");

      try {
        const res = await accountService.updateAccount({
          userID: user.id,
          email: user.email,
          password: accountForm.password,
          updatedAccount: {
            email: accountForm.email,
            phoneNumber: accountForm.phoneNumber,
          },
        });
        if (res.error) throw new Error(res.error);
        displayToastMessage("success", "Account updated successfully.");
      } catch (error: Error | any) {
        logIssueNetworkRequestError(
          "SellerDashboard.handleUpdateAccount()",
          error,
        );
        displayToastMessage(
          "error",
          `Failed to update account. ${contactSupportErrorLabel}`,
        );
      }
    },
    [accountForm, user.id, user.email, accountService],
  );

  const handleUpdateProfile = useCallback(
    async (e: Event | any) => {
      e.preventDefault();
      logIssueNetworkRequest("SellerDashboard.handleUpdateProfile()");
      displayToastMessage("info", "Updating profile...");

      try {
        const res = await accountService.updateSellerProfile({
          userID: user.id,
          email: user.email,
          avatarImage: profileForm.currentAvatarImage,
          profileAboutYouText: profileForm.profileAboutYouText,
        });
        if (res.error) throw new Error(res.error);
        displayToastMessage("success", "Profile updated successfully.");
      } catch (error: Error | any) {
        logIssueNetworkRequestError(
          "SellerDashboard.handleUpdateProfile()",
          error,
        );
        displayToastMessage(
          "error",
          `Failed to update profile. ${contactSupportErrorLabel}`,
        );
      }
    },
    [user.id, user.email, profileForm, accountService],
  );

  const handleAvatarChange = useCallback(async (event: Event | any) => {
    const files: any = Array.from(event.target.files).filter((file: any) =>
      /\.(jpg|jpeg|png|webp)$/i.test(file.name),
    );
    if (files.length > 0) {
      try {
        const client = createThirdwebClient({
          clientId: config.web3.thirdWebAPIKey,
        });
        displayToastMessage("info", "Uploading image...");
        const uri = await upload({ client, files });
        displayToastMessage("success", "Image uploaded successfully");
        setProfileForm((prev) => ({ ...prev, currentAvatarImage: uri }));
        logUpdatedState({ currentAvatarImage: uri }, "currentAvatarImage");
      } catch (error: Error | any) {
        logIssueNetworkRequestError(
          "SellerDashboard.handleAvatarChange()",
          error,
        );
        displayToastMessage(
          "error",
          `Failed to upload image. ${contactSupportErrorLabel}`,
        );
      }
    } else {
      displayToastMessage(
        "error",
        "Please upload valid image files (jpg, jpeg, png, webp).",
      );
    }
  }, []);

  const renderSellerInformation = () => (
    <div className="my-10 overflow-hidden rounded-lg bg-white shadow-lg">
      <div className="bg-gradient-to-r from-blue-500 to-indigo-600 px-6 py-4">
        <h1 className="break-words text-xl font-bold text-white sm:text-2xl">
          My Listings
        </h1>
      </div>
      <div className="flex flex-col space-y-4 p-6 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
        <div className="flex items-center space-x-2">
          <span className="text-lg font-semibold text-gray-700">
            Total Ratings:
          </span>
          <span className="text-2xl font-bold text-blue-600">
            {sellerInfo.totalRatings}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-lg font-semibold text-gray-700">
            Average Rating:
          </span>
          <span className="text-2xl font-bold text-blue-600">
            {sellerInfo.averageRating}
          </span>
        </div>
      </div>
    </div>
  );

  const renderProfileForm = () => (
    <div className="my-10 rounded-lg bg-white p-6 shadow-lg">
      <h2 className="mb-6 text-2xl font-bold text-gray-800">
        Profile Management
      </h2>
      <form onSubmit={handleUpdateProfile} className="space-y-6">
        <div>
          <label className="mb-2 block text-sm font-medium text-gray-700">
            Avatar:
          </label>
          <img
            alt="seller-profile-avatar"
            className="mx-auto mb-4 h-48 w-48 rounded-full object-cover"
            src={convertIPFSLinkToHTTPSLink(profileForm.currentAvatarImage)}
          />
          <input
            type="file"
            onChange={handleAvatarChange}
            className="w-full rounded-lg border border-gray-300 p-2 text-sm file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
          />
        </div>
        <div>
          <label className="mb-2 block text-sm font-medium text-gray-700">
            About You:
          </label>
          <textarea
            name="profileAboutYouText"
            value={profileForm.profileAboutYouText}
            onChange={(e) =>
              setProfileForm((prev) => ({
                ...prev,
                profileAboutYouText: e.target.value,
              }))
            }
            className="w-full rounded-lg border border-gray-300 p-2 focus:border-blue-500 focus:ring focus:ring-blue-200"
            rows={4}
          ></textarea>
        </div>
        <button
          type="submit"
          className="w-full rounded-lg bg-gradient-to-r from-blue-500 to-indigo-600 px-4 py-2 text-white transition duration-300 hover:from-blue-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Update Profile
        </button>
      </form>
    </div>
  );
  const renderAccountForm = () => (
    <div className="my-10 rounded-lg bg-white p-6 shadow-lg">
      <h2 className="mb-6 text-2xl font-bold text-gray-800">
        Account Management
      </h2>
      <form onSubmit={handleUpdateAccount} className="space-y-6">
        {(["email", "phoneNumber", "password", "confirmPassword"] as const).map(
          (field) => (
            <div key={field}>
              <label className="mb-2 block text-sm font-medium text-gray-700">
                {field.charAt(0).toUpperCase() +
                  field.slice(1).replace(/([A-Z])/g, " $1")}
                :
              </label>
              <input
                type={field.includes("password") ? "password" : "text"}
                name={field}
                value={accountForm[field]}
                onChange={(e) =>
                  setAccountForm((prev) => ({
                    ...prev,
                    [field]: e.target.value,
                  }))
                }
                className="w-full rounded-lg border border-gray-300 p-2 focus:border-blue-500 focus:ring focus:ring-blue-200"
              />
            </div>
          ),
        )}
        <button
          type="submit"
          className="w-full rounded-lg bg-gradient-to-r from-blue-500 to-indigo-600 px-4 py-2 text-white transition duration-300 hover:from-blue-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Update Account
        </button>
      </form>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200">
      <CommonHeader />
      <div className="container mx-auto px-4 py-8 lg:px-8">
        {renderProfileForm()}
        {renderAccountForm()}
        {renderSellerInformation()}
        {sellerInfo.sellerID ? (
          <CommonProductsList
            isSellerDashboard={true}
            sellerID={sellerInfo.sellerID}
          />
        ) : (
          <div className="my-10 rounded-lg bg-white p-6 text-center shadow-lg">
            <h2 className="mb-4 text-xl font-semibold text-red-600">
              Loading...
            </h2>
            <button
              onClick={loadSellerInformation}
              className="rounded-lg bg-gradient-to-r from-blue-500 to-indigo-600 px-6 py-2 text-white transition duration-300 hover:from-blue-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Reload
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SellerDashboard;
