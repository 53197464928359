import CommonService from "../Common";
import { UpdateNotificationAccountRequest } from "../../interfaces/notification";

interface Notification {
  pathPrefix?: string;
  CommonService: CommonService | any;
}

class NotificationService implements Notification {
  pathPrefix?: string | undefined;
  CommonService: CommonService | any;

  constructor() {
    this.pathPrefix = "/api/v1/notification";

    this.CommonService = new CommonService();
  }

  updateNotificationAccount(reqBody: UpdateNotificationAccountRequest) {
    return this.CommonService.fetch(`${this.pathPrefix}/update`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(reqBody),
    });
  }
}

export default NotificationService;
