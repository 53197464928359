import { SET_USER, CLEAR_USER } from "../constants";
import { IUser } from "../../interfaces/user";

export type UserType = "inactive" | "seller";

export interface IUserAction {
  type: string;
  user?: IUser | any;
}

export const setUser = (user: IUser | any) => {
  return {
    type: SET_USER,
    user,
  };
};

export const clearUser = () => {
  return { type: CLEAR_USER };
};
