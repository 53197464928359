import React from "react";
import { connect } from "react-redux";
import { createThirdwebClient } from "thirdweb";
import { upload } from "thirdweb/storage";
import { history } from "../../redux";
import ProductService from "../../services/Product";
import SessionService from "../../services/Session";
import { displayToastMessage } from "../../utils/toasts";
import {
  logIssueNetworkRequestError,
  logUpdatedState,
} from "../../utils/loggers";
import { authRoutePath, sellerDashboardPath } from "../Router";
import { IUser } from "../../interfaces/user";
import { setUser } from "../../redux/actions/user";
import config from "../../config";
import CommonHeader from "../../components/CommonHeader";
import { contactSupportErrorLabel } from "../../utils/errors";
import { Mixpanel } from "../../utils/analytics";

class NewProductListing extends React.Component<any, any> {
  ProductService: ProductService;
  SessionService: SessionService;

  ipfsUpload: any;

  constructor(props: any) {
    super(props);

    let unfinishedListing: any = {};
    if (window && window.localStorage !== undefined) {
      let listing = window.localStorage.getItem("listing");
      if (listing && listing !== "") {
        try {
          const listingJSON = JSON.parse(listing);
          console.log("listingJSON:", listingJSON);

          unfinishedListing.category = listingJSON.category;
          unfinishedListing.title = listingJSON.title;
          unfinishedListing.description = listingJSON.description;
          unfinishedListing.quantity = listingJSON.quantity;
          unfinishedListing.price = listingJSON.price;
          unfinishedListing.paymentType = listingJSON.paymentType;
        } catch (error) {
          console.log("JSON.parse(listing) error:", error);
        }
      }
    }

    this.state = {
      sellerAddress:
        props.user && props.user.walletAddress ? props.user.walletAddress : "",
      category: unfinishedListing.category ? unfinishedListing.category : "All",
      title: unfinishedListing.title ? unfinishedListing.title : "",
      description: unfinishedListing.description
        ? unfinishedListing.description
        : "",
      quantity: unfinishedListing.quantity ? unfinishedListing.quantity : 1,
      price: unfinishedListing.price ? unfinishedListing.price : "",
      paymentType: unfinishedListing.paymentType
        ? unfinishedListing.paymentType
        : "ETH",
      imageLocations: [],
    };

    this.ProductService = new ProductService();
    this.SessionService = new SessionService();
    this.ipfsUpload = upload;

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    Mixpanel.track("NewProductListing", {
      user: this.props.user,
    });

    setTimeout(() => {
      if (this.props.user && this.props.user.walletAddress) {
        this.setState({ sellerAddress: this.props.user.walletAddress });
      }
      if (!this.props.user.chainId) {
        displayToastMessage("error", "Failed to fetch chainId");
      }
    }, 5000);

    try {
      if (!this.props.user.id) {
        const sessionData = await this.SessionService.reviewSession();
        if (sessionData.error) throw new Error(sessionData.error);
        if (sessionData.idVerificationNeeded) history.replace(authRoutePath);
        if (sessionData.success) {
          this.props.dispatchSetUser({
            id: sessionData.userID,
            email: sessionData.email,
            phoneNumber: sessionData.phoneNumber,
          });
        }
      }
    } catch (error: Error | any) {
      logIssueNetworkRequestError(
        "NewProductListing.componentDidMount()",
        error,
      );
      history.replace(authRoutePath);
    }
  }

  handleInputChange(event: Event | any) {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      logUpdatedState(this.state, name);
      if (
        window &&
        window.localStorage &&
        name !== "password" &&
        name !== "sellerAddress" &&
        name !== "imageLocations"
      ) {
        try {
          const listingStr: string | any =
            window.localStorage.getItem("listing");
          const listing = JSON.parse(listingStr);
          window.localStorage.setItem(
            "listing",
            JSON.stringify({
              ...listing,
              [name]: value,
            }),
          );
        } catch (error) {
          console.log("JSON.parse(inputChange) error:", error);
        }
      }
    });
  }

  async handleFileChange(event: Event | any) {
    const files: any = Array.from(event.target.files)
      .filter((file: any) => /\.(jpg|jpeg|png)$/i.test(file.name))
      .slice(0, 4);
    if (files.length > 0) {
      try {
        const client = createThirdwebClient({
          clientId: config.web3.thirdWebAPIKey,
        });

        displayToastMessage("info", "Uploading image(s)...");

        const uris = await this.ipfsUpload({
          client,
          files,
        });

        console.log("uris:", uris);

        displayToastMessage("success", "Uploaded file(s)");

        this.setState(
          { imageLocations: files.length === 1 ? [uris] : uris },
          () => logUpdatedState(this.state, "imageLocations"),
        );
      } catch (error: Error | any) {
        logIssueNetworkRequestError(
          "NewProductListing.handleFileChange() ipfs upload:",
          error,
        );
        displayToastMessage(
          "error",
          `Failed to upload image(s). ${contactSupportErrorLabel}`,
        );
      }
    } else {
      displayToastMessage(
        "error",
        "Please upload valid image files (jpg, jpeg, png).",
      );
    }
  }

  async handleSubmit(event: Event | any) {
    event.preventDefault();

    displayToastMessage("info", "Submitting...");

    let {
      password,
      sellerAddress,
      category,
      title,
      description,
      price,
      quantity,
      paymentType,
      imageLocations,
    } = this.state;

    switch (paymentType) {
      case "ETH":
        paymentType = 0;
        break;
      case "USDC":
        paymentType = 1;
        break;
      case "DAI":
        paymentType = 2;
        break;
      case "GHO":
        paymentType = 3;
        break;
      default:
        paymentType = 0;
        break;
    }

    if (description.length === 0 || description.length > 300) {
      return displayToastMessage("error", "Invalid description");
    }

    const listProductData = {
      chainID: this.props.user.chainId,
      userID: this.props.user.id,
      email: this.props.user.email,
      password,
      sellerAddress,
      category,
      title,
      description,
      quantity,
      price,
      paymentType,
      imageLocations,
    };

    try {
      const resData = await this.ProductService.listProduct(listProductData);
      if (resData.error) throw new Error(resData.error);
      if (resData.idVerificationNeeded) history.replace(authRoutePath);

      displayToastMessage("success", "Success");

      listProductData.password = null;
      delete listProductData.password;

      Mixpanel.track("List New Product - Success", listProductData);

      if (window && window.localStorage !== undefined) {
        window.localStorage.setItem("listing", "");
      }

      history.replace(sellerDashboardPath);
    } catch (error: Error | any) {
      logIssueNetworkRequestError("NewProductListing.handleSubmit()", error);
      displayToastMessage(
        "error",
        "Network error: Please refresh and/or try again",
      );
      Mixpanel.track("List New Product - Failure", { error });
    }
  }

  render() {
    const {
      password,
      sellerAddress,
      category,
      title,
      description,
      quantity,
      price,
      paymentType,
    } = this.state;

    return (
      <div className="min-h-screen bg-gray-100">
        <CommonHeader />
        <div className="mx-auto max-w-4xl px-4 py-8">
          <h2 className="text-2xl font-semibold text-gray-700">
            List a New Product
          </h2>
          <form onSubmit={this.handleSubmit} className="mt-6 space-y-4">
            <div className="flex flex-col space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Seller Address:
              </label>
              <input
                type="text"
                name="sellerAddress"
                value={sellerAddress}
                onChange={this.handleInputChange}
                placeholder="Seller Address"
                className="w-full rounded-lg border-gray-300 p-2 shadow-sm focus:border-[rgb(65,105,225)] focus:ring focus:ring-[rgb(65,105,225)] focus:ring-opacity-50"
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label
                htmlFor="category-select"
                className="text-sm font-medium text-gray-700"
              >
                Category:
              </label>
              <select
                id="category-select"
                name="category"
                value={category}
                onChange={this.handleInputChange}
                className="w-full rounded-lg border border-gray-300 bg-white p-2 shadow-sm focus:border-[rgb(65,105,225)] focus:ring focus:ring-[rgb(65,105,225)] focus:ring-opacity-50"
              >
                {config.products.searchCategories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Title:
              </label>
              <input
                type="text"
                name="title"
                value={title}
                onChange={this.handleInputChange}
                placeholder="Title"
                className="w-full rounded-lg border-gray-300 p-2 shadow-sm focus:border-[rgb(65,105,225)] focus:ring focus:ring-[rgb(65,105,225)] focus:ring-opacity-50"
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Description (300 characters max):
              </label>
              <textarea
                name="description"
                value={description}
                onChange={this.handleInputChange}
                placeholder="For example, if you're listing a SaaS business, provide details including site age, monthly profit, profit margin, page views, profit and revenue multiples, MRR, active subscribers, churn rate, etc"
                className="h-32 w-full rounded-lg border-gray-300 p-2 shadow-sm focus:border-[rgb(65,105,225)] focus:ring focus:ring-[rgb(65,105,225)] focus:ring-opacity-50"
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Upload Images:
              </label>
              <input
                type="file"
                multiple
                onChange={this.handleFileChange}
                className="file:rounded file:border-none file:bg-[rgb(65,105,225)] file:px-4 file:py-2 file:text-white file:transition-colors file:hover:bg-[rgb(65,105,225)]"
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Quantity:
              </label>
              <input
                type="number"
                name="quantity"
                value={quantity}
                onChange={this.handleInputChange}
                placeholder="Quantity"
                className="w-full rounded-lg border-gray-300 p-2 shadow-sm focus:border-[rgb(65,105,225)] focus:ring focus:ring-[rgb(65,105,225)] focus:ring-opacity-50"
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label
                htmlFor="payment-type-select"
                className="text-sm font-medium text-gray-700"
              >
                Payment Type:
              </label>
              <select
                id="payment-type-select"
                name="paymentType"
                value={paymentType}
                onChange={this.handleInputChange}
                className="w-full rounded-lg border border-gray-300 bg-white p-2 shadow-sm focus:border-[rgb(65,105,225)] focus:ring focus:ring-[rgb(65,105,225)] focus:ring-opacity-50"
              >
                <option value="ETH">ETH</option>
                <option value="USDC">USDC</option>
                <option value="DAI">DAI</option>
                <option value="GHO">GHO</option>
              </select>
            </div>
            <div className="flex flex-col space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Price (in {paymentType}):
              </label>
              <input
                type="text"
                name="price"
                value={price}
                onChange={this.handleInputChange}
                placeholder={`Price (in ${paymentType})`}
                className="w-full rounded-lg border-gray-300 p-2 shadow-sm focus:border-[rgb(65,105,225)] focus:ring focus:ring-[rgb(65,105,225)] focus:ring-opacity-50"
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Password:
              </label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={this.handleInputChange}
                placeholder="Enter your password"
                className="w-full rounded-lg border-gray-300 p-2 shadow-sm focus:border-[rgb(65,105,225)] focus:ring focus:ring-[rgb(65,105,225)] focus:ring-opacity-50"
              />
            </div>
            <button
              type="submit"
              className="w-full rounded-lg bg-[rgb(65,105,225)] py-2 text-white hover:bg-[rgb(65,105,225)]"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Object | any) => ({
  router: state.router,
  user: state.user,
});

const mapDispatchToProps = (dispatch: Function | any) => ({
  dispatchSetUser: (user: IUser | any) => dispatch(setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewProductListing);
